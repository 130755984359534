// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

function FetchError({ error, title, className }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const text = title != null ? title : t("compensationFetchError");

  if (error != null) {
    return (
      <div className={`${classes.actionContainer} ${className}`}>
        <Typography
          className={classes.error}
        >{`${text} ${error.status}`}</Typography>
      </div>
    );
  }
  return null;
}

FetchError.propTypes = {
  error: PropTypes.node,
  title: PropTypes.string,
  className: PropTypes.node,
};

FetchError.defaultProps = {
  error: null,
  title: null,
  className: null,
};

export default FetchError;
