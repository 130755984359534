import moment from "moment";
// eslint-disable-next-line import/no-extraneous-dependencies
import jwtDecode from "jwt-decode";
import ensureTrailingSlash from "../url/url";
// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import { decompressData } from "../dataCompression/dataCompression";

export const openLoginPage = location => {
  // redirect to login page
  const appId = process.env.REACT_APP_ORIGO_APP_ID;
  const authUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
  const authApi = process.env.REACT_APP_ORIGO_AUTH_API;
  const path = `${authUrl}${authApi}?app_id=${appId}`;
  location.replace(path);
};

export const openPortal = location => {
  location.replace(process.env.REACT_APP_ORIGO_URL);
};

export const isTokenExpired = tokenData => {
  if (tokenData === null) {
    return true;
  }

  const { expiryTime } = tokenData;
  return moment().isAfter(expiryTime);
};

const createTokenData = data => {
  const expiresInSeconds = data.expiresIn;
  return {
    ...data,
    expiryTime: moment().add(expiresInSeconds, "second"),
  };
};

export const fetchTokenData = sessionId =>
  RestService.getToken(sessionId)
    .then(token => Promise.resolve(createTokenData(token)))
    .catch(err => Promise.reject(err));

export const parseUserDataFromToken = userDataJson => {
  try {
    const organizations = JSON.parse(userDataJson);

    // gather
    const parsed = organizations.reduce(
      (acc, org) => {
        const result = acc;
        result.organizationIds.push(org.orgId);
        result.profitCenters.push(...org.profitCenters);
        const roles = org.apps.reduce(
          (curr, app) => curr.concat(app.roles),
          []
        );
        result.roles.push(...roles);
        return result;
      },
      {
        roles: [],
        organizationIds: [],
        profitCenters: [],
      }
    );

    // return without duplicates
    return {
      roles: Array.from(new Set(parsed.roles)),
      organizationIds: parsed.organizationIds,
      profitCenters: Array.from(new Set(parsed.profitCenters)),
    };
  } catch (error) {
    return {
      roles: [],
      organizations: [],
      profitCenters: [],
    };
  }
};

export const parseUserData = idToken => {
  const payload = jwtDecode(idToken);
  const name = `${payload.given_name} ${payload.family_name}`;
  const { email } = payload;
  const userId = payload["cognito:username"];
  const userDataDecompressed = decompressData(payload.user_data);
  const tokenData = parseUserDataFromToken(userDataDecompressed);

  return {
    userId,
    name,
    email,
    roles: tokenData.roles,
    organizationIds: tokenData.organizationIds,
    profitCenters: tokenData.profitCenters,
  };
};
