// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { useDispatch, useSelector } from "react-redux";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import _ from "lodash";
import ExceptionsEdit, { createEmptyException } from "./exceptionsEdit";
import ExceptionsView from "./exceptionsView";
import {
  EditButton,
  UpdateButton,
  CancelButton,
  AddExceptionButton,
} from "../buttons";
import {
  updateRules,
  clearRuleErrors,
  modifyRuleLocally,
  updateStoredOriginalRule,
  removeStoredOriginalRule,
} from "../../../../redux";
import { Progress } from "../../../common";
import { RuleStatus } from "../../../common/constants/constants";
// eslint-disable-next-line import/no-cycle
import { RuleListMode } from "../compensationRuleList";

const EMPTY_INDEX = -1;

function Exceptions({
  classes,
  rule,
  index,
  updateModifiedIndex,
  updateScrollIndex,
  isRowEdited,
  ruleListMode,
  onRequestRender,
}) {
  const dispatch = useDispatch();
  const { sendingRuleIds, ruleSendingError, storedOriginalRules } = useSelector(
    state => state.rules
  );
  const { exceptions } = rule;
  const exceptionCount = exceptions != null ? exceptions.length : 0;
  const { t } = useTranslation();

  const isExceptionEmpty = exception =>
    (exception.startDate == null && exception.endDate == null) ||
    exception.description == null;

  const findStoredOriginalRule = modifyRule => {
    const result = storedOriginalRules.find(
      storedOriginalRule =>
        storedOriginalRule.productVNR === modifyRule.productVNR
    );
    return result;
  };

  const isModified = () => {
    // find original rule
    const origRule = findStoredOriginalRule(rule);
    const compRule = { ...rule, _highlightResult: null };
    const compOriginalRule = { ...origRule, _highlightResult: null };
    const equals = _.isEqual(compRule, compOriginalRule);

    return equals === false;
  };

  const isUpdateDisabled = () => {
    if (rule.exceptions != null) {
      // any empty
      for (let i = 0; i < rule.exceptions.length; i += 1) {
        const exception = rule.exceptions[i];
        // missing both dates
        if (isExceptionEmpty(exception)) {
          return true;
        }
      }
    }

    // compensation status needs to be set
    if (
      ruleListMode !== RuleListMode.DEFAULT &&
      rule.status === RuleStatus.UNDEFINED
    ) {
      return true;
    }

    return isModified() === false;
  };

  const updateModify = modifyRule => {
    dispatch(updateStoredOriginalRule(modifyRule));
  };

  const stopModify = modifyRule => {
    dispatch(removeStoredOriginalRule(modifyRule));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ProgressIndicator() {
    if (sendingRuleIds.includes(rule.productVNR) === false) {
      return null;
    }

    return (
      <div className={classes.updateProgress}>
        <Progress size={25} show />
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SendError() {
    // check if the error concerns this
    let showError = false;
    if (ruleSendingError != null) {
      showError = ruleSendingError.ids.includes(rule.productVNR);
    }

    if (showError === true) {
      return (
        <Typography className={classes.sendingErrorText}>
          {t("ruleUpdateFailedError")}
        </Typography>
      );
    }
    return null;
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function Buttons({ hideCancel }) {
    return (
      <div>
        <div className={classes.cancelAndUpdateButtons}>
          {hideCancel === false && (
            <div className={classes.cancelButton}>
              <CancelButton
                disabled={sendingRuleIds.length > 0}
                onClick={() => {
                  // clear errors
                  dispatch(clearRuleErrors());

                  // update index
                  updateModifiedIndex(EMPTY_INDEX);

                  // reset to original
                  const currentOriginal = findStoredOriginalRule(rule);
                  dispatch(modifyRuleLocally(currentOriginal));

                  // stop modify
                  stopModify(rule);

                  // inform
                  onRequestRender(index);
                }}
              />
            </div>
          )}
          {sendingRuleIds.length === 0 && (
            <UpdateButton
              disabled={isUpdateDisabled()}
              onClick={() => {
                // if undefined rule is set to not undefined
                const currentOriginal = findStoredOriginalRule(rule);

                const isMovedToActive =
                  currentOriginal.status === RuleStatus.UNDEFINED &&
                  rule.status !== RuleStatus.UNDEFINED;

                // send update
                dispatch(updateRules([rule])).then(response => {
                  if (response != null) {
                    // update index
                    updateModifiedIndex(EMPTY_INDEX);

                    // stop modify
                    stopModify(rule);

                    // re-render?
                    if (isMovedToActive === false) {
                      // inform
                      onRequestRender(index);
                    }
                  }
                });
              }}
            />
          )}
          <ProgressIndicator />
        </div>
        <SendError />
      </div>
    );
  }

  Buttons.propTypes = {
    hideCancel: PropTypes.bool,
  };

  Buttons.defaultProps = {
    hideCancel: false,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function EditBlock() {
    const shouldHideCancelButton = () => {
      // all rows are in edit mode by default
      if (ruleListMode === RuleListMode.INACTIVE) {
        // TODO: fix this so that every row is edited by default
        return false;
      }

      return false;
    };

    return (
      <div>
        <div className={classes.exceptionsRow}>
          <div className={classes.grow}>
            {exceptionCount > 0 && (
              <ExceptionsEdit
                rule={rule}
                onExceptionChanged={ex => {
                  // update edited row
                  updateModifiedIndex(index);

                  // update exceptions
                  dispatch(modifyRuleLocally({ ...rule, ex }));

                  // changed
                  onRequestRender(index);
                }}
              />
            )}
            {exceptionCount === 0 && (
              <AddExceptionButton
                onClick={() => {
                  // update edited row
                  updateModifiedIndex(index);

                  // add new empty exception if there is none
                  const { exceptions: exceptionsFromRule } = rule;
                  if (
                    exceptionsFromRule == null ||
                    exceptionsFromRule.length === 0
                  ) {
                    dispatch(
                      modifyRuleLocally({
                        ...rule,
                        exceptions: [createEmptyException()],
                      })
                    );
                  }

                  // changed
                  onRequestRender(index);
                }}
              />
            )}
          </div>
          {exceptionCount === 0 && (
            <Buttons hideCancel={shouldHideCancelButton()} />
          )}
        </div>
        {exceptionCount > 0 && (
          <div className={classes.buttonsTopMargin}>
            <Buttons hideCancel={false} />
          </div>
        )}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function ViewBlock() {
    return (
      <div className={classes.exceptionsRow}>
        <div className={classes.grow}>
          <ExceptionsView rule={rule} />
        </div>
        <EditButton
          onClick={() => {
            // start modify
            updateModify(rule);

            // update edited index
            updateModifiedIndex(index);

            // stick scroll
            updateScrollIndex(index);

            // if there's exceptions it will need re-calculating of the row
            if (rule.exceptions.length > 0) {
              onRequestRender(index);
            }
          }}
        />
      </div>
    );
  }

  // edit mode
  if (isRowEdited(index)) {
    return <EditBlock />;
  }
  return <ViewBlock />;
}

Exceptions.propTypes = {
  rule: PropTypes.shape({
    exceptions: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        description: PropTypes.string,
      })
    ),
    status: PropTypes.string,
    productVNR: PropTypes.string,
  }),
  classes: PropTypes.shape({
    grow: PropTypes.string,
    exceptionsRow: PropTypes.string,
    buttonsTopMargin: PropTypes.string,
    cancelButton: PropTypes.string,
    cancelAndUpdateButtons: PropTypes.string,
    updateProgress: PropTypes.string,
    sendingErrorText: PropTypes.string,
  }),
  index: PropTypes.number,
  updateModifiedIndex: PropTypes.func,
  updateScrollIndex: PropTypes.func,
  isRowEdited: PropTypes.func,
  ruleListMode: PropTypes.string,
  onRequestRender: PropTypes.func,
};

Exceptions.defaultProps = {
  onRequestRender: () => {},
  rule: {
    exceptions: [],
  },
  classes: {},
  index: 0,
  updateModifiedIndex: () => {},
  updateScrollIndex: () => {},
  isRowEdited: () => {},
  ruleListMode: "",
};

export default Exceptions;
