/* eslint-disable */
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import moment from "moment";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, ButtonGroup, Button } from "@material-ui/core";
import { useDispatch } from "react-redux";
import { TabWidths } from "./headerRow";
import {
  InfinityList,
  DateRangePicker,
  SwitchText,
  TooltipTypography,
} from "../../common";
import { Cell } from "../../common/tables/common";
import {
  getLocalizedRuleProductName,
  isRuleCompensated,
  getTimeLimitStr,
} from "../../../utils/rule/rule";
import { RuleStatus } from "../../common/constants/constants";
// eslint-disable-next-line import/no-cycle
import Exceptions from "./exceptions/exceptions";
import { modifyRuleLocally } from "../../../redux";
import { RuleListMode } from "./compensationRuleList";

const useStyles = makeStyles(theme => ({
  rowContainer: {},
  productRow: {
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "1px solid rgba(0, 0, 0, 0.12)",
    // to compensate extension
    borderLeft: "5px solid transparent",
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
  },
  productRowAlignTop: {
    alignItems: "flex-start",
  },
  productRowExpanded: {
    backgroundColor: "#f5f5f5",
  },
  borderLeftHighlight: {
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  productName: {
    ...theme.typography.body1,
    color: theme.palette.text.primary.main,
  },
  vnrText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
    marginTop: theme.spacing(1),
  },
  rowHeader: {
    marginTop: theme.spacing(2),
  },
  exceptionsRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  grow: {
    flexGrow: 1,
  },
  buttonsTopMargin: {
    marginTop: theme.spacing(4),
  },
  cancelAndUpdateButtons: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  cancelButton: {
    marginRight: "1rem",
  },
  buttonGroupButton: {
    color: theme.palette.secondaryMain,
  },
  updateProgress: {
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  sendingErrorText: {
    marginTop: theme.spacing(0.5),
    ...theme.typography.button,
    textTransform: "none",
    color: "red",
  },
  timeLimitText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

const INACTIVE_ROW_HEIGHT = 76;
const DEFAULT_ROW_HEIGHT = 73;
const EXCEPTIONS_HEIGHT_VIEW = 40;
const EXCEPTIONS_HEIGHT_EDIT = 129;
const UPDATE_BUTTON_HEIGHT = 49;
const EXCEPTIONS_HEIGHT_VIEW_MARGIN = 8;
const ROW_SPACING = 0;
export const DEFAULT_VISIBLE_ITEMS = 20;
const SINGLE_VISIBLE_ITEM = 1;
const EMPTY_INDEX = -1;

function ProductName({ rule, ruleListMode }) {
  const classes = useStyles();
  const { t, i18n } = useTranslation();

  if (ruleListMode === RuleListMode.DEFAULT) {
    return (
      <Typography className={classes.productName}>
        {t("setToAllNewProducts")}
      </Typography>
    );
  }

  const vnrText = `${t("vnr").toUpperCase()}: ${rule.productVNR} | ${t(
    "oriolaNo"
  )}: ${rule.materialId}`;
  const name = getLocalizedRuleProductName(rule, i18n.language);

  return (
    <div>
      <TooltipTypography className={classes.productName} tooltip={name}>
        {name}
      </TooltipTypography>
      <Typography className={classes.vnrText}>{vnrText}</Typography>
    </div>
  );
}

ProductName.propTypes = {
  rule: PropTypes.shape({
    productVNR: PropTypes.string,
    materialId: PropTypes.string,
  }),
  ruleListMode: PropTypes.string,
};

ProductName.defaultProps = {
  rule: {},
  ruleListMode: "",
};

function RulesTable({ ruleListMode, rules, ruleCount, isLoading, onLoadMore }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const [modifiedRowIndex, setModifiedRowIndex] = useState(EMPTY_INDEX);
  const [listHeight, setListHeight] = useState(0);
  const [scrollToIndex, setScrollToIndex] = useState(EMPTY_INDEX);
  const [forceRender, setForceRender] = useState(0);

  // This is a hack to force re-calculating row height. There seems to be
  // a bug in the virtualized table that does not make this.
  const onRequestRender = () => {
    setForceRender((forceRender + 1) % 2);
  };

  const updateModifiedIndex = index => {
    setModifiedRowIndex(index);
  };

  const isRowEdited = index =>
    // INACTIVE mode enables edit for all rows by default
    // ruleListMode === RuleListMode.INACTIVE ||
    modifiedRowIndex === index;
  const getRowHeightByMode = () => {
    if (ruleListMode === RuleListMode.DEFAULT) {
      return DEFAULT_ROW_HEIGHT;
    }
    // same for inactive/active
    return INACTIVE_ROW_HEIGHT;
  };

  const getVisibleItemsByMode = () => {
    if (ruleListMode === RuleListMode.DEFAULT) {
      return SINGLE_VISIBLE_ITEM;
    }
    return DEFAULT_VISIBLE_ITEMS;
  };

  const calculateExceptionHeight = (index, rule) => {
    const { exceptions } = rule;
    if (exceptions != null) {
      const exceptionCount = rule.exceptions.length;
      if (exceptionCount > 0) {
        if (modifiedRowIndex === index) {
          const multiplier = exceptionCount;
          return EXCEPTIONS_HEIGHT_EDIT * multiplier + UPDATE_BUTTON_HEIGHT;
        }
        // when on non-edit mode one exception can be in the row
        const multiplier = exceptionCount - 1;
        return (
          multiplier * EXCEPTIONS_HEIGHT_VIEW +
          multiplier * EXCEPTIONS_HEIGHT_VIEW_MARGIN
        );
      }
    }
    return 0;
  };

  const calculateListHeight = () => {
    // single default row needs to be expanded if exceptions are added
    let additionalHeight = 0;
    if (ruleListMode === RuleListMode.DEFAULT && rules.length > 0) {
      const rule = rules[0];
      additionalHeight = calculateExceptionHeight(0, rule);
    }

    return rules.length < DEFAULT_VISIBLE_ITEMS
      ? getRowHeightByMode() * rules.length + additionalHeight
      : getRowHeightByMode() * getVisibleItemsByMode() + additionalHeight;
  };

  useEffect(() => {
    // update height
    setListHeight(calculateListHeight());
  }, [rules, setListHeight]);
  function StatusButtonGroup({ rule, index }) {
    let emptyColor = null;
    let yesColor = null;
    let noColor = null;

    if (rule.status === RuleStatus.UNDEFINED) {
      emptyColor = "#eaeef5";
    } else if (rule.status === RuleStatus.ON) {
      yesColor = "#38864680";
    } else {
      noColor = "#ec130e80";
    }

    const setStatus = status => {
      dispatch(modifyRuleLocally({ ...rule, status }));
    };

    return (
      <ButtonGroup variant="text" disabled={isRowEdited(index) === false}>
        <Button
          className={classes.buttonGroupButton}
          style={{ backgroundColor: emptyColor }}
          onClick={() => setStatus(RuleStatus.UNDEFINED)}
        >
          {t("empty")}
        </Button>
        <Button
          className={classes.buttonGroupButton}
          style={{ backgroundColor: yesColor }}
          onClick={() => setStatus(RuleStatus.ON)}
        >
          {t("yes")}
        </Button>
        <Button
          className={classes.buttonGroupButton}
          style={{ backgroundColor: noColor }}
          onClick={() => setStatus(RuleStatus.OFF)}
        >
          {t("no")}
        </Button>
      </ButtonGroup>
    );
  }

  StatusButtonGroup.propTypes = {
    rule: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
  };

  function StatusSwitch({ rule, index }) {
    const checked =
      rule.status === RuleStatus.UNDEFINED ? null : isRuleCompensated(rule);
    return (
      <SwitchText
        checked={checked}
        checkedText={t("toBeCompensated")}
        notCheckedText={t("noCompensation")}
        disabled={isRowEdited(index) === false}
        onChecked={value => {
          // update edited
          updateModifiedIndex(index);

          const status = value === true ? RuleStatus.ON : RuleStatus.OFF;
          dispatch(modifyRuleLocally({ ...rule, status }));
        }}
      />
    );
  }

  StatusSwitch.propTypes = {
    rule: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
  };

  function DateRange({ rule, index }) {
    return (
      <DateRangePicker
        startDate={rule.startDate}
        endDate={rule.endDate}
        onStartDateChanged={date => {
          // update edited
          updateModifiedIndex(index);

          dispatch(
            modifyRuleLocally({
              ...rule,
              startDate:
                date != null ? moment(date).format("DD-MM-YYYY") : null,
            })
          );
        }}
        onEndDateChanged={date => {
          // update edited
          updateModifiedIndex(index);

          dispatch(
            modifyRuleLocally({
              ...rule,
              endDate: date != null ? moment(date).format("DD-MM-YYYY") : null,
            })
          );
        }}
      />
    );
  }

  DateRange.propTypes = {
    rule: PropTypes.shape({
      startDate: PropTypes.string,
      endDate: PropTypes.string,
    }).isRequired,
    index: PropTypes.number.isRequired,
  };

  const rowRenderer = ({ key, index, style }) => {
    const rule = rules[index];
    const rowEdited = isRowEdited(index);
    const leftColor =
      modifiedRowIndex === index ? classes.borderLeftHighlight : null;
    const backgroundColor =
      rowEdited === true ? classes.productRowExpanded : null;
    const exceptionCount = rule.exceptions.length;
    const alignRowTop =
      ruleListMode === RuleListMode.DEFAULT
        ? exceptionCount > 1
        : exceptionCount > 0;
    const alignRowItemsTopClass =
      alignRowTop || (rowEdited && exceptionCount > 0)
        ? classes.productRowAlignTop
        : null;

    return (
      <div key={key} style={style} className={classes.rowContainer}>
        <div
          className={`${classes.productRow} ${leftColor} ${backgroundColor} ${alignRowItemsTopClass}`}
        >
          <Cell width={TabWidths.Product}>
            <ProductName rule={rule} ruleListMode={ruleListMode} />
          </Cell>
          <Cell width={TabWidths.Compensation}>
            {ruleListMode !== RuleListMode.DEFAULT && (
              <StatusSwitch rule={rule} index={index} />
            )}
            {ruleListMode === RuleListMode.DEFAULT && (
              <StatusButtonGroup rule={rule} index={index} />
            )}
          </Cell>
          <Cell width={TabWidths.TimeLimit}>
            {rowEdited === false && (
              <Typography className={classes.timeLimitText}>
                {getTimeLimitStr(rule, t)}
              </Typography>
            )}
            {rowEdited === true && <DateRange rule={rule} index={index} />}
          </Cell>
          <Cell width={TabWidths.Exceptions}>
            <Exceptions
              classes={classes}
              rule={rule}
              index={index}
              updateModifiedIndex={idx => updateModifiedIndex(idx)}
              updateScrollIndex={idx => setScrollToIndex(idx)}
              isRowEdited={idx => isRowEdited(idx)}
              ruleListMode={ruleListMode}
              onRequestRender={() => onRequestRender()}
            />
          </Cell>
        </div>
      </div>
    );
  };

  const getRowHeight = ({ index }) => {
    const rule = rules[index];
    return getRowHeightByMode() + calculateExceptionHeight(index, rule);
  };

  const visibleItems = getVisibleItemsByMode();
  return (
    <div key={forceRender}>
      <InfinityList
        items={rules}
        visibleItemCount={visibleItems}
        itemOverallCount={ruleCount}
        getRowHeight={getRowHeight}
        rowSpacing={ROW_SPACING}
        isLoading={isLoading}
        onRenderRow={rowRenderer}
        onLoad={onLoadMore}
        listHeight={listHeight}
        scrollToIndex={scrollToIndex}
      />
    </div>
  );
}

RulesTable.propTypes = {
  ruleListMode: PropTypes.string.isRequired,
  rules: PropTypes.arrayOf(
    PropTypes.shape({
      status: PropTypes.string,
      materialId: PropTypes.string,
      productVNR: PropTypes.string,
    })
  ).isRequired,
  ruleCount: PropTypes.number.isRequired,
  onLoadMore: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
};

RulesTable.defaultProps = {
  isLoading: false,
};

export default RulesTable;
