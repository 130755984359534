// eslint-disable-next-line no-use-before-define
import React, { forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { AutoSizer, InfiniteLoader, List } from "react-virtualized";
// eslint-disable-next-line import/no-cycle
import { Progress } from "..";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  list: {
    outline: "none",
  },
  progress: {
    marginTop: theme.spacing(1),
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

const InfinityList = forwardRef(
  (
    {
      items,
      visibleItemCount,
      itemOverallCount,
      rowHeight,
      getRowHeight,
      rowSpacing,
      isLoading,
      onRenderRow,
      onLoad,
      listHeight,
      scrollToIndex,
    },
    listRef
  ) => {
    const classes = useStyles();

    const overallRowHeight = rowHeight + rowSpacing;
    const wantedListHeight =
      listHeight != null ? listHeight : overallRowHeight * visibleItemCount;
    const onLoadProducts = isLoading ? () => {} : onLoad;

    return (
      <div className={classes.root}>
        <div style={{ height: wantedListHeight, width: "100%" }}>
          <InfiniteLoader
            isRowLoaded={({ index }) => !!items[index]}
            loadMoreRows={({ startIndex, stopIndex }) =>
              onLoadProducts(startIndex, stopIndex)
            }
            rowCount={itemOverallCount || 1000}
            minimumBatchSize={visibleItemCount}
          >
            {({ onRowsRendered, registerChild }) => (
              <AutoSizer disableHeight={!!listHeight}>
                {({ width, height }) => (
                  <List
                    className={classes.list}
                    height={listHeight || height}
                    width={width}
                    onRowsRendered={onRowsRendered}
                    ref={node => {
                      // Allow also forwarded ref here
                      if (listRef) {
                        listRef.current = node;
                      }
                      registerChild(node);
                    }}
                    rowCount={items.length}
                    rowHeight={getRowHeight || overallRowHeight}
                    rowRenderer={onRenderRow}
                    scrollToIndex={scrollToIndex}
                  />
                )}
              </AutoSizer>
            )}
          </InfiniteLoader>
        </div>
        {isLoading && (
          <div className={classes.progress}>
            <Progress size={25} show={isLoading} />
          </div>
        )}
      </div>
    );
  }
);

InfinityList.propTypes = {
  items: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  rowHeight: PropTypes.number,
  getRowHeight: PropTypes.func,
  onRenderRow: PropTypes.func.isRequired,
  rowSpacing: PropTypes.number,
  onLoad: PropTypes.func,
  visibleItemCount: PropTypes.number,
  itemOverallCount: PropTypes.number,
  isLoading: PropTypes.bool,
  listHeight: PropTypes.number,
  scrollToIndex: PropTypes.number,
};

InfinityList.defaultProps = {
  onLoad: () => {},
  rowHeight: 60,
  rowSpacing: 0,
  visibleItemCount: 10,
  itemOverallCount: 10,
  isLoading: false,
  getRowHeight: null,
  scrollToIndex: -1,
  listHeight: null,
};

export default InfinityList;
