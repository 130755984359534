/* eslint-disable */
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { sum, uniq, xorBy, intersectionBy } from "lodash";
// eslint-disable-next-line import/no-cycle
import { InfinityList, TooltipTypography, OverlineValue } from "../..";
import {
  getCompensationsProducts,
  getCompensatedProducts,
  getTotalCompensationsRequested,
  getTotalCompensationsGiven,
  createUpdatedProductsProperties,
  areAllApprovedByPharmacies,
} from "../../../../utils/compensation/compensation";
import {
  Cell,
  RejectButton,
  RollbackRejectButton,
  NetworkLoader,
} from "../common";
import {
  CompensationStatuses,
  PharmacyStatuses,
  EURO_CURRENCY,
  RejectionReason,
} from "../../constants/constants";
import ProductTable from "../productTable/productTable";
import RejectPopup, { RejectPopupType } from "../../rejectPopup/rejectPopup";
import { updateCompensationsProperties } from "../../../../redux";
import { TableDetailsContext } from "../tableDetailsContext";
import {
  getChildTableHeight,
  calculateNeededHeightForChildTable,
  EXTENSION_CONTAINER_BOTTOM_PADDING,
  EXTENSION_CONTAINER_TOP_PADDING,
  HEADER_TOP_PADDING,
  HEADER_BOTTOM_PADDING,
  HEADER_BORDER_WIDTH,
  HEADER_HEIGHT,
  HEADER_FULL_HEIGHT,
} from "../../../../utils/tableExtensionView";

const FIXED_ROW_HEIGHT = 76;
const ROW_SPACING = 0;
const EXTENDED_ROW_EMPTY = -1;
const MAX_VISIBLE_ITEMS = 10;
const MIN_VISIBLE_ITEMS = 5;
const POPUP_MARGIN = 8;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headerRow: {
    height: HEADER_HEIGHT,
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: HEADER_TOP_PADDING,
    paddingBottom: HEADER_BOTTOM_PADDING,
    borderBottom: `solid ${HEADER_BORDER_WIDTH}px #e7e7e7`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // to compensate extension
    borderLeft: "5px solid transparent",
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
  },
  productRow: {
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
    // to compensate extension
    borderLeft: "5px solid transparent",
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
  },
  productRowHover: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
  },
  borderLeftHighlight: {
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  rowDisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  columnHeaderText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  columnValueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  columnValueTextDisabled: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  columnValueTextBold: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
    fontWeight: "bold",
  },
  columnValueTextRejected: {
    color: "#ec130e",
    ...theme.typography.button,
    textTransform: "none",
    marginTop: theme.spacing(1),
  },
  columnValueMargin: {
    marginLeft: theme.spacing(1),
  },
  rowDirectionCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nameContainer: {
    paddingRight: "0.5rem",
    // TODO: set fixed height since the pharma co address was removed and want to center the text
    // without affecting infinity list fixed row height
    height: "43px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
  pharmaCompanyName: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    textTransform: "none",
  },
  pharmaCompanyAddress: {
    marginTop: theme.spacing(1),
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  noCompensations: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  extensionContainer: {
    borderLeftWidth: "5px",
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    borderBottomStyle: "solid",
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: EXTENSION_CONTAINER_TOP_PADDING,
    paddingBottom: EXTENSION_CONTAINER_BOTTOM_PADDING,
    paddingLeft: "3rem",
  },
}));

export const PharmaTableMode = Object.freeze({
  ShowPharmacy: "pharmacy",
  ShowPharmaCompany: "pharmaCompany",
});

export const ExtensionMode = Object.freeze({
  None: "None",
  PharmaCompanies: "PharmaCompanies",
  Products: "Products",
  Pharmacies: "Pharmacies",
});

const columnWidthsPharmaCompany = {
  name: "30%",
  products: "15%",
  compensation: "20%",
  status: "20%",
  actions: "15%",
};

const columnWidthsPharmacy = {
  name: "30%",
  customerNo: "10%",
  products: "10%",
  compensation: "20%",
  status: "20%",
  actions: "10%",
};

const footerWidthsFive = {
  title: "30%",
  products: "15%",
  compensation: "20%",
};

const footerWidthsSix = {
  title: "40%",
  products: "10%",
  compensation: "20%",
};

const getId = (compensation, mode) =>
  mode === PharmaTableMode.ShowPharmaCompany
    ? compensation.pharmaCompanyId
    : compensation.pharmacyId;

const getName = (compensation, mode, emptyCompensationPharmaCompanyName) =>
  mode === PharmaTableMode.ShowPharmaCompany
    ? compensation.pharmaCompanyName ?? emptyCompensationPharmaCompanyName
    : compensation.pharmacyName;

const getAddress = (compensation, mode) =>
  mode === PharmaTableMode.ShowPharmaCompany
    ? compensation.pharmaCompanyAddress
    : compensation.pharmacyAddress;

const createCompensationGroups = (
  mode,
  showInServiceProfitCenters,
  inServiceProfitCenters,
  compensations = []
) => {
  const groups = Object.values(
    compensations.reduce((groupMap, compensation) => {
      const id = getId(compensation, mode);
      if (!groupMap[id]) {
        groupMap[id] = {
          id,
          name: getName(compensation, mode),
          compensations: [],
        };

        // Add pharmacyAccepted info if mode is pharmacy
        if (mode === PharmaTableMode.ShowPharmacy) {
          groupMap[id].pharmacyAccepted =
            compensation.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;
        }
      }
      groupMap[id].compensations.push(compensation);
      return groupMap;
    }, {})
  );

  groups.sort((i1, i2) => (i1.name || "").localeCompare(i2.name || "", "fi"));

  // Add companies that has no compensations as groups
  if (
    mode === PharmaTableMode.ShowPharmaCompany &&
    showInServiceProfitCenters
  ) {
    // Get in-service companies having no compensations & sorted A-Z
    let noCompensationGroups = xorBy(groups, inServiceProfitCenters, "id").sort(
      (i1, i2) => (i1.name || "").localeCompare(i2.name || "", "fi")
    );
    // Remove duplicates and transform zero compensation groups
    noCompensationGroups = intersectionBy(
      noCompensationGroups,
      inServiceProfitCenters,
      "id"
    ).map(group => ({
      ...group,
      compensations: [],
    }));

    groups.push(...noCompensationGroups);
  }
  return groups;
};

const getColumnWidths = mode =>
  mode === PharmaTableMode.ShowPharmacy
    ? columnWidthsPharmacy
    : columnWidthsPharmaCompany;

function Name({ compensation, emptyCompensationPharmaCompanyName, mode }) {
  const classes = useStyles();

  const name = getName(compensation, mode, emptyCompensationPharmaCompanyName);
  const address = getAddress(compensation, mode);

  return (
    <div className={classes.nameContainer}>
      <TooltipTypography
        className={classes.pharmaCompanyName}
        color="textPrimary"
        noWrap
        tooltip={name}
      >
        {name}
      </TooltipTypography>
      {address != null && (
        <Typography
          className={classes.pharmaCompanyAddress}
          color="textPrimary"
          noWrap
        >
          {address}
        </Typography>
      )}
    </div>
  );
}

Name.propTypes = {
  compensation: PropTypes.shape({}),
  emptyCompensationPharmaCompanyName: PropTypes.string,
  mode: PropTypes.string,
};

Name.defaultProps = {
  compensation: null,
  emptyCompensationPharmaCompanyName: "",
  mode: "",
};

function CustomerId({ compensation }) {
  const classes = useStyles();

  const name = compensation.pharmacyId;
  return (
    <div>
      <Typography className={classes.pharmaCompanyName} color="textPrimary">
        {name}
      </Typography>
    </div>
  );
}

CustomerId.propTypes = {
  compensation: PropTypes.shape({
    pharmacyId: PropTypes.string,
  }),
};

CustomerId.defaultProps = {
  compensation: null,
};

function ProductCount({ compensations }) {
  const compensatedCount = sum(
    compensations.map(compensation => compensation.compensatedProducts.length)
  );
  const rejected = sum(
    compensations.map(compensation => compensation.rejectedProducts.length)
  );
  const overallCount = compensatedCount + rejected;
  const showDouble = rejected > 0;

  return (
    <OverlineValue
      overallValue={overallCount}
      actualValue={compensatedCount}
      overline={showDouble}
    />
  );
}

ProductCount.propTypes = {
  compensations: PropTypes.arrayOf(
    PropTypes.shape({
      compensatedProducts: PropTypes.arrayOf(PropTypes.shape({})),
      rejectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
};

ProductCount.defaultProps = {
  compensations: [],
};

function CompensationValue({ compensations }) {
  const rejected = sum(
    compensations.map(compensation => compensation.rejectedProducts.length)
  );
  const allPharmaciesApproved = areAllApprovedByPharmacies(compensations);
  const showDouble = rejected > 0 || !allPharmaciesApproved;
  const totalRequested = getTotalCompensationsRequested(compensations);
  const totalGiven = getTotalCompensationsGiven(compensations);

  return (
    <OverlineValue
      overallValue={`${totalRequested}${EURO_CURRENCY}`}
      actualValue={`${totalGiven}${EURO_CURRENCY}`}
      overline={showDouble}
    />
  );
}

CompensationValue.propTypes = {
  compensations: PropTypes.arrayOf(
    PropTypes.shape({
      rejectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ),
};

CompensationValue.defaultProps = {
  compensations: [],
};

function PharmaTable({
  compensations,
  mode,
  showActionControls,
  extensionMode,
  fixedHeight,
  hideFooter,
  inServiceProfitCenters,
  showInServiceProfitCenters,
  tableLevel = 0,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [extendedRowIndex, setExtendedRowIndex] = useState(EXTENDED_ROW_EMPTY);
  const [rejectionRowIndex, setRejectionRowIndex] =
    useState(EXTENDED_ROW_EMPTY);
  const [rejectionRowSubIndex, setRejectionRowSubIndex] =
    useState(EXTENDED_ROW_EMPTY);
  const virtualizedRef = useRef();

  const {
    getTableDetailsOfChildTables,
    setTableDetailsForLevel,
    resetTableDetailsForLevel,
  } = useContext(TableDetailsContext);

  // Recompute infinity list row heights when some child table had some changes
  // on their details
  // (like more rows etc - as amount of those rows has an effect on the reserved height on this tableLevel)
  useEffect(() => {
    if (virtualizedRef.current) {
      virtualizedRef.current.recomputeRowHeights(0);
    }
  }, [getTableDetailsOfChildTables]);

  const showActionsColumn = showActionControls;
  const columnWidths = getColumnWidths(mode);

  const compensationGroups = useMemo(
    () =>
      createCompensationGroups(
        mode,
        showInServiceProfitCenters,
        inServiceProfitCenters,
        compensations
      ),
    [compensations, mode, showInServiceProfitCenters, inServiceProfitCenters]
  );
  const compensationGroupsCount = compensationGroups.length;

  // TODO: handle send error
  const { sending } = useSelector(state => state.compensation);
  const dispatch = useDispatch();

  useEffect(() => {
    setTableDetailsForLevel(tableLevel, {
      rowCount: compensationGroupsCount,
      defaultRowHeight: FIXED_ROW_HEIGHT,
      utilitiesHeight: hideFooter ? HEADER_FULL_HEIGHT : HEADER_FULL_HEIGHT * 2,
    });
    return () => {
      resetTableDetailsForLevel(tableLevel);
    };
  }, [
    compensationGroupsCount,
    mode,
    tableLevel,
    hideFooter,
    setTableDetailsForLevel,
    resetTableDetailsForLevel,
  ]);

  const [popup, setPopup] = useState({
    type: RejectPopupType.Pharmacy,
    show: false,
    top: 0,
    left: 0,
    compensation: null,
    product: null,
    pharmacyCount: null,
  });
  const [popupSize, setPopupSize] = useState({ width: 0, height: 0 });

  const handlePharmacyRejectClick = (e, compensation, index) => {
    e.stopPropagation();
    setRejectionRowIndex(index);

    // show pharmacy rejection popup
    const rect = e.target.getBoundingClientRect();
    setPopup({
      type: RejectPopupType.Pharmacy,
      show: true,
      top: rect.y,
      left: rect.x,
      compensation,
      pharmacyCount: 1,
    });
  };

  const handlePharmacyProductRejectClick = (
    e,
    compensation,
    product,
    index
  ) => {
    e.stopPropagation();
    setRejectionRowIndex(index);

    // show pharmacy rejection popup
    const rect = e.target.getBoundingClientRect();
    setPopup({
      type: RejectPopupType.Product,
      show: true,
      top: rect.y,
      left: rect.x,
      compensation,
      product,
      pharmacyCount: 1,
    });
  };

  const handlePharmacyRollbackRejection = async (e, compensation, index) => {
    e.stopPropagation();
    setRejectionRowIndex(index);

    // enabled pharma
    const pharmaProperties = {
      pharmaCompanyStatus: CompensationStatuses.Accepted,
      pharmaCompanyStatusComment: "",
      additionalInfo: "",
    };

    // enable pharma products
    const productProperties = createUpdatedProductsProperties(
      compensation.rejectedProducts,
      CompensationStatuses.Accepted,
      RejectionReason.CompensationPolicy,
      ""
    );
    const updatedProperties = { ...pharmaProperties, ...productProperties };

    dispatch(updateCompensationsProperties([compensation], updatedProperties));
  };

  const handlePharmacyProductRollbackRejectClick = (
    e,
    compensation,
    product,
    index
  ) => {
    e.stopPropagation();
    setRejectionRowIndex(index);

    // enable pharma products
    const productProperties = createUpdatedProductsProperties(
      [product],
      CompensationStatuses.Accepted,
      RejectionReason.CompensationPolicy,
      ""
    );
    dispatch(updateCompensationsProperties([compensation], productProperties));
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Header() {
    const companyNameTitle =
      mode === PharmaTableMode.ShowPharmacy
        ? t("pharmacy")
        : t("pharmaCompany");

    return (
      <div className={classes.headerRow}>
        <Cell width={columnWidths.name}>
          <Typography className={classes.columnHeaderText}>
            {companyNameTitle}
          </Typography>
        </Cell>
        {mode === PharmaTableMode.ShowPharmacy && (
          <Cell width={columnWidths.customerNo}>
            <Typography className={classes.columnHeaderText}>
              {t("customerNo")}
            </Typography>
          </Cell>
        )}
        <Cell width={columnWidths.products}>
          <Typography className={classes.columnHeaderText}>
            {t("products")}
          </Typography>
        </Cell>
        <Cell width={columnWidths.compensation}>
          <Typography className={classes.columnHeaderText}>
            {t("compensation")}
          </Typography>
        </Cell>
        <Cell width={columnWidths.status}>
          <Typography className={classes.columnHeaderText}>
            {t("status")}
          </Typography>
        </Cell>
        <Cell width={columnWidths.actions}>
          <Typography className={classes.columnHeaderText}>
            {showActionsColumn ? t("actions") : ""}
          </Typography>
        </Cell>
      </div>
    );
  }

  // eslint-disable-next-line
  function Status({ compensations, noCompensations }) {
    const isSomeProductCompensated = compensations.some(
      compensation => compensation.compensatedProducts.length > 0
    );
    const isSomeProductRejected = compensations.some(
      compensation => compensation.rejectedProducts.length > 0
    );
    let rejectedText;

    if (
      mode === PharmaTableMode.ShowPharmaCompany &&
      extensionMode === ExtensionMode.Products
    ) {
      const totalUniqueRejectedProductCount = uniq(
        compensations.flatMap(compensation =>
          (compensation.rejectedProducts || []).map(
            product => product.productVNR
          )
        )
      );

      rejectedText = !isSomeProductCompensated
        ? t("all").toLowerCase()
        : totalUniqueRejectedProductCount.length;
    } else {
      const totalRejectedProductCount = compensations.reduce(
        (acc, compensation) => {
          if (compensation.rejectedProducts) {
            acc.push(...compensation.rejectedProducts);
          }
          return acc;
        },
        []
      );
      rejectedText = !isSomeProductCompensated
        ? t("all").toLowerCase()
        : totalRejectedProductCount.length;
    }

    return (
      <div>
        {isSomeProductCompensated && (
          <Typography
            className={classes.columnValueText}
            style={{ color: "#388646" }}
          >
            {t("toBeCompensated")}
          </Typography>
        )}
        {isSomeProductRejected && (
          <Typography className={classes.columnValueTextRejected}>
            {`${t("rejected")} (${rejectedText}) `}
          </Typography>
        )}
        {noCompensations && (
          <Typography
            className={classes.columnValueText}
            style={{ color: "#388646" }}
          >
            {t("noCompensationsFromInServiceCompanies")}
          </Typography>
        )}
      </div>
    );
  }

  Status.propTypes = {
    compensations: PropTypes.arrayOf(PropTypes.shape({})),
    noCompensations: PropTypes.bool,
  };

  Status.defaultProps = {
    compensations: [],
    noCompensations: false,
  };

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderRowExtension = (compensations, index) => {
    if (extensionMode === ExtensionMode.Products) {
      return (
        <div className={classes.extensionContainer}>
          <ProductTable
            tableLevel={tableLevel + 1}
            compensations={compensations}
            showActionControls={showActionControls}
            // handle product rejections/rollbacks here
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onProductRejectClick={(e, compensations, product) => {
              // Note! only one passed
              handlePharmacyProductRejectClick(
                e,
                compensations[0],
                product,
                index
              );
            }}
            // eslint-disable-next-line @typescript-eslint/no-shadow
            onProductRollbackRejectionClick={(e, compensations, product) => {
              // Note! only one passed
              handlePharmacyProductRollbackRejectClick(
                e,
                compensations[0],
                product,
                index
              );
            }}
            highlightedRowIndex={rejectionRowSubIndex}
            fixedHeight={getChildTableHeight(
              false,
              getTableDetailsOfChildTables,
              tableLevel
            )}
            hideFooter
          />
        </div>
      );
    }
    if (extensionMode === ExtensionMode.PharmaCompanies) {
      return (
        <div className={classes.extensionContainer}>
          <PharmaTable
            tableLevel={tableLevel + 1}
            mode={PharmaTableMode.ShowPharmaCompany}
            compensations={compensations}
            extensionMode={ExtensionMode.Products}
            showActionControls={showActionControls}
            hideFooter
            fixedHeight={getChildTableHeight(
              false,
              getTableDetailsOfChildTables,
              tableLevel
            )}
          />
        </div>
      );
    }
    if (extensionMode === ExtensionMode.Pharmacies && tableLevel < 1) {
      // Render only once for this extension mode by limiting tableLevel to 0 and no deeper
      return (
        <div className={classes.extensionContainer}>
          <PharmaTable
            tableLevel={tableLevel + 1}
            mode={PharmaTableMode.ShowPharmacy}
            compensations={compensations}
            extensionMode={ExtensionMode.Pharmacies}
            showActionControls={false}
            hideFooter
            fixedHeight={getChildTableHeight(
              false,
              getTableDetailsOfChildTables,
              tableLevel
            )}
          />
        </div>
      );
    }
    return null;
  };

  const rowRenderer = ({ key, index, style }) => {
    const compensationGroup = compensationGroups[index];
    const compensationOrEmptyCompensationGroup = compensationGroup.compensations
      .length
      ? compensationGroup.compensations[0]
      : compensationGroup;

    const rowExtended = extendedRowIndex === index;
    const rejected =
      compensationOrEmptyCompensationGroup.pharmaCompanyStatus ===
      CompensationStatuses.Rejected;
    // check only if in pharmacy mode. Pharma company mode is never disabled
    let pharmacyAccepted = true;
    if (mode === PharmaTableMode.ShowPharmacy) {
      pharmacyAccepted =
        compensationOrEmptyCompensationGroup.pharmacyAcceptStatus !==
        PharmacyStatuses.NotAccepted;
    }

    const showActions = showActionControls && pharmacyAccepted;

    const handleRowClick = idx => {
      if (
        extensionMode !== ExtensionMode.None &&
        compensationGroup.compensations.length
      ) {
        if (extendedRowIndex === idx) {
          setExtendedRowIndex(EXTENDED_ROW_EMPTY);
        } else {
          setExtendedRowIndex(idx);
        }
      }
    };

    const rowHighlightClass =
      rejectionRowIndex === index ? classes.borderLeftHighlight : null;
    const rowHoverClass =
      extensionMode !== ExtensionMode.None ? classes.productRowHover : null;
    const rowDisabledClass =
      pharmacyAccepted === false ? classes.rowDisabled : null;
    const rowClasses = [
      classes.productRow,
      rowHoverClass,
      rowHighlightClass,
      rowDisabledClass,
    ]
      .filter(Boolean)
      .join(" ");

    return (
      <div key={key} style={style}>
        <div
          className={rowClasses}
          onClick={() => handleRowClick(index)}
          onKeyDown={() => {}}
        >
          <Cell width={columnWidths.name}>
            <Name
              compensation={compensationOrEmptyCompensationGroup}
              emptyCompensationPharmaCompanyName={
                compensationOrEmptyCompensationGroup.name
              }
              mode={mode}
            />
          </Cell>
          {mode === PharmaTableMode.ShowPharmacy && (
            <Cell width={columnWidths.customerNo}>
              <CustomerId compensation={compensationOrEmptyCompensationGroup} />
            </Cell>
          )}
          <Cell width={columnWidths.products}>
            <ProductCount compensations={compensationGroup.compensations} />
          </Cell>
          <Cell width={columnWidths.compensation}>
            <CompensationValue
              compensations={compensationGroup.compensations}
            />
          </Cell>
          <Cell width={columnWidths.status}>
            <Status
              compensations={compensationGroup.compensations}
              noCompensations={
                compensationOrEmptyCompensationGroup.compensations?.length === 0
              }
            />
          </Cell>

          <Cell width={columnWidths.actions}>
            {showActions && (
              <>
                {sending === true && <NetworkLoader />}
                {rejected === false && sending === false && (
                  <RejectButton
                    text={t("rejectPharmacy")}
                    onClick={e => {
                      handlePharmacyRejectClick(
                        e,
                        compensationOrEmptyCompensationGroup,
                        index
                      );
                    }}
                  />
                )}

                {rejected === true && sending === false && (
                  <RollbackRejectButton
                    text={t("rollbackRejection")}
                    onClick={e => {
                      handlePharmacyRollbackRejection(
                        e,
                        compensationOrEmptyCompensationGroup,
                        index
                      );
                    }}
                  />
                )}
              </>
            )}
            {pharmacyAccepted === false && (
              <Typography className={classes.columnHeaderText}>
                {t("pharmacyNotApproved")}
              </Typography>
            )}
          </Cell>
        </div>
        {rowExtended === true &&
          renderRowExtension(compensationGroup.compensations, index)}
      </div>
    );
  };

  const getRowHeight = ({ index }) => {
    if (index !== extendedRowIndex) {
      return FIXED_ROW_HEIGHT;
    }

    const childTableHeight = calculateNeededHeightForChildTable(
      true,
      getTableDetailsOfChildTables,
      tableLevel
    );
    // Add one fixed row height to give the needed height for the parent row
    return childTableHeight + FIXED_ROW_HEIGHT;
  };

  const renderTable = () => {
    let visibleCount;
    // Always show all rows at once when on subtables
    const allRowsVisible = tableLevel > 0;
    if (allRowsVisible) {
      visibleCount = compensationGroups.length;
    } else {
      visibleCount =
        compensationGroups.length < MAX_VISIBLE_ITEMS
          ? compensationGroups.length
          : MAX_VISIBLE_ITEMS;
      visibleCount =
        visibleCount < MIN_VISIBLE_ITEMS ? MIN_VISIBLE_ITEMS : visibleCount;
    }

    if (compensationGroups.length > 0) {
      let infinityListHeight;
      if (fixedHeight) {
        infinityListHeight = fixedHeight - HEADER_FULL_HEIGHT;
        if (!hideFooter) {
          infinityListHeight -= HEADER_FULL_HEIGHT;
        }
      }

      return (
        <InfinityList
          ref={virtualizedRef}
          items={compensationGroups}
          visibleItemCount={visibleCount}
          listHeight={infinityListHeight}
          itemOverallCount={compensationGroups.length}
          getRowHeight={getRowHeight}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          isLoading={false}
          onRenderRow={rowRenderer}
          onLoad={() => {}}
        />
      );
    }
    return (
      <div className={classes.noCompensations}>
        <Typography className={classes.emptyText}>
          {t("noCompensations")}
        </Typography>
      </div>
    );
  };

  function Footer() {
    const titleText =
      mode === PharmaTableMode.ShowPharmacy
        ? t("pharmacies")
        : t("pharmaCompanies");

    const validPharmacyPharmaCompanyCount = compensationGroups.length;
    let pharmacyApprovedCompensationGroups = compensationGroups;

    // check only in pharmacy mode
    if (mode === PharmaTableMode.ShowPharmacy) {
      pharmacyApprovedCompensationGroups = compensationGroups.filter(
        group => group.pharmacyAccepted
      );
    }

    const pharmacyApprovedCompensations =
      pharmacyApprovedCompensationGroups.flatMap(g => g.compensations);

    const overallProductCount = getCompensationsProducts(
      pharmacyApprovedCompensations
    ).length;
    const compensatedProductCount = getCompensatedProducts(
      pharmacyApprovedCompensations
    ).length;

    const totalCompensationsRequested = getTotalCompensationsRequested(
      pharmacyApprovedCompensations
    );
    const totalCompensationsGiven = getTotalCompensationsGiven(
      pharmacyApprovedCompensations
    );

    const productsRejected = overallProductCount !== compensatedProductCount;
    const colWidths =
      mode === PharmaTableMode.ShowPharmacy
        ? footerWidthsSix
        : footerWidthsFive;

    return (
      <div className={classes.headerRow}>
        <Cell width={colWidths.title}>
          <OverlineValue
            title={titleText}
            overallValue={validPharmacyPharmaCompanyCount}
            actualValue={pharmacyApprovedCompensationGroups.length}
            overline={
              validPharmacyPharmaCompanyCount !==
              pharmacyApprovedCompensationGroups.length
            }
            highlight
          />
        </Cell>
        <Cell width={colWidths.products}>
          <OverlineValue
            title={t("products")}
            overallValue={overallProductCount}
            actualValue={compensatedProductCount}
            overline={overallProductCount !== compensatedProductCount}
            highlight
          />
        </Cell>
        <Cell width={colWidths.compensation}>
          <OverlineValue
            title={t("total")}
            overallValue={`${totalCompensationsRequested} ${EURO_CURRENCY}`}
            actualValue={`${totalCompensationsGiven} ${EURO_CURRENCY}`}
            overline={productsRejected}
            highlight
          />
        </Cell>
      </div>
    );
  }

  function Popup() {
    const { type, compensation, pharmacyCount, product, show, top, left } =
      popup;
    const topPos =
      top + popupSize.height > window.innerHeight
        ? window.innerHeight - popupSize.height - POPUP_MARGIN
        : top;
    const leftPos = left - popupSize.width - POPUP_MARGIN;

    const onCancel = () => {
      setPopup({ show: false });
      setRejectionRowIndex(EXTENDED_ROW_EMPTY);
      setRejectionRowSubIndex(EXTENDED_ROW_EMPTY);
    };

    const onReject = (
      rejectPopupType,
      pharmacy,
      providedProduct,
      rejectReason,
      additionalInfo
    ) => {
      setPopup({ show: false });
      setRejectionRowIndex(EXTENDED_ROW_EMPTY);
      setRejectionRowSubIndex(EXTENDED_ROW_EMPTY);

      // pharmacy being rejected
      if (rejectPopupType === RejectPopupType.Pharmacy) {
        // reject pharmacy
        const pharmaProperties = {
          pharmaCompanyStatus: CompensationStatuses.Rejected,
          pharmaCompanyStatusComment: rejectReason,
          additionalInfo: additionalInfo || "",
        };

        // reject products
        const productProperties = createUpdatedProductsProperties(
          pharmacy.compensatedProducts,
          CompensationStatuses.Rejected,
          rejectReason,
          additionalInfo || ""
        );
        const updatedProperties = { ...pharmaProperties, ...productProperties };

        // send update
        dispatch(updateCompensationsProperties([pharmacy], updatedProperties));
      } else {
        // reject product from all the pharmacies that has this
        const updatedProductProperties = createUpdatedProductsProperties(
          [providedProduct],
          CompensationStatuses.Rejected,
          rejectReason,
          additionalInfo || ""
        );

        // send update
        dispatch(
          updateCompensationsProperties([pharmacy], updatedProductProperties)
        );
      }
    };

    return (
      <RejectPopup
        type={type}
        pharmacy={compensation}
        product={product}
        pharmacyCount={pharmacyCount}
        show={show}
        top={topPos}
        left={leftPos}
        onCancel={() => onCancel()}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onReject={(type, pharmacy, product, rejectReason, additionalInfo) =>
          onReject(type, pharmacy, product, rejectReason, additionalInfo)
        }
        popupRef={ref => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (
              popupSize.width !== rect.width ||
              popupSize.height !== rect.height
            ) {
              setPopupSize({ width: rect.width, height: rect.height });
            }
          }
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Header />
      {renderTable()}
      {!hideFooter && <Footer />}
      <Popup />
    </div>
  );
}

PharmaTable.propTypes = {
  compensations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  mode: PropTypes.string.isRequired,
  showActionControls: PropTypes.bool,
  extensionMode: PropTypes.string,
  hideFooter: PropTypes.bool,
  fixedHeight: PropTypes.number,
  inServiceProfitCenters: PropTypes.arrayOf(PropTypes.shape({})),
  showInServiceProfitCenters: PropTypes.bool,
  tableLevel: PropTypes.number,
};

PharmaTable.defaultProps = {
  extensionMode: null,
  showActionControls: false,
  hideFooter: false,
  fixedHeight: null,
  inServiceProfitCenters: [],
  showInServiceProfitCenters: false,
  tableLevel: 0,
};

export default PharmaTable;
