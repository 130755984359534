import axios from "axios";
// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from "@sentry/react";
import ensureTrailingSlash from "../../../utils/url/url";
import isSentryEnabled from "../../../utils/sentry/sentry";

const getHeaders = tokens => {
  const headers = {
    Authorization: `Bearer ${tokens.accessToken}`,
    "content-type": "application/json",
  };

  if (tokens.idToken) {
    headers.idtoken = tokens.idToken;
  }
  return headers;
};

const getConfig = tokens => {
  const headers = getHeaders(tokens);
  return {
    headers,
  };
};

const createError = (status, errorMessage) => ({
  status,
  errorMessage,
});

const handleError = error => {
  if (isSentryEnabled()) {
    Sentry.captureException(error);
  }

  if (error.response) {
    // server responded with error
    return Promise.reject(
      createError(error.response.status, error.response.statusText)
    );
  }
  if (error.request) {
    // The request was made but no response was received
    return Promise.reject(createError(520, error.message));
  }
  // Something happened in setting up the request that triggered an Error
  return Promise.reject(createError(520, error.message));
};

const RestService = {
  getToken: sessionId => {
    const authUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
    const tokenApi = process.env.REACT_APP_ORIGO_TOKEN_API;
    const path = `${authUrl}${tokenApi}`;

    return axios
      .get(path, {
        headers: {
          Authorization: `OrigoSession ${sessionId}`,
        },
      })
      .then(response => Promise.resolve(response.data))
      .catch(error => handleError(error));
  },

  get: (tokens, path) =>
    // make the call
    axios
      .get(path, getConfig(tokens))
      .then(response =>
        // resolve with the result
        Promise.resolve(response.data)
      )
      .catch(error => handleError(error)),
  post: (tokens, path, data) =>
    // make the call
    axios
      .post(path, data, getConfig(tokens))
      .then(response =>
        // resolve with the result
        Promise.resolve(response.data)
      )
      .catch(error => handleError(error)),
  put: (tokens, path, data) =>
    // make the call
    axios
      .put(path, data, getConfig(tokens))
      .then(response =>
        // resolve with the result
        Promise.resolve(response.data)
      )
      .catch(error => handleError(error)),
  patch: (tokens, path, data) =>
    // make the call
    axios
      .patch(path, data, getConfig(tokens))
      .then(response =>
        // resolve with the result
        Promise.resolve(response.data)
      )
      .catch(error => handleError(error)),
  delete: (tokens, path, data) =>
    // make the call
    axios
      .delete(path, data, getConfig(tokens))
      .then(response =>
        // resolve with the result
        Promise.resolve(response.data)
      )
      .catch(error => handleError(error)),
};

export default RestService;
