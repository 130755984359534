// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import DayPickerInput from "react-day-picker/DayPickerInput";
import MomentLocaleUtils, {
  formatDate,
  parseDate,
} from "react-day-picker/moment";
import { ModifiersUtils } from "react-day-picker";
import FormHelperText from "@material-ui/core/FormHelperText";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import "react-day-picker/lib/style.css";
import { Calendar } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
  },
  inputContainer: {
    borderWidth: "1px",
    borderStyle: "solid",
    borderRadius: "5px",
    borderColor: "#a29791",
    display: "flex",
    flexDirection: "row",
    backgroundColor: "#f5f4f3",
    width: "fit-content",
  },
  error: {
    borderColor: theme.palette.error.main,
  },
  inputField: {
    padding: "1px",
  },
  calendarContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    borderLeftWidth: "1px",
    borderLeftStyle: "solid",
    borderLeftColor: "#a29791",
  },
}));

const format = "DD.MM.YYYY";

function DatePicker({
  value,
  onClick,
  showCalendar,
  locale,
  disabledDays,
  placeholder,
  error,
  helperText,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [err, setErr] = useState(false);
  useEffect(() => {
    setErr(error || err);
  }, [error, err]);

  const handleDayChange = selectedDay => {
    setErr(false);
    onClick(selectedDay);
  };

  const validate = inputValue => {
    const date = inputValue && parseDate(inputValue, format, locale);
    if (!inputValue) {
      setErr(false); // no input, that's ok
    } else if (!date) {
      // input cannot be parsed to a date
      setErr(true);
    } else if (ModifiersUtils.dayMatchesModifier(date, disabledDays)) {
      // input is a date but is is now allowed
      setErr(true);
    } else {
      setErr(false);
    }
  };

  return (
    <div>
      <div
        className={clsx(
          classes.inputContainer,
          err ? classes.error : undefined
        )}
      >
        <div className={classes.inputField}>
          <DayPickerInput
            format={format}
            formatDate={formatDate}
            placeholder={placeholder}
            parseDate={parseDate}
            dayPickerProps={{
              locale,
              localeUtils: MomentLocaleUtils,
              showWeekNumbers: true,
              disabledDays,
            }}
            inputProps={{
              style: {
                border: "none",
                textAlign: "center",
                width: "7.625rem",
                height: "2.5rem",
                color: err
                  ? theme.palette.error.main
                  : theme.palette.text.primary,
                ...theme.typography.button,
              },
              onBlur: event => {
                const inputValue = event.target.value;
                validate(inputValue);
              },
            }}
            value={value}
            onDayChange={handleDayChange}
          />
        </div>
        {showCalendar && (
          <div className={classes.calendarContainer}>
            <Calendar />
          </div>
        )}
      </div>
      {err && helperText && (
        <FormHelperText error={err}>{helperText}</FormHelperText>
      )}
    </div>
  );
}

DatePicker.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(Date)]),
  onClick: PropTypes.func,
  showCalendar: PropTypes.bool,
  locale: PropTypes.string,
  disabledDays: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.arrayOf(PropTypes.object),
    PropTypes.instanceOf(Date),
    PropTypes.arrayOf(PropTypes.instanceOf(Date)),
    PropTypes.func,
  ]),
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  helperText: PropTypes.string,
};

DatePicker.defaultProps = {
  value: new Date(),
  onClick: () => {},
  showCalendar: true,
  error: false,
  helperText: `Invalid date, use format ${format}`,
  locale: "en",
  disabledDays: null,
  placeholder: "",
};

export default DatePicker;
