// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Paper } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import moment from "moment";
import DropDown from "../common/dropDown/dropDown";
import SearchField from "../common/searchField/searchField";
import {
  getYears,
  generateCompensationPeriods,
} from "../../utils/compensation/compensation";
import OriolaPeriodRow from "./oriolaPeriodRow";
import { Header, Progress, FetchError } from "../common";
import {
  filterCompensations,
  clearFilteredCompensations,
  getInServiceProfitCenters,
  fetchProcessSteps,
} from "../../redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "1rem",
  },
  titleContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    ...theme.typography.h5,
    color: theme.palette.text.primary.main,
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  searchContainer: {
    marginRight: "3rem",
    marginLeft: "3rem",
  },
  searchResultContainer: {},
  searchResultText: {
    ...theme.typography.button,
    textTransform: "none",
    marginRight: theme.spacing(2),
  },
  searchField: { width: "500px" },
  dropdown: {
    backgroundColor: theme.palette.background.default,
    height: "40px",
    width: "165px",
  },
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

function Title() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.title}>
        {t("compensationPeriods")}
      </Typography>
    </div>
  );
}

// TODO: loading and error
function Oriola() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState(moment().year().toString());

  const {
    compensationsMap,
    filteredCompensationsMap,
    filteredCompensationCount,
    fetchError,
    fetchingMap,
    searching,
    searchError,
  } = useSelector(state => state.compensation);
  const [periods, setPeriods] = useState([]);
  const [periodCompensations, setPeriodCompensations] = useState([]);
  const inServiceProfitCenters = useSelector(
    state => state.profitCenters.inServiceProfitCenters
  );

  useEffect(() => {
    // set periods
    const currentYearPeriods =
      generateCompensationPeriods(currentYear).reverse();
    setPeriods(currentYearPeriods);

    // fetch process steps for each period in the year
    const periodStartDates = currentYearPeriods
      .map(period => period.periodStartDate)
      .join(",");
    dispatch(fetchProcessSteps(periodStartDates));
  }, [currentYear, setPeriods, dispatch]);

  useEffect(() => {
    if (periods.length === 0) {
      return;
    }

    const allPeriodCompensations = periods.map(period => {
      // get compensations based on mode
      if (filteredCompensationsMap) {
        return filteredCompensationsMap.get(period.key) || [];
      }
      return compensationsMap.get(period.key);
    });

    setPeriodCompensations(allPeriodCompensations);
  }, [
    filteredCompensationsMap,
    compensationsMap,
    periods,
    setPeriodCompensations,
  ]);

  useEffect(() => {
    dispatch(getInServiceProfitCenters());
  }, [dispatch]);

  const renderSearchControls = () => {
    const filteredCount = filteredCompensationCount;
    const searchResult = `${t("hits")} ${filteredCount}`;

    return (
      <div className={classes.searchBar}>
        <DropDown
          className={classes.dropdown}
          value={currentYear}
          noSelectionLabel="select"
          options={getYears()}
          onSelect={year => setCurrentYear(year)}
        />
        <div className={classes.searchContainer}>
          <SearchField
            className={classes.searchField}
            placeholder={t("searchPlaceholder")}
            onSearch={text => {
              if (text == null) {
                dispatch(clearFilteredCompensations());
              } else {
                const start = periods[periods.length - 1].periodStartDate;
                const end = periods[0].periodEndDate;
                dispatch(filterCompensations(start, end, text, null, null));
              }
            }}
            onClear={() => dispatch(clearFilteredCompensations())}
          />
        </div>
        {filteredCompensationsMap != null && (
          <div className={classes.searchResultContainer}>
            <Typography
              className={classes.searchResultText}
              color="textPrimary"
            >
              {searchResult}
            </Typography>
          </div>
        )}
        <Progress show={searching} />
        <FetchError error={searchError} />
      </div>
    );
  };

  return (
    <Paper className={classes.root}>
      <Header showButton={false} />
      <Title />
      {renderSearchControls()}
      {periods.map((period, index) => (
        <OriolaPeriodRow
          // eslint-disable-next-line react/no-array-index-key
          key={index}
          preload={index < 6}
          period={period}
          compensations={periodCompensations[index]}
          fetchError={fetchError}
          fetching={fetchingMap.get(period.key) != null}
          inServiceProfitCenters={inServiceProfitCenters}
        />
      ))}
    </Paper>
  );
}

export default Oriola;
