// eslint-disable-next-line no-use-before-define
import React, { useState } from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles(theme => ({
  datePickerContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  datePicker: {
    width: "45%",
  },
  datePickerSeparator: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
    marginLeft: "0.2rem",
    marginRight: "0.2rem",
  },
}));

function DateRangePicker({
  startDate,
  endDate,
  onStartDateChanged,
  onEndDateChanged,
}) {
  const classes = useStyles();
  const [startError, setStartError] = useState(false);
  const [endError, setEndError] = useState(false);

  const start =
    startDate != null
      ? moment(startDate, ["DD.MM.YYYY", "DD-MM-YYYY"]).toDate()
      : null;
  const end =
    endDate != null
      ? moment(endDate, ["DD.MM.YYYY", "DD-MM-YYYY"]).toDate()
      : null;

  const handleStartDate = date => {
    // clear
    if (date == null) {
      onStartDateChanged(null);
    }

    const startingDate = moment(date);
    if (startingDate.isValid()) {
      if (end == null || startingDate.isBefore(end)) {
        onStartDateChanged(startingDate.toDate());
      } else {
        setStartError(true);
      }
    } else {
      setStartError(date != null);
    }
  };

  const handleEndDate = date => {
    // clear
    if (date == null) {
      onEndDateChanged(null);
    }

    const endingDate = moment(date);
    if (endingDate.isValid()) {
      if (start == null || endingDate.isAfter(start)) {
        onEndDateChanged(endingDate.toDate());
      } else {
        setEndError(true);
      }
    } else {
      setEndError(date != null);
    }
  };

  return (
    <div className={classes.datePickerContainer}>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={startError}
          placeholder="dd.mm.yyyy"
          value={start}
          onChange={handleStartDate}
          className={classes.datePicker}
          variant="inline"
          inputVariant="outlined"
          format="dd.MM.yyyy"
          size="small"
        />
      </MuiPickersUtilsProvider>
      <Typography className={classes.datePickerSeparator}>-</Typography>
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <KeyboardDatePicker
          error={endError}
          placeholder="dd.mm.yyyy"
          value={end}
          onChange={handleEndDate}
          className={classes.datePicker}
          variant="inline"
          inputVariant="outlined"
          format="dd.MM.yyyy"
          size="small"
        />
      </MuiPickersUtilsProvider>
    </div>
  );
}

DateRangePicker.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  onStartDateChanged: PropTypes.func,
  onEndDateChanged: PropTypes.func,
};

DateRangePicker.defaultProps = {
  startDate: null,
  endDate: null,
  onStartDateChanged: () => {},
  onEndDateChanged: () => {},
};

export default DateRangePicker;
