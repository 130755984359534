// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import Button from "@material-ui/core/Button";
import ClearIcon from "@material-ui/icons/Clear";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  modal: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  root: {
    position: "absolute",
    width: "40%",
    backgroundColor: theme.palette.background.paper,
    borderWidth: "1px",
    borderRadius: 5,
    borderColor: theme.palette.background.paper,
    borderStyle: "solid",
    boxShadow: theme.shadows[2],
    padding: theme.spacing(1),
    outline: "none",
    "&:hover": {
      borderColor: "#6db8f3",
    },
  },
  header: {
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "flex-end",
  },
  closeButton: {
    textTransform: "none",
  },
  content: {
    marginLeft: theme.spacing(4),
    marginRight: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  buttons: {},
  cancelButton: {
    height: "2.5rem",
    textTransform: "none",
    marginRight: theme.spacing(4),
    width: "25%",
  },
  saveButton: {
    height: "2.5rem",
    textTransform: "none",
    width: "25%",
  },
  fontSizeSmall: {
    fontSize: "1.8rem",
  },
}));

function PopupLayout({
  show,
  top,
  left,
  bottom,
  right,
  children,
  onOk,
  onCancel,
  disableSave,
  popupRef,
  cancelKey,
  okKey,
  hideButtons,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <Modal
      open={show}
      onClose={onCancel}
      hideBackdrop
      disableBackdropClick
      className={classes.modal}
    >
      <div
        className={classes.root}
        style={{ top, left, bottom, right }}
        ref={popupRef}
      >
        <div className={classes.header}>
          <Button
            size="small"
            className={classes.closeButton}
            onClick={onCancel}
          >
            <ClearIcon
              fontSize="small"
              classes={{ fontSizeSmall: classes.fontSizeSmall }}
            />
            <div className={classes.cartButtonText}>{t("close")}</div>
          </Button>
        </div>
        <div className={classes.content}>
          {children}
          {hideButtons === false && (
            <div className={classes.buttons}>
              <Button
                className={classes.cancelButton}
                variant="contained"
                onClick={onCancel}
              >
                {t(`${cancelKey}`)}
              </Button>
              <Button
                className={classes.saveButton}
                variant="contained"
                color="secondary"
                onClick={onOk}
                disabled={disableSave}
              >
                {t(`${okKey}`)}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
}

PopupLayout.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  children: PropTypes.node,
  onOk: PropTypes.func,
  onCancel: PropTypes.func,
  show: PropTypes.bool,
  disableSave: PropTypes.bool,
  popupRef: PropTypes.func,
  cancelKey: PropTypes.string,
  okKey: PropTypes.string,
  hideButtons: PropTypes.bool,
};

PopupLayout.defaultProps = {
  onOk: () => {},
  onCancel: () => {},
  show: false,
  cancelKey: "cancel",
  okKey: "ok",
  hideButtons: false,
  top: null,
  left: null,
  bottom: null,
  right: null,
  children: null,
  disableSave: false,
  popupRef: () => {},
};

export default PopupLayout;
