// eslint-disable-next-line no-use-before-define
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Switch } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  centeredRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  switchText: {
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
  },
}));

function SwitchText({ checked, onChecked, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();

  let text = "";
  let color = "#eaeef5";
  const value = checked == null ? false : checked;

  if (checked != null) {
    text = checked === true ? t("toBeCompensated") : t("noCompensation");
    color = checked === true ? "#388646" : "#ec130e";
  }

  return (
    <div className={classes.centeredRow}>
      <Switch
        checked={value}
        onClick={event => onChecked(event.target.checked)}
        disabled={disabled}
        style={{ color }}
      />
      <Typography className={classes.switchText}>{text}</Typography>
    </div>
  );
}

SwitchText.propTypes = {
  checked: PropTypes.bool,
  onChecked: PropTypes.func,
  disabled: PropTypes.bool,
};

SwitchText.defaultProps = {
  checked: false,
  onChecked: () => {},
  disabled: false,
};

export default SwitchText;
