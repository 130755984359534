// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import ensureTrailingSlash from "../../utils/url/url";
import { getRefreshedToken } from "..";
import { ReportType } from "../../components/common/constants/constants";

export const Types = Object.freeze({
  FETCH_FILES_STARTED: "ORIOLA_FETCH_FILES_STARTED",
  FETCH_FILES_FINISHED: "ORIOLA_FETCH_FILES_FINISHED",
  FETCH_FILES_ERROR: "ORIOLA_FETCH_FILES_ERROR",
  FETCH_CHARGES_STARTED: "ORIOLA_FETCH_CHARGES_STARTED",
  FETCH_CHARGES_FINISHED: "ORIOLA_FETCH_CHARGES_FINISHED",
  FETCH_CHARGES_ERROR: "ORIOLA_FETCH_CHARGES_ERROR",
  FETCH_STEPS_STARTED: "ORIOLA_FETCH_STEPS_STARTED",
  FETCH_STEPS_FINISHED: "ORIOLA_FETCH_STEPS_FINISHED",
  FETCH_STEPS_ERROR: "ORIOLA_FETCH_STEPS_ERROR",
  UPDATE_CHARGES: "ORIOLA_UPDATE_CHARGES",
  UPDATE_COMPENSATION: "ORIOLA_UPDATE_COMPENSATION",
  UPDATE_SERVICE_CHARGES: "ORIOLA_UPDATE_SERVICE_CHARGES",
  UPDATE_REFUNDS: "ORIOLA_UPDATE_REFUNDS",
  UPDATE_PROCESS_STEPS_STATUSES: "ORIOLA_UPDATE_PROCESS_STEPS_STATUSES",
  SEND_CHARGES_STARTED: "ORIOLA_SEND_CHARGES_STARTED",
  SEND_CHARGES_FINISHED: "ORIOLA_SEND_CHARGES_FINISHED",
  SEND_CHARGES_ERROR: "ORIOLA_SEND_CHARGES_ERROR",
  SEND_STEPS_STARTED: "ORIOLA_SEND_STEPS_STARTED",
  SEND_STEPS_FINISHED: "ORIOLA_SEND_STEPS_FINISHED",
  SEND_STEPS_ERROR: "ORIOLA_SEND_STEPS_ERROR",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_COMPENSATION_SERVICE);
const reportFileAPi = "oriola/v1/report-file";
const oriolaApiUrl = "oriola/v1";

// -- ACTIONS

export const SERVICE_NAME_COMPENSATIONS = "compensations";
export const SERVICE_NAME_CHARGES = "serviceCharges";
export const SERVICE_NAME_REFUNDS = "refunds";

// TODO: separate these to own functions
export const fetchServiceReportFile =
  (periodStartDate, periodEndDate, reportType) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      let updateType = "";
      let serviceName = "";
      switch (reportType) {
        case ReportType.Compensation:
          updateType = Types.UPDATE_COMPENSATION;
          serviceName = SERVICE_NAME_COMPENSATIONS;
          break;
        case ReportType.Refunds:
          updateType = Types.UPDATE_REFUNDS;
          serviceName = SERVICE_NAME_REFUNDS;
          break;
        case ReportType.Charges:
          updateType = Types.UPDATE_SERVICE_CHARGES;
          serviceName = SERVICE_NAME_CHARGES;
          break;
        default:
          break;
      }

      const path = `${baseUrl}${reportFileAPi}?serviceName=${serviceName}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;
      dispatch({ type: Types.FETCH_FILES_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.get(tokens, path);

      dispatch({ type: updateType, payload: response });

      dispatch({ type: Types.FETCH_FILES_FINISHED });

      return response;
    } catch (error) {
      dispatch({ type: Types.FETCH_FILES_ERROR, payload: error });
    }
  };

export const createServiceCharges =
  (periodStartDate, periodEndDate) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      const path = `${baseUrl}${oriolaApiUrl}/charges/create?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;

      dispatch({ type: Types.FETCH_CHARGES_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const charges = await RestService.get(tokens, path);

      dispatch({ type: Types.FETCH_CHARGES_FINISHED });

      dispatch({ type: Types.UPDATE_CHARGES, payload: charges });

      return charges;
    } catch (error) {
      dispatch({ type: Types.FETCH_CHARGES_ERROR, payload: error });
    }
  };
export const fetchServiceCharges =
  // eslint-disable-next-line consistent-return
  (periodStartDate, periodEndDate) => async dispatch => {
    try {
      const path = `${baseUrl}${oriolaApiUrl}/charges?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;

      dispatch({ type: Types.FETCH_CHARGES_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const charges = await RestService.get(tokens, path);

      dispatch({ type: Types.FETCH_CHARGES_FINISHED });

      dispatch({ type: Types.UPDATE_CHARGES, payload: charges });

      return charges;
    } catch (error) {
      dispatch({ type: Types.FETCH_CHARGES_ERROR, payload: error });
    }
  };
export const updateServiceChargeStatus =
  // eslint-disable-next-line consistent-return
  (pharmaCompanyId, periodStartDate, status) => async dispatch => {
    try {
      const path = `${baseUrl}${oriolaApiUrl}/charges`;
      const data = {
        pharmaCompanyId,
        periodStartDate,
        status,
      };

      dispatch({ type: Types.SEND_CHARGES_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const charge = await RestService.post(tokens, path, data);

      dispatch({ type: Types.SEND_CHARGES_FINISHED });

      return Promise.resolve(charge);
    } catch (error) {
      dispatch({ type: Types.SEND_CHARGES_ERROR, payload: error });
    }
  };
// eslint-disable-next-line consistent-return
export const fetchProcessSteps = periodStartDates => async dispatch => {
  try {
    const path = `${baseUrl}${oriolaApiUrl}/processSteps?periodStartDates=${periodStartDates}`;

    dispatch({ type: Types.FETCH_STEPS_STARTED });

    const tokens = await dispatch(getRefreshedToken());
    const processSteps = await RestService.get(tokens, path);

    dispatch({ type: Types.FETCH_STEPS_FINISHED });

    dispatch({
      type: Types.UPDATE_PROCESS_STEPS_STATUSES,
      payload: processSteps,
    });

    return processSteps;
  } catch (error) {
    dispatch({ type: Types.FETCH_STEPS_ERROR, payload: error });
  }
};
// eslint-disable-next-line consistent-return
export const updateProcessSteps = processSteps => async dispatch => {
  try {
    const path = `${baseUrl}${oriolaApiUrl}/processSteps`;

    dispatch({ type: Types.SEND_STEPS_STARTED });

    // sigh, verify that all the fields are there
    const verifiedProcessSteps = processSteps.map(step => ({
      serviceChargesAcceptedAt: step.serviceChargesAcceptedAt || "",
      refundsApprover: step.refundsApprover || "",
      periodEndDate: step.periodEndDate || "",
      compensationApprover: step.compensationApprover || "",
      refundsStatus: step.refundsStatus || "",
      refundsAcceptedAt: step.refundsAcceptedAt || "",
      compensationAcceptedAt: step.compensationAcceptedAt || "",
      serviceChargesStatus: step.serviceChargesStatus || "",
      serviceChargesApprover: step.serviceChargesApprover || "",
      periodStartDate: step.periodStartDate || "",
      compensationStatus: step.compensationStatus || "",
    }));

    const tokens = await dispatch(getRefreshedToken());
    await RestService.post(tokens, path, {
      processSteps: verifiedProcessSteps,
    });

    dispatch({
      type: Types.UPDATE_PROCESS_STEPS_STATUSES,
      payload: verifiedProcessSteps,
    });

    dispatch({ type: Types.SEND_STEPS_FINISHED });

    return verifiedProcessSteps;
  } catch (error) {
    dispatch({ type: Types.SEND_STEPS_ERROR, payload: error });
  }
};

// -- REDUCER --

const DEFAULT_STATE = {
  fetchingFiles: false,
  fetchFilesError: null,
  fetchingCharges: false,
  fetchChargesError: null,
  fetchingSteps: false,
  fetchStepsError: null,
  charges: [],
  compensation: null,
  serviceCharges: null,
  refunds: null,
  processStatuses: [],
  sendingCharges: false,
  sendChargesError: null,
  sendingSteps: false,
  sendStepsError: null,
};

// eslint-disable-next-line default-param-last
export const oriolaReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_FILES_STARTED:
      return { ...state, fetchingFiles: true, fetchFilesError: null };
    case Types.FETCH_FILES_ERROR:
      return {
        ...state,
        fetchingFiles: false,
        fetchFilesError: action.payload,
      };
    case Types.FETCH_FILES_FINISHED:
      return { ...state, fetchingFiles: false };
    case Types.FETCH_STEPS_STARTED:
      return { ...state, fetchingSteps: true, fetchStepsError: null };
    case Types.FETCH_STEPS_ERROR:
      return {
        ...state,
        fetchingSteps: false,
        fetchStepsError: action.payload,
      };
    case Types.FETCH_STEPS_FINISHED:
      return { ...state, fetchingSteps: false };
    case Types.FETCH_CHARGES_STARTED:
      return { ...state, fetchingCharges: true, fetchChargesError: null };
    case Types.FETCH_CHARGES_ERROR:
      return {
        ...state,
        fetchingCharges: false,
        fetchChargesError: action.payload,
      };
    case Types.FETCH_CHARGES_FINISHED:
      return { ...state, fetchingCharges: false };
    case Types.SEND_CHARGES_STARTED:
      return { ...state, sendingCharges: true, sendChargesError: null };
    case Types.SEND_CHARGES_FINISHED:
      return { ...state, sendingCharges: false };
    case Types.SEND_CHARGES_ERROR:
      return {
        ...state,
        sendingCharges: false,
        sendChargesError: action.payload,
      };
    case Types.SEND_STEPS_STARTED:
      return { ...state, sendingSteps: true, sendStepsError: null };
    case Types.SEND_STEPS_FINISHED:
      return { ...state, sendingSteps: false };
    case Types.SEND_STEPS_ERROR:
      return { ...state, sendingSteps: false, sendStepsError: action.payload };
    case Types.UPDATE_REFUNDS: {
      const refunds = action.payload || [];
      return { ...state, refunds };
    }
    case Types.UPDATE_PROCESS_STEPS_STATUSES: {
      const processStatuses = action.payload;
      return { ...state, processStatuses };
    }
    case Types.UPDATE_CHARGES: {
      return { ...state, charges: action.payload };
    }
    case Types.UPDATE_COMPENSATION: {
      const compensation = action.payload || [];
      return { ...state, compensation };
    }
    case Types.UPDATE_SERVICE_CHARGES: {
      const serviceCharges = action.payload || [];
      return { ...state, serviceCharges };
    }
    default:
      return state;
  }
};
