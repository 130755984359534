// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { Typography, Paper, Tabs, Tab } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import DropDown from "../common/dropDown/dropDown";
import SearchField from "../common/searchField/searchField";
import { FetchError, Progress, Header } from "../common";
import {
  getYears,
  generateCompensationPeriods,
} from "../../utils/compensation/compensation";
import PharmaCompanyPeriodRow from "./pharmaCompanyPeriodRow";
import { filterCompensations, clearFilteredCompensations } from "../../redux";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "1rem",
  },
  tabContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  searchContainer: {
    marginRight: "3rem",
    marginLeft: "3rem",
  },
  searchResultContainer: {
    marginRight: theme.spacing(1),
  },
  searchResultText: {
    ...theme.typography.button,
    textTransform: "none",
    marginRight: theme.spacing(2),
  },
  searchField: { width: "410px" },
  dropdown: {
    backgroundColor: theme.palette.background.default,
    height: "40px",
    width: "165px",
  },
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

const TabType = Object.freeze({
  Pharmacies: 0,
  Products: 1,
});

function PharmaCompany() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(TabType.Pharmacies);
  const [periods, setPeriods] = useState([]);
  const [currentYear, setCurrentYear] = useState(moment().year().toString());
  const {
    compensationsMap,
    filteredCompensationsMap,
    filteredCompensationCount,
    fetchError,
    fetchingMap,
    searching,
    searchError,
  } = useSelector(state => state.compensation);
  const dispatch = useDispatch();
  const { userData } = useSelector(state => state.user);

  useEffect(() => {
    // set periods
    setPeriods(generateCompensationPeriods(currentYear).reverse());
  }, [currentYear, setPeriods]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ContentTabs() {
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    return (
      <div className={classes.tabContainer}>
        <Tabs
          value={tabValue}
          onChange={handleTabChange}
          indicatorColor="primary"
          textColor="secondary"
        >
          <Tab
            className={classes.tab}
            label={t("pharmacies").toUpperCase()}
            value={TabType.Pharmacies}
          />
          <Tab
            className={classes.tab}
            label={t("products").toUpperCase()}
            value={TabType.Products}
          />
        </Tabs>
      </div>
    );
  }

  // TODO: this is exactly like in the oriolaAdmin, create separate component and use that
  const renderSearchControls = () => {
    const filteredCount = filteredCompensationCount;
    const searchResult = `${t("hits")} ${filteredCount}`;

    return (
      <div className={classes.searchBar}>
        <DropDown
          className={classes.dropdown}
          value={currentYear}
          noSelectionLabel="select"
          options={getYears()}
          onSelect={year => setCurrentYear(year)}
        />
        <div className={classes.searchContainer}>
          <SearchField
            className={classes.searchField}
            placeholder={t("searchPlaceholder")}
            onSearch={text => {
              if (text == null) {
                dispatch(clearFilteredCompensations());
              } else {
                const start = periods[periods.length - 1].periodStartDate;
                const end = periods[0].periodEndDate;
                dispatch(
                  filterCompensations(
                    start,
                    end,
                    text,
                    null,
                    userData.profitCenters
                  )
                );
              }
            }}
            onClear={() => dispatch(clearFilteredCompensations())}
          />
        </div>
        {filteredCompensationsMap != null && (
          <div className={classes.searchResultContainer}>
            <Typography
              className={classes.searchResultText}
              color="textPrimary"
            >
              {searchResult}
            </Typography>
          </div>
        )}
        <Progress show={searching} />
        <FetchError error={searchError} />
      </div>
    );
  };

  return (
    <Paper className={classes.root}>
      <Header showButton />
      <ContentTabs />
      {renderSearchControls()}
      <div>
        {periods.map((period, index) => {
          // get compensations based on mode
          let compensations = null;
          if (filteredCompensationsMap != null) {
            compensations = filteredCompensationsMap.get(period.key) || [];
          } else {
            compensations = compensationsMap.get(period.key);
          }

          return (
            <PharmaCompanyPeriodRow
              // eslint-disable-next-line react/no-array-index-key
              key={index}
              preload={index < 6}
              period={period}
              productMode={tabValue === TabType.Products}
              compensations={compensations}
              fetchError={fetchError}
              fetching={fetchingMap.get(period.key) != null}
            />
          );
        })}
      </div>
    </Paper>
  );
}

export default PharmaCompany;
