// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { HighlightOff } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
// eslint-disable-next-line import/no-cycle
import { Progress } from "..";

const useStyles = makeStyles(theme => ({
  rejectButtonText: {
    ...theme.typography.button,
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
  updateProgress: {
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export function Cell({ width, children, className }) {
  return (
    <div className={className} style={{ width }}>
      {children}
    </div>
  );
}

Cell.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node,
  className: PropTypes.node,
};

Cell.defaultProps = {
  width: "",
  children: null,
  className: null,
};

export function RejectButton({ text, onClick }) {
  const classes = useStyles();
  return (
    <Button className={classes.rowDirectionCenter} onClick={onClick}>
      <HighlightOff color="secondary" />
      <Typography className={classes.rejectButtonText} noWrap>
        {text}
      </Typography>
    </Button>
  );
}

RejectButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

RejectButton.defaultProps = {
  text: "",
  onClick: () => {},
};

export function RollbackRejectButton({ text, onClick }) {
  const classes = useStyles();
  return (
    <Button className={classes.rowDirectionCenter} onClick={onClick}>
      <HighlightOff color="primary" />
      <Typography className={classes.rejectButtonText} noWrap>
        {text}
      </Typography>
    </Button>
  );
}

export function NetworkLoader() {
  const classes = useStyles();
  return (
    <div className={classes.updateProgress}>
      <Progress size={25} show />
    </div>
  );
}

RollbackRejectButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
};

RollbackRejectButton.defaultProps = {
  text: "",
  onClick: () => {},
};
