/* eslint-disable import/no-cycle */
// eslint-disable-next-line no-use-before-define
import React from "react";
import { useSelector } from "react-redux";
import Pharmacy from "../pharmacy";
import { PharmaCompany } from "../pharmaCompany";
import Oriola from "../oriolaAdmin";
import NotFound from "../404/404";
import {
  ORIOLA_ADMIN_ROLE,
  ORIOLA_VIEW_ROLE,
  PHARMACY_ROLE,
  PHARMA_CO_ROLE,
} from "./constants";

export const ViewTypes = Object.freeze({
  Pharmacy: 1,
  PharmaCompany: 2,
  Oriola: 3,
});

function ViewManager() {
  const { userData } = useSelector(state => state.user);
  const { roles } = userData;

  let activeView = null;

  // oriola user
  const targetRoles = roles.filter(
    role => role === ORIOLA_ADMIN_ROLE || role === ORIOLA_VIEW_ROLE
  );
  if (targetRoles !== null && targetRoles.length > 0) {
    activeView = ViewTypes.Oriola;
  }

  // pharma co user
  if (activeView == null) {
    const principalTargetRoles = roles.filter(role => role === PHARMA_CO_ROLE);
    if (principalTargetRoles !== null && principalTargetRoles.length > 0) {
      activeView = ViewTypes.PharmaCompany;
    }
  }

  // pharmacy user
  if (activeView == null) {
    const pharmacyTargetRoles = roles.filter(role => role === PHARMACY_ROLE);
    if (pharmacyTargetRoles !== null && pharmacyTargetRoles.length > 0) {
      activeView = ViewTypes.Pharmacy;
    }
  }

  switch (activeView) {
    case ViewTypes.Pharmacy: {
      window.location = "pharmacy";
      return <Pharmacy />;
    }
    case ViewTypes.PharmaCompany: {
      window.location = "pharmaCompany";
      return <PharmaCompany />;
    }
    case ViewTypes.Oriola: {
      window.location = "oriola";
      return <Oriola />;
    }
    default:
      return <NotFound />;
  }
}

export default ViewManager;
