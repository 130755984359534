// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Paper, Typography } from "@material-ui/core";
import DropDown from "../common/dropDown/dropDown";
import SearchField from "../common/searchField/searchField";
import {
  filterCompensations,
  clearFilteredCompensations,
  fetchPharmacyApproveData,
  updatePharmacyApproveData,
  getInServiceProfitCenters,
} from "../../redux";
import PharmacyPeriodRow from "./pharmacyPeriodRow";
import {
  getYears,
  generateCompensationPeriods,
} from "../../utils/compensation/compensation";
import { Header, ConsentPopup, Progress, FetchError } from "../common";

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "1rem",
  },
  titleContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  title: {
    ...theme.typography.h5,
    color: theme.palette.text.primary.main,
  },
  searchBar: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: theme.spacing(4),
  },
  searchContainer: {
    marginRight: "3rem",
    marginLeft: "3rem",
  },
  searchResultContainer: {},
  searchResultText: {
    ...theme.typography.button,
    textTransform: "none",
    marginRight: theme.spacing(2),
  },
  searchField: { width: "410px" },
  dropdown: {
    backgroundColor: theme.palette.background.default,
    height: "40px",
    width: "165px",
  },
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

function Title() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.title}>
        {t("compensationPeriods")}
      </Typography>
    </div>
  );
}

function Pharmacy() {
  const classes = useStyles();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [currentYear, setCurrentYear] = useState(moment().year().toString());
  const { userData } = useSelector(state => state.user);
  // TODO: handle consent fetching and sending errors
  const { approvals } = useSelector(state => state.pharmacy);
  const {
    compensationsMap,
    filteredCompensationsMap,
    filteredCompensationCount,
    fetchError,
    fetchingMap,
    searching,
    searchError,
  } = useSelector(state => state.compensation);
  const [showConsent, setShowConsent] = useState(false);
  const [periods, setPeriods] = useState([]);
  const inServiceProfitCenters = useSelector(
    state => state.profitCenters.inServiceProfitCenters
  );

  useEffect(() => {
    // set periods
    setPeriods(generateCompensationPeriods(currentYear).reverse());
  }, [currentYear, setPeriods]);

  useEffect(() => {
    const { organizationIds } = userData;
    if (organizationIds.length) {
      dispatch(fetchPharmacyApproveData(organizationIds));
    }
  }, [userData, dispatch]);

  useEffect(() => {
    if (approvals != null) {
      // check against organization ids
      const { organizationIds } = userData;
      for (let i = 0; i < organizationIds.length; i += 1) {
        const approval = approvals.find(
          item => item.customerNumber === organizationIds[i]
        );
        if (approval.approved === false) {
          setShowConsent(true);
          break;
        }
      }
    }
  }, [approvals, userData]);

  useEffect(() => {
    dispatch(getInServiceProfitCenters());
  }, [dispatch]);

  const renderSearchControls = () => {
    const filteredCount = filteredCompensationCount;
    const searchResult = `${t("hits")} ${filteredCount}`;

    return (
      <div className={classes.searchBar}>
        <DropDown
          className={classes.dropdown}
          value={currentYear}
          noSelectionLabel="select"
          options={getYears()}
          onSelect={year => setCurrentYear(year)}
        />
        <div className={classes.searchContainer}>
          <SearchField
            className={classes.searchField}
            placeholder={t("searchPlaceholder")}
            onSearch={text => {
              if (text == null) {
                dispatch(clearFilteredCompensations());
              } else {
                const { organizationIds } = userData;
                const start = periods[periods.length - 1].periodStartDate;
                const end = periods[0].periodEndDate;
                dispatch(
                  filterCompensations(start, end, text, organizationIds, null)
                );
              }
            }}
            onClear={() => dispatch(clearFilteredCompensations())}
          />
        </div>
        {filteredCompensationsMap != null && (
          <div className={classes.searchResultContainer}>
            <Typography
              className={classes.searchResultText}
              color="textPrimary"
            >
              {searchResult}
            </Typography>
          </div>
        )}
        <Progress show={searching} />
        <FetchError error={searchError} />
      </div>
    );
  };

  const onAccept = updatedApprovals => {
    updatedApprovals.forEach(updatedApproval => {
      const params = {
        ...updatedApproval,
        approverName: userData.name,
        approveTime: moment().toISOString(),
      };
      dispatch(updatePharmacyApproveData(params));
    });

    setShowConsent(false);
  };

  return (
    <Paper className={classes.root}>
      <Header showButton={false} />
      <ConsentPopup
        show={showConsent}
        top={100}
        right={110}
        onAccept={updatedApprovals => onAccept(updatedApprovals)}
        approvals={approvals || []}
      />
      <Title />
      {renderSearchControls()}
      {periods.map((period, index) => {
        // get compensations based on mode
        let compensations = null;
        if (filteredCompensationsMap != null) {
          compensations = filteredCompensationsMap.get(period.key) || [];
        } else {
          compensations = compensationsMap.get(period.key);
        }

        return (
          <PharmacyPeriodRow
            // eslint-disable-next-line react/no-array-index-key
            key={index}
            preload={index < 6}
            period={period}
            compensations={compensations}
            fetchError={fetchError}
            fetching={fetchingMap.get(period.key) != null}
            inServiceProfitCenters={inServiceProfitCenters}
            showInServiceProfitCenters
          />
        );
      })}
    </Paper>
  );
}

export default Pharmacy;
