/* Permission rules for roles:
 * prop name = role
 * static = a list of static permissions used in this application to restrict access / visibility
 * dynamic = functions for determining access based on data
 */
export const Permission = Object.freeze({
  PHARMA_COMPANY_VIEW: "pharmacompany:view",
  PHARMA_COMPANY_EDIT: "pharmacompany:edit",
  PHARMACY_VIEW: "pharmacy:view",
  ORIOLA_VIEW: "oriola:view",
  ORIOLA_EDIT: "oriola:edit",
});

const rules = {
  compensation_oriola_admin: {
    static: [Permission.ORIOLA_EDIT, Permission.ORIOLA_VIEW],
  },
  compensation_oriola_view: {
    static: [Permission.ORIOLA_VIEW],
  },
  compensation_pharma_company_edit: {
    static: [Permission.PHARMA_COMPANY_EDIT, Permission.PHARMA_COMPANY_VIEW],
  },
  compensation_pharmacy_view: {
    static: [Permission.PHARMACY_VIEW],
  },
};

const getStaticPermissionsForRole = role => rules[role]?.static || [];

const getDynamicPermissionsForRole = role => rules[role]?.dynamic || [];

const checkForRole = (role, action, data) => {
  if (!role) {
    return false;
  }

  const staticPermissions = getStaticPermissionsForRole(role);
  if (staticPermissions.includes(action)) {
    return true;
  }

  const dynamicPermissions = getDynamicPermissionsForRole(role);
  if (dynamicPermissions[action]) {
    return dynamicPermissions[action](data);
  }
  return false;
};

export const isAllowed = (user, action, data) =>
  (user.roles || []).some(role => checkForRole(role, action, data));
