// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import OrigoHeader from "../header/origoHeader";

const useStyles = makeStyles(theme => ({
  container: {
    backgroundColor: theme.palette.background.default,
    flexGrow: 1,
  },
  contentContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    marginLeft: "7.3%",
    marginRight: "7.3%",
    [theme.breakpoints.down("md")]: {
      marginLeft: 0,
      marginRight: 0,
    },
  },
  navHelper: {
    position: "absolute",
    right: 0,
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(1),
  },
  profitCenterHelper: {
    position: "absolute",
    left: 0,
    display: "flex",
    flexDirection: "column",
    paddingRight: theme.spacing(1),
  },
  title: {
    ...theme.typography.body1,
    fontWeight: "bold",
  },
}));

function NavHelper() {
  const classes = useStyles();
  return (
    <div className={classes.navHelper}>
      <Link to="/pharmacy">pharmacy</Link>
      <Link to="/oriola">oriola</Link>
      <Link to="/pharmaCompany">pharma co</Link>
    </div>
  );
}

function ProfitCenterHelper() {
  const classes = useStyles();
  const { userData } = useSelector(state => state.user);
  return (
    <div className={classes.profitCenterHelper}>
      <Typography className={classes.title}>roles</Typography>
      {userData.roles
        .filter(role => role.includes("compensation"))
        .map((value, index) => (
          // eslint-disable-next-line react/no-array-index-key
          <div key={index}>{value}</div>
        ))}
      <div />
      <Typography className={classes.title}>pc</Typography>
      {userData.profitCenters.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{value}</div>
      ))}
      <div />
      <Typography className={classes.title}>org</Typography>
      {userData.organizationIds.map((value, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <div key={index}>{value}</div>
      ))}
    </div>
  );
}

function Layout({ children }) {
  const classes = useStyles();
  const showDebugInfo = process.env.REACT_APP_DEBUG_INFO === "true";
  return (
    <div className={classes.container}>
      <OrigoHeader />
      {showDebugInfo === true && <NavHelper />}
      {showDebugInfo === true && <ProfitCenterHelper />}
      <div className={classes.contentContainer}>{children}</div>
    </div>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

Layout.defaultProps = {};

export default Layout;
