// eslint-disable-next-line no-use-before-define
import React from "react";
import { render } from "react-dom";
import { I18nextProvider } from "react-i18next";
import { Provider } from "react-redux";
import "./index.css";
import { ThemeProvider } from "@material-ui/core/styles";
import { sentry, AnalyticsUtil } from "@oriola-origo/origo-common-client-lib";
import App from "./App";
import i18n from "./localization/i18n";
import * as serviceWorker from "./serviceWorker";
import configureStore from "./redux/config/configureStore";
import theme from "./theme/index";
import packageJson from "../package.json";

AnalyticsUtil.initializeAnalytics();

// init sentry
sentry.init({ release: `${packageJson.name}@${packageJson.version}` });

const store = configureStore();

const renderApp = () => {
  render(
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <I18nextProvider i18n={i18n}>
          <App />
        </I18nextProvider>
      </ThemeProvider>
    </Provider>,
    document.getElementById("root")
  );
};

// reload only changed
if (process.env.NODE_ENV !== "production" && module.hot) {
  module.hot.accept("./App", renderApp);
}

renderApp();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
