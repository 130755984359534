// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Paper,
  Checkbox,
  FormControlLabel,
  IconButton,
} from "@material-ui/core";
import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { ExpansionImage, DashImage } from "../../images";
import {
  getTotalCompensationsGiven,
  getPharmacyApprovedCompensations,
  isPeriodApprovedByPharmaCompany,
  getCompensationsProducts,
} from "../../utils/compensation/compensation";
import ProductTable from "../common/tables/productTable/productTable";
import PharmaTable, {
  ExtensionMode,
  PharmaTableMode,
} from "../common/tables/pharmaTable/pharmaTable";
import { FetchLoader, FetchError, OrigoTextButton, Progress } from "../common";
import {
  approvePeriodCompensations,
  fetchCompensationsForPharmaCompany,
} from "../../redux";
import { EURO_CURRENCY } from "../common/constants/constants";
import TableDetailsProvider from "../common/tables/tableDetailsContext";
import SearchField from "../common/searchField/searchField";
import locallyFilterCompensations from "../oriolaAdmin/locallyFilterCompensations";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderTop: "solid 2px transparent",
    marginTop: theme.spacing(2),
  },
  rootSelected: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    borderTop: "solid 2px #ffa000",
    marginTop: theme.spacing(2),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expandIcon: {
    marginLeft: "0.5rem",
    marginRight: "1rem",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginRight: "0.5rem",
  },
  normalText: {
    ...theme.typography.body1,
    color: "#757070",
  },
  highlightText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.primary.main,
  },
  textRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  checkbox: {
    width: "10%",
  },
  checkboxLabel: {
    ...theme.typography.subtitle2,
  },
  content: {
    marginTop: theme.spacing(4),
  },
  approveButtonContainer: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  approveButtonAdjacentText: {
    marginLeft: theme.spacing(2),
    ...theme.typography.button,
    textTransform: "none",
  },
  updateProgress: {
    marginLeft: "1.5rem",
    marginRight: "1.5rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  tabsAndSearchContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  tabsContainer: {},
  searchContainer: {
    width: "100%",
    maxWidth: 500,
  },
  searchField: {
    width: "100%",
  },
}));

function Cell({ width, children }) {
  const classes = useStyles();

  return (
    <div className={classes.cell} style={{ width }}>
      {children}
    </div>
  );
}

Cell.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node,
};

Cell.defaultProps = {
  width: "",
  children: null,
};

function ApprovalText({ compensations }) {
  const classes = useStyles();
  const { t } = useTranslation();
  // no compensations
  if (compensations == null || compensations.length === 0) {
    return null;
  }

  // all the compensations on this period has the same automatic approval so find one
  const automaticApproval = compensations.reduce((acc, compensation) => {
    if (acc == null && compensation.automaticApproval != null) {
      return compensation.automaticApproval;
    }
    return acc;
  }, null);

  const date =
    automaticApproval != null
      ? moment(automaticApproval).format("DD.MM.YYYY")
      : "";

  return (
    <div className={classes.textRow}>
      <Typography className={classes.titleText}>
        {t("automaticApprovalDate")}
      </Typography>
      <Typography className={classes.highlightText}>{date}</Typography>
    </div>
  );
}

ApprovalText.propTypes = {
  compensations: PropTypes.arrayOf(
    PropTypes.shape({
      automaticApproval: PropTypes.string,
    })
  ),
};

ApprovalText.defaultProps = {
  compensations: null,
};

function Status({ compensations }) {
  const classes = useStyles();
  const { t } = useTranslation();
  // no compensations
  if (compensations == null || compensations.length === 0) {
    return null;
  }

  const periodApproved = isPeriodApprovedByPharmaCompany(compensations);
  const color = periodApproved === false ? "#ffa000" : "#388646";
  const text = periodApproved === false ? t("pending") : t("accepted");

  return (
    <FormControlLabel
      control={<Checkbox checked value style={{ color }} />}
      label={
        <Typography style={{ color }} className={classes.checkboxLabel}>
          {text}
        </Typography>
      }
      className={classes.checkbox}
    />
  );
}

Status.propTypes = {
  compensations: PropTypes.arrayOf(PropTypes.shape({})),
};

Status.defaultProps = {
  compensations: null,
};

function PharmaCompanyPeriodRow({
  period,
  productMode,
  compensations,
  fetchError,
  fetching,
  preload,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(false);
  const [totalCompensation, setTotalCompensation] = useState(0);
  const userData = useSelector(state => state.user.userData);
  const { sending } = useSelector(state => state.compensation);
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState("");
  const [targetCompensations, setTargetCompensations] = useState([]);

  useEffect(() => {
    if (compensations == null && preload === true) {
      // initial fetching
      const { profitCenters } = userData;
      const { periodStartDate, periodEndDate } = period;
      dispatch(
        fetchCompensationsForPharmaCompany(
          profitCenters,
          periodStartDate,
          periodEndDate
        )
      );
    }
  }, [compensations, preload, period, dispatch, userData]);

  useEffect(() => {
    if (compensations != null) {
      // update total compensation
      const pharmacyApprovedCompensations =
        getPharmacyApprovedCompensations(compensations);
      setTotalCompensation(
        getTotalCompensationsGiven(pharmacyApprovedCompensations)
      );
    }
  }, [compensations, setTotalCompensation]);

  useEffect(() => {
    const filteredCompensations = locallyFilterCompensations(
      compensations,
      searchValue
    );
    setTargetCompensations(filteredCompensations);
  }, [compensations, setTargetCompensations, searchValue]);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ExpandIcon() {
    const doSetExpanded = value => {
      // load compensations if not loaded yet
      if (compensations == null && value === true) {
        // initial fetching
        const { profitCenters } = userData;
        const { periodStartDate, periodEndDate } = period;
        dispatch(
          fetchCompensationsForPharmaCompany(
            profitCenters,
            periodStartDate,
            periodEndDate
          )
        );
      }
      setExpanded(value);
    };

    return (
      <div className={classes.expandIcon}>
        {expanded === true && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(false)}
            disabled={fetching}
          >
            <DashImage />
          </IconButton>
        )}
        {expanded === false && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(true)}
            disabled={fetching}
          >
            <ExpansionImage />
          </IconButton>
        )}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function TitleText() {
    const { periodStartDate, periodEndDate } = period;

    // count by mode
    let count = "-";
    if (compensations != null) {
      count = compensations.length;
      if (productMode === true) {
        count = getCompensationsProducts(compensations).length;
      }
    }

    const text = `${t("requestsOnPeriod")} ${moment(periodStartDate).format(
      "DD."
    )}-${moment(periodEndDate).format("DD.MM.YYYY")} (${count})`;

    return (
      <div className={classes.textRow}>
        <Typography className={classes.titleText}>{text}</Typography>
        <Progress
          show={fetching && preload === true && compensations == null}
          size={25}
        />
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function TotalCompensation() {
    // no compensations
    if (compensations == null || compensations.length === 0) {
      return null;
    }

    return (
      <div className={classes.textRow}>
        <Typography className={classes.titleText}>
          {t("totalCompensationValue")}
        </Typography>
        <Typography
          className={classes.highlightText}
        >{`${totalCompensation} ${EURO_CURRENCY}`}</Typography>
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  const onRenderContent = compensationData => {
    // loading
    if (fetching === true) {
      return <FetchLoader loading />;
    }
    if (fetchError != null) {
      return <FetchError error={fetchError} />;
    }

    // sanity
    if (compensationData == null) {
      return null;
    }

    const periodApproved = isPeriodApprovedByPharmaCompany(compensationData);
    // TODO: Approved periods should not show products
    // rejection or roll back options
    if (productMode === true) {
      return (
        <div className={classes.content}>
          <TableDetailsProvider>
            <ProductTable
              compensations={targetCompensations}
              showExtension
              showActionControls={periodApproved === false}
              showPharmacyProductCount
            />
          </TableDetailsProvider>
        </div>
      );
    }
    return (
      <div className={classes.content}>
        <TableDetailsProvider>
          <PharmaTable
            compensations={targetCompensations}
            mode={PharmaTableMode.ShowPharmacy}
            showActionControls={periodApproved === false}
            extensionMode={ExtensionMode.Products}
          />
        </TableDetailsProvider>
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Content() {
    return <div>{expanded === true && onRenderContent(compensations)}</div>;
  }

  const doApprovePeriodCompensations = () => {
    const { periodStartDate, periodEndDate } = period;
    const { userId, name, profitCenters } = userData;

    dispatch(
      approvePeriodCompensations(
        profitCenters,
        periodStartDate,
        periodEndDate,
        userId,
        name
      )
    ).then(() => {
      // update all
      dispatch(
        fetchCompensationsForPharmaCompany(
          profitCenters,
          periodStartDate,
          periodEndDate
        )
      );
    });
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function ApprovalButton() {
    if (compensations == null || compensations.length === 0) {
      return null;
    }

    const getDate = value =>
      value != null ? moment(value).format("YYYY-DD-MM HH:mm") : "-";

    const solveApprover = value => {
      if (value == null || value === "automatic") {
        return t("automatic");
      }
      return value;
    };

    const { acceptedByPharmaCompanyUsername, pharmaCompanyAcceptedAt } =
      compensations[0];

    const approvedText = `${t("approvedBy")} ${solveApprover(
      acceptedByPharmaCompanyUsername
    )} ${t("at")}  ${getDate(pharmaCompanyAcceptedAt)}`;
    const notApprovedText = t("approvalButtonExplanation");

    const periodApproved = isPeriodApprovedByPharmaCompany(compensations);
    const text = periodApproved === true ? approvedText : notApprovedText;

    return (
      <div className={classes.approveButtonContainer}>
        {((sending === false && fetching === false) ||
          periodApproved === true) && (
          <OrigoTextButton
            className={classes.excelButton}
            size="medium"
            color="secondary"
            onClick={() => doApprovePeriodCompensations()}
            disabled={periodApproved}
          >
            {t("approval")}
          </OrigoTextButton>
        )}
        {(sending === true || fetching === true) &&
          periodApproved === false && (
            <div className={classes.updateProgress}>
              <Progress size={25} show />
            </div>
          )}
        <Typography className={classes.approveButtonAdjacentText}>
          {text}
        </Typography>
      </div>
    );
  }

  const renderTabsAndSearch = () => (
    <div className={classes.tabsAndSearchContainer}>
      <div className={classes.searchContainer}>
        <SearchField
          className={classes.searchField}
          placeholder={t("principalSearchPlaceholder")}
          onSearch={text => setSearchValue(text || "")}
          onClear={() => setSearchValue("")}
        />
      </div>
    </div>
  );

  const rootClass = expanded ? classes.rootSelected : classes.root;
  return (
    <Paper className={rootClass}>
      <div className={classes.header}>
        <Cell width="3%">
          <ExpandIcon />
        </Cell>
        <Cell width="32%">
          <TitleText />
        </Cell>
        <Cell width="26%">
          <ApprovalText compensations={compensations} />
        </Cell>
        <Cell width="12%">
          <Status compensations={compensations} />
        </Cell>
        <Cell width="26%">
          <TotalCompensation />
        </Cell>
      </div>
      <ApprovalButton />
      {expanded && renderTabsAndSearch()}
      <Content />
    </Paper>
  );
}

PharmaCompanyPeriodRow.propTypes = {
  period: PropTypes.shape({
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
  }).isRequired,
  productMode: PropTypes.bool,
  compensations: PropTypes.arrayOf(
    PropTypes.shape({
      automaticApproval: PropTypes.string,
      acceptedByPharmaCompanyUsername: PropTypes.string,
      pharmaCompanyAcceptedAt: PropTypes.string,
    })
  ).isRequired,
  fetching: PropTypes.bool,
  fetchError: PropTypes.node,
  preload: PropTypes.bool,
};

PharmaCompanyPeriodRow.defaultProps = {
  productMode: false,
  fetching: false,
  fetchError: null,
  preload: false,
};

export default PharmaCompanyPeriodRow;
