// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, FormControlLabel, Checkbox } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import ConsentPopupLayout from "../consentPopupLayout/consentPopupLayout";

const useStyles = makeStyles(theme => ({
  header: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  consent: {
    marginBottom: theme.spacing(2),
  },
  consentText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  checkboxes: {
    marginBottom: theme.spacing(2),
  },
  closingText: {
    marginBottom: theme.spacing(2),
  },
}));

function ConsentPopup({ show, top, left, bottom, right, onAccept, approvals }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [localApprovals, setLocalApprovals] = useState([]);

  useEffect(() => {
    setLocalApprovals(approvals);
  }, [approvals]);

  const close = () => {
    onAccept(localApprovals);
  };

  const isAllApproved = () => {
    for (let i = 0; i < localApprovals.length; i += 1) {
      const approved = localApprovals[i].approved || false;
      if (approved === false) {
        return false;
      }
    }
    return true;
  };

  const checkApprove = (customerNumber, value) => {
    const updatedApprovals = localApprovals.slice(0);

    for (let i = 0; i < updatedApprovals.length; i += 1) {
      if (updatedApprovals[i].customerNumber === customerNumber) {
        updatedApprovals[i].approved = value;
        break;
      }
    }
    setLocalApprovals(updatedApprovals);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function AcceptanceRequest() {
    const allApproved = isAllApproved();
    const title =
      allApproved === true
        ? t("acceptanceCompensationService")
        : t("consentTitle");
    const info =
      allApproved === true ? t("approvalInfoText") : t("consentText");
    return (
      <div>
        <div className={classes.header}>
          <Typography variant="subtitle2">{title}</Typography>
        </div>
        <div className={classes.consent}>
          <Typography className={classes.consentText}>{info}</Typography>
        </div>
        <div className={classes.checkboxes}>
          {localApprovals.map(approval => (
            <div key={approval.customerNumber}>
              <FormControlLabel
                control={
                  <Checkbox
                    style={{ color: "#388646" }}
                    checked={approval.approved === true}
                    value={approval.approved === true}
                    onChange={e =>
                      checkApprove(approval.customerNumber, e.target.checked)
                    }
                  />
                }
                label={
                  <Typography noWrap>{`${approval.customerNumber} ${
                    approval.customerPharmacyName || ""
                  }`}</Typography>
                }
                labelPlacement="end"
              />
            </div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <ConsentPopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      onOk={close}
      okKey="close"
      hideClose
      disableSave={isAllApproved() === false}
    >
      <AcceptanceRequest />
    </ConsentPopupLayout>
  );
}

ConsentPopup.propTypes = {
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  approvals: PropTypes.arrayOf(PropTypes.shape({})),
  onAccept: PropTypes.func,
};

ConsentPopup.defaultProps = {
  top: null,
  left: null,
  bottom: null,
  right: null,
  show: false,
  approvals: [],
  onAccept: () => {},
};

export default ConsentPopup;
