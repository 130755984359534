// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import ensureTrailingSlash from "../../utils/url/url";
import { getRefreshedToken } from "..";

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_COMPENSATION_SERVICE);
const profitCentersApi = "profitCenters/v1";

export const Types = Object.freeze({
  FETCH_STARTED: "PROFIT_CENTERS_FETCH_STARTED",
  FETCH_FINISHED: "PROFIT_CENTERS_FETCH_FINISHED",
  FETCH_ERROR: "PROFIT_CENTERS_FETCH_ERROR",
  UPDATE_IN_SERVICE_PROFIT_CENTERS: "PROFIT_CENTERS_UPDATE",
});

// eslint-disable-next-line consistent-return
export const getInServiceProfitCenters = () => async dispatch => {
  try {
    const path = `${baseUrl}${profitCentersApi}`;

    dispatch({ type: Types.FETCH_STARTED });

    const tokens = await dispatch(getRefreshedToken());
    const response = await RestService.get(tokens, path);

    dispatch({ type: Types.FETCH_FINISHED });

    dispatch({
      type: Types.UPDATE_IN_SERVICE_PROFIT_CENTERS,
      payload: response,
    });

    return response;
  } catch (error) {
    dispatch({ type: Types.FETCH_ERROR, payload: error });
  }
};

// -- REDUCER --

const INIT_STATE = {
  inServiceProfitCenters: [],
};

// eslint-disable-next-line default-param-last
export const profitCentersReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_STARTED: {
      return { ...state, fetchError: null };
    }
    case Types.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Types.FETCH_FINISHED: {
      return { ...state, fetching: false };
    }
    case Types.UPDATE_IN_SERVICE_PROFIT_CENTERS: {
      return { ...state, inServiceProfitCenters: action.payload };
    }
    default:
      return state;
  }
};
