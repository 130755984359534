// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import ensureTrailingSlash from "../../utils/url/url";
import { getRefreshedToken } from "..";
import { RuleStatus } from "../../components/common/constants/constants";

import mockUndefinedRules from "../../mockData/undefined_rules";
import mockDefinedRules from "../../mockData/defined_rules";
import mockDefaultRule from "../../mockData/default_rule";

const doGetMockRules = (startIndex, stopIndex, source) => ({
  hits: source.slice(startIndex, stopIndex + 1),
  overallCount: source.length,
});

const doSearchMockRules = (source, productName) => {
  const hits = source.filter(rule => {
    const name = rule.productName;
    if (name != null) {
      return name.toLowerCase().includes(productName.toLowerCase());
    }
    return false;
  });
  return {
    hits,
    overallCount: hits.length,
  };
};

export const Types = Object.freeze({
  RULES_ACTIVE_FETCH_STARTED: "RULES_ACTIVE_FETCH_STARTED",
  RULES_ACTIVE_FETCH_FINISHED: "RULES_ACTIVE_FETCH_FINISHED",
  RULES_ACTIVE_FETCH_ERROR: "RULES_ACTIVE_FETCH_ERROR",
  RULES_UNDEFINED_FETCH_STARTED: "RULES_UNDEFINED_FETCH_STARTED",
  RULES_UNDEFINED_FETCH_FINISHED: "RULES_UNDEFINED_FETCH_FINISHED",
  RULES_UNDEFINED_FETCH_ERROR: "RULES_UNDEFINED_FETCH_ERROR",
  RULES_DEFAULT_FETCH_STARTED: "RULES_DEFAULT_FETCH_STARTED",
  RULES_DEFAULT_FETCH_FINISHED: "RULES_DEFAULT_FETCH_FINISHED",
  RULES_DEFAULT_FETCH_ERROR: "RULES_DEFAULT_FETCH_ERROR",
  RULES_SEND_STARTED: "RULES_SEND_STARTED",
  RULES_SEND_FINISHED: "RULES_SEND_FINISHED",
  RULES_SEND_ERROR: "RULES_SEND_ERROR",
  RULES_UPDATE_ACTIVE_RULES: "RULES_UPDATE_ACTIVE_RULES",
  RULES_UPDATE_UNDEFINED_RULES: "RULES_UPDATE_UNDEFINED_RULES",
  RULES_UPDATE_DEFAULT_RULES: "RULES_UPDATE_DEFAULT_RULES",
  RULE_CLEAR_ERRORS: "RULE_CLEAR_ERRORS",
  RULE_UPDATE_ORIGINAL_RULES: "RULE_UPDATE_ORIGINAL_RULES",
  RULES_SEARCH_STARTED: "RULES_SEARCH_STARTED",
  RULES_SEARCH_FINISHED: "RULES_SEARCH_FINISHED",
  RULES_SEARCH_ERROR: "RULES_SEARCH_ERROR",
  RULES_SEARCH_CLEAR: "RULES_SEARCH_CLEAR",
});

const useMock = false;
const DEFAULT_RULE_VNR = "DEFAULT_RULE";
const baseUrl = ensureTrailingSlash(process.env.REACT_APP_COMPENSATION_SERVICE);
const ruleSearchApi = "rule/v1/search";
const ruleDefaultApi = "rule/v1/default";
const ruleSaveApi = "rule/v1";

// -- ACTIONS

export const fetchDefaultRule =
  profitCenters =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      const profitCentersStr = profitCenters.join(",");
      const path = `${baseUrl}${ruleDefaultApi}?profitCenters=${profitCentersStr}`;

      // start fetching
      dispatch({ type: Types.RULES_DEFAULT_FETCH_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      let rules = null;
      if (useMock === true) {
        rules = [mockDefaultRule];
      } else {
        rules = await RestService.get(tokens, path);
      }

      // update
      dispatch({ type: Types.RULES_UPDATE_DEFAULT_RULES, payload: rules });

      // finished
      dispatch({ type: Types.RULES_DEFAULT_FETCH_FINISHED });

      // return result
      return rules;
    } catch (error) {
      dispatch({ type: Types.RULES_DEFAULT_FETCH_ERROR, payload: error });
    }
  };

const filterDefaultRulesOut = rules =>
  rules.filter(rule => rule.productVNR !== DEFAULT_RULE_VNR);

export const searchRules =
  (profitCenters, startIndex, stopIndex, query) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const profitCentersStr = profitCenters.join(",");
      const pageSize = stopIndex - startIndex + 1;
      const searchText = query || "";
      const filter = "";
      const path = `${baseUrl}${ruleSearchApi}?profitCenters=${profitCentersStr}&query=${searchText}&startIndex=${startIndex}&pageSize=${pageSize}&filters=${filter}`;

      // start fetching
      dispatch({ type: Types.RULES_SEARCH_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      let result = null;
      if (useMock === true) {
        const source = mockUndefinedRules.map(rule => ({
          ...rule,
          productVNR: `${rule.productVNR}jee`,
        }));
        result = doSearchMockRules(source, query);
      } else {
        result = await RestService.get(tokens, path);
      }

      let foundRules = filterDefaultRulesOut(result.hits);
      // append if not starting from zero index
      if (startIndex > 0) {
        // get existing and append results
        const existingRules = getState().rules.activeRules.slice(0);
        foundRules = [...existingRules, ...foundRules];
      }

      // separate results
      const activeRules = foundRules.filter(
        rule => rule.status !== RuleStatus.UNDEFINED
      );
      const inActiveRules = foundRules.filter(
        rule => rule.status === RuleStatus.UNDEFINED
      );

      // update
      dispatch({
        type: Types.RULES_UPDATE_ACTIVE_RULES,
        payload: { rules: activeRules, count: activeRules.length },
      });

      // update
      dispatch({
        type: Types.RULES_UPDATE_UNDEFINED_RULES,
        payload: { rules: inActiveRules, count: inActiveRules.length },
      });

      // finished
      dispatch({
        type: Types.RULES_SEARCH_FINISHED,
      });

      // return result
      return result;
    } catch (error) {
      dispatch({ type: Types.RULES_SEARCH_FINISHED, payload: error });
    }
  };

export const fetchActiveRules =
  (profitCenters, startIndex, stopIndex, query) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const profitCentersStr = profitCenters.join(",");
      const pageSize = stopIndex - startIndex + 1;
      const filter = "NOT status:UNDEFINED";
      const searchText = query || "";
      const path = `${baseUrl}${ruleSearchApi}?profitCenters=${profitCentersStr}&query=${searchText}&startIndex=${startIndex}&pageSize=${pageSize}&filters=${filter}`;

      // start fetching
      dispatch({ type: Types.RULES_ACTIVE_FETCH_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      let result = null;
      if (useMock === true) {
        result = doGetMockRules(startIndex, stopIndex, mockDefinedRules);
      } else {
        result = await RestService.get(tokens, path);
      }

      let foundRules = filterDefaultRulesOut(result.hits);
      // append if not starting from zero index
      if (startIndex > 0) {
        // get existing and append results
        const existingRules = getState().rules.activeRules.slice(0);
        foundRules = [...existingRules, ...foundRules];
      }

      // update
      dispatch({
        type: Types.RULES_UPDATE_ACTIVE_RULES,
        payload: { rules: foundRules, count: result.overallCount },
      });

      // finished
      dispatch({ type: Types.RULES_ACTIVE_FETCH_FINISHED });

      // return result
      return result;
    } catch (error) {
      dispatch({ type: Types.RULES_ACTIVE_FETCH_ERROR, payload: error });
    }
  };

export const fetchUndefinedRules =
  (profitCenters, startIndex, stopIndex, query) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const profitCentersStr = profitCenters.join(",");
      const pageSize = stopIndex - startIndex + 1;
      const filter = "status:UNDEFINED";
      const searchText = query || "";
      const path = `${baseUrl}${ruleSearchApi}?profitCenters=${profitCentersStr}&query=${searchText}&startIndex=${startIndex}&pageSize=${pageSize}&filters=${filter}`;

      // start fetching
      dispatch({ type: Types.RULES_UNDEFINED_FETCH_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      let result = null;

      if (useMock === true) {
        result = doGetMockRules(
          startIndex,
          stopIndex,
          mockUndefinedRules.map(rule => ({
            ...rule,
            productVNR: `${rule.productVNR}jee`,
          }))
        );
      } else {
        result = await RestService.get(tokens, path);
      }

      let foundRules = filterDefaultRulesOut(result.hits);
      // append if not starting from zero index
      if (startIndex > 0) {
        // get existing and append results
        const existingRules = getState().rules.undefinedRules.slice(0);
        foundRules = [...existingRules, ...foundRules];
      }

      // update
      dispatch({
        type: Types.RULES_UPDATE_UNDEFINED_RULES,
        payload: { rules: foundRules, count: result.overallCount },
      });

      // finished
      dispatch({ type: Types.RULES_UNDEFINED_FETCH_FINISHED });

      // return result
      return result;
    } catch (error) {
      dispatch({ type: Types.RULES_UNDEFINED_FETCH_ERROR, payload: error });
    }
  };

const updateRulesLocally = (state, dispatch, rules) => {
  const { undefinedRules, activeRules, defaultRules } = state.rules;
  let { undefinedRuleCount, activeRuleCount } = state.rules;

  // undefined
  const undefinedRulesUpdated = undefinedRules.slice(0);
  const undefinedRuleVnrs = undefinedRules.map(rule => rule.productVNR);

  // active
  const activeRulesUpdated = activeRules.slice(0);
  const activeRuleVnrs = activeRules.map(rule => rule.productVNR);

  // unfortunately there can be several default rules since companies can still have several profit centers
  let updatedDefaultRules = null;

  //  update flags
  let updateUndefined = false;
  let updateDefault = false;
  let updateActive = false;

  // go trough modified rules
  for (let i = 0; i < rules.length; i += 1) {
    const rule = rules[i];

    // default rule
    if (rule.productVNR === DEFAULT_RULE_VNR) {
      // update all with the same data
      updatedDefaultRules = defaultRules
        .slice(0)
        .map(dRule => ({ ...dRule, ...rule }));
      updateDefault = true;
      // eslint-disable-next-line no-continue
      continue;
    }

    // is in the undefined section, remove
    const index = undefinedRuleVnrs.indexOf(rule.productVNR);
    if (index !== -1) {
      // add to active
      activeRulesUpdated.push({ ...rule });
      activeRuleCount += 1;

      // remove from undefined
      undefinedRulesUpdated.splice(index, 1);
      undefinedRuleCount -= 1;

      // needs updating
      updateUndefined = true;
      updateActive = true;
    } else {
      // update active rule
      const idx = activeRuleVnrs.indexOf(rule.productVNR);
      if (idx !== -1) {
        // update
        activeRulesUpdated[idx] = { ...rule };

        // needs update
        updateActive = true;
      }
    }
  }

  // default needs updating
  if (updateDefault === true) {
    dispatch({
      type: Types.RULES_UPDATE_DEFAULT_RULES,
      payload: updatedDefaultRules,
    });
  }

  // undefined needs updating
  if (updateUndefined === true) {
    // update undefined
    dispatch({
      type: Types.RULES_UPDATE_UNDEFINED_RULES,
      payload: { rules: undefinedRulesUpdated, count: undefinedRuleCount },
    });
  }

  if (updateActive === true) {
    // sort active if there are new rules
    activeRulesUpdated.sort((a, b) => {
      const nameA = a.productName || "";
      const nameB = b.productName || "";
      return nameA.localeCompare(nameB);
    });

    // update active
    dispatch({
      type: Types.RULES_UPDATE_ACTIVE_RULES,
      payload: { rules: activeRulesUpdated, count: activeRuleCount },
    });
  }
};

export const modifyRuleLocally = rule => (dispatch, getState) => {
  const { rules } = getState();

  if (rule.productVNR === DEFAULT_RULE_VNR) {
    const defaultRules = rules.defaultRules.slice(0);
    const updated = defaultRules.slice(0).map(dRule => ({ ...dRule, ...rule }));
    dispatch({ type: Types.RULES_UPDATE_DEFAULT_RULES, payload: updated });
  } else {
    const undefinedRules = rules.undefinedRules.slice(0);
    const { undefinedRuleCount } = rules;
    const activeRules = rules.activeRules.slice(0);
    const { activeRuleCount } = rules;

    // is on undefined list
    const index = undefinedRules
      .map(undefinedRule => undefinedRule.productVNR)
      .indexOf(rule.productVNR);
    if (index !== -1) {
      undefinedRules[index] = { ...rule };
      dispatch({
        type: Types.RULES_UPDATE_UNDEFINED_RULES,
        payload: { rules: undefinedRules, count: undefinedRuleCount },
      });
    } else {
      const idx = activeRules
        .map(activeRule => activeRule.productVNR)
        .indexOf(rule.productVNR);
      activeRules[idx] = { ...rule };
      dispatch({
        type: Types.RULES_UPDATE_ACTIVE_RULES,
        payload: { rules: activeRules, count: activeRuleCount },
      });
    }
  }
};

export const updateRules =
  rules =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const path = `${baseUrl}${ruleSaveApi}`;

      // prune
      const updatedRules = rules.map(rule => {
        const pruned = { ...rule };
        // no need to store algolia stuff
        // eslint-disable-next-line no-underscore-dangle
        delete pruned._highlightResult;

        // make sure that old fields are gone
        delete pruned.startTime;
        delete pruned.endTime;

        // make sure that start date and end date exists (otherwise validation fails)
        if (pruned.startDate == null) {
          pruned.startDate = null;
        }

        if (pruned.endDate == null) {
          pruned.endDate = null;
        }
        return pruned;
      });

      const body = { rules: updatedRules };

      // start fetching
      const productVNRs = rules.map(rule => rule.productVNR);
      dispatch({ type: Types.RULES_SEND_STARTED, payload: productVNRs });

      const tokens = await dispatch(getRefreshedToken());

      if (useMock === false) {
        await RestService.post(tokens, path, body);
      }

      // modify locally
      updateRulesLocally(getState(), dispatch, rules);

      // finished
      dispatch({ type: Types.RULES_SEND_FINISHED });

      return Promise.resolve(updatedRules);
    } catch (error) {
      const ids = rules.map(rule => rule.productVNR);
      dispatch({ type: Types.RULES_SEND_ERROR, payload: { ids, error } });
    }
  };

export const clearRuleErrors = () => ({
  type: Types.RULE_CLEAR_ERRORS,
});

export const updateStoredOriginalRule = rule => (dispatch, getState) => {
  const storedOriginalRules = getState().rules.storedOriginalRules.slice(0);

  // find and update
  const ruleCopy = JSON.parse(JSON.stringify(rule));
  const index = storedOriginalRules
    .map(storedOriginalRule => storedOriginalRule.productVNR)
    .indexOf(rule.productVNR);
  if (index !== -1) {
    storedOriginalRules[index] = ruleCopy;
  } else {
    storedOriginalRules.push(ruleCopy);
  }
  dispatch({
    type: Types.RULE_UPDATE_ORIGINAL_RULES,
    payload: storedOriginalRules,
  });
};

export const removeStoredOriginalRule = rule => (dispatch, getState) => {
  const storedOriginalRules = getState().rules.storedOriginalRules.slice(0);

  const index = storedOriginalRules
    .map(storedOriginalRule => storedOriginalRule.productVNR)
    .indexOf(rule.productVNR);
  if (index !== -1) {
    storedOriginalRules.splice(index, 1);
    dispatch({
      type: Types.RULE_UPDATE_ORIGINAL_RULES,
      payload: storedOriginalRules,
    });
  }
};

// -- REDUCER --

const DEFAULT_STATE = {
  fetchingActiveRules: false,
  fetchingUndefinedRules: false,
  fetchingDefaultRule: false,
  searchingRules: false,
  activeRuleFetchError: null,
  undefinedRuleFetchError: null,
  defaultRuleFetchError: null,
  searchRuleError: null,
  activeRules: [],
  activeRuleCount: 0,
  undefinedRules: [],
  undefinedRuleCount: 0,
  defaultRules: [],
  sendingRuleIds: [],
  ruleSendingError: null,
  storedOriginalRules: [],
};

// eslint-disable-next-line default-param-last
export const rulesReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Types.RULE_CLEAR_ERRORS:
      return {
        ...state,
        activeRuleFetchError: null,
        undefinedRuleFetchError: null,
        defaultRuleFetchError: null,
        ruleSendingError: null,
      };
    case Types.RULES_ACTIVE_FETCH_STARTED:
      return {
        ...state,
        fetchingActiveRules: true,
        activeRuleFetchError: null,
      };
    case Types.RULES_ACTIVE_FETCH_FINISHED:
      return { ...state, fetchingActiveRules: false };
    case Types.RULES_ACTIVE_FETCH_ERROR:
      return {
        ...state,
        fetchingActiveRules: false,
        activeRuleFetchError: action.payload,
      };
    case Types.RULES_UNDEFINED_FETCH_STARTED:
      return {
        ...state,
        fetchingUndefinedRules: true,
        undefinedRuleFetchError: null,
      };
    case Types.RULES_UNDEFINED_FETCH_FINISHED:
      return { ...state, fetchingUndefinedRules: false };
    case Types.RULES_UNDEFINED_FETCH_ERROR:
      return {
        ...state,
        fetchingUndefinedRules: false,
        undefinedRuleFetchError: action.payload,
      };
    case Types.RULES_DEFAULT_FETCH_STARTED:
      return {
        ...state,
        fetchingDefaultRule: true,
        defaultRuleFetchError: null,
      };
    case Types.RULES_DEFAULT_FETCH_FINISHED:
      return { ...state, fetchingDefaultRule: false };
    case Types.RULES_DEFAULT_FETCH_ERROR:
      return {
        ...state,
        fetchingDefaultRule: false,
        defaultRuleFetchError: action.payload,
      };
    case Types.RULES_SEND_STARTED:
      return {
        ...state,
        sendingRuleIds: action.payload,
        ruleSendingError: null,
      };
    case Types.RULES_SEND_FINISHED:
      return { ...state, sendingRuleIds: [] };
    case Types.RULES_SEND_ERROR:
      return { ...state, sendingRuleIds: [], ruleSendingError: action.payload };
    case Types.RULES_UPDATE_ACTIVE_RULES: {
      const { rules, count } = action.payload;
      return { ...state, activeRules: rules, activeRuleCount: count };
    }
    case Types.RULES_UPDATE_UNDEFINED_RULES: {
      const { rules, count } = action.payload;
      return { ...state, undefinedRules: rules, undefinedRuleCount: count };
    }
    case Types.RULES_UPDATE_DEFAULT_RULES: {
      return { ...state, defaultRules: action.payload };
    }
    case Types.RULE_UPDATE_ORIGINAL_RULES: {
      return { ...state, storedOriginalRules: action.payload };
    }
    case Types.RULES_SEARCH_STARTED:
      return { ...state, searchingRules: true, searchRuleError: null };
    case Types.RULES_SEARCH_FINISHED: {
      return { ...state, searchingRules: false };
    }
    case Types.RULES_SEARCH_ERROR:
      return {
        ...state,
        searchingRules: false,
        searchRuleError: action.payload,
      };
    default:
      return state;
  }
};
