// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";

function Progress({ show, className, size }) {
  const wantedSize = size || 36;
  if (show) {
    return <CircularProgress className={className} size={wantedSize} />;
  }
  return null;
}

Progress.propTypes = {
  show: PropTypes.bool.isRequired,
  className: PropTypes.node,
  size: PropTypes.number,
};

Progress.defaultProps = {
  className: null,
  size: null,
};

export default Progress;
