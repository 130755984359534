// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
// eslint-disable-next-line import/no-cycle
import { TooltipTypography } from "..";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  valueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  valueTextBold: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
    fontWeight: "bold",
  },
  valueMargin: {
    marginLeft: theme.spacing(1),
  },
  valueOverline: {
    textDecoration: "line-through",
  },
}));

function OverlineValue({
  title,
  overallValue,
  actualValue,
  overline,
  highlight,
}) {
  const classes = useStyles();

  const textHighlight =
    highlight === true ? classes.valueTextBold : classes.valueText;
  const overallHighlightClass =
    overline === true ? classes.valueOverline : textHighlight;

  return (
    <div className={classes.container}>
      {title != null && (
        <Typography className={classes.valueText}>{title}</Typography>
      )}
      <TooltipTypography
        className={`${classes.valueText} ${overallHighlightClass} ${classes.valueMargin}`}
        tooltip={overallValue}
      >
        {overallValue}
      </TooltipTypography>
      {overline === true && (
        <TooltipTypography
          className={`${textHighlight} ${classes.valueMargin}`}
          tooltip={actualValue}
        >
          {actualValue}
        </TooltipTypography>
      )}
    </div>
  );
}

OverlineValue.propTypes = {
  title: PropTypes.string,
  overallValue: PropTypes.node.isRequired,
  actualValue: PropTypes.node.isRequired,
  overline: PropTypes.bool.isRequired,
  highlight: PropTypes.bool,
};

OverlineValue.defaultProps = {
  title: null,
  highlight: false,
};

export default OverlineValue;
