// eslint-disable-next-line no-use-before-define
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { GroupIcon, SettingsIcon } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: "100%",
  },
  title: {
    ...theme.typography.h4,
    marginLeft: "1rem",
  },
  grow: {
    flexGrow: 1,
  },
  button: {
    textDecoration: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    backgroundColor: theme.palette.background.default,
    padding: "0.5rem",
    boxShadow:
      "0 1px 1px 0 rgba(0,0,0,0.14), 0 2px 1px -1px rgba(0,0,0,0.12), 0 1px 3px 0 rgba(0,0,0,0.20)",
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    float: "right",
    marginRight: theme.spacing(2),
    textDecoration: "none",
  },
  buttonText: {
    marginLeft: "0.25rem",
  },
}));

function Settings() {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Link className={classes.button} to="compensationRules">
      <SettingsIcon />
      <Typography
        className={classes.buttonText}
        variant="body1"
        color="secondary"
      >
        {t("setCompensationRules")}
      </Typography>
    </Link>
  );
}

function Header({ showButton }) {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GroupIcon />
      <Typography className={classes.title}>
        {t("compensationManager")}
      </Typography>
      <div className={classes.grow} />
      {showButton === true && <Settings />}
    </div>
  );
}

Header.propTypes = {
  showButton: PropTypes.bool,
};

Header.defaultProps = {
  showButton: false,
};

export default Header;
