// eslint-disable-next-line no-use-before-define
import React from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Typography from "@material-ui/core/Typography";

function CompensationHeading({ titleKey, componentStyle, headingTitle }) {
  const { t } = useTranslation();

  return (
    <div className={componentStyle}>
      <Typography className={headingTitle}>{t(titleKey)}</Typography>
    </div>
  );
}

CompensationHeading.propTypes = {
  titleKey: PropTypes.string.isRequired,
  componentStyle: PropTypes.string.isRequired,
  headingTitle: PropTypes.string.isRequired,
};
export default CompensationHeading;
