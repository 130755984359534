// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  FormControlLabel,
  Radio,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";
import PopupLayout from "../popupLayout/popupLayout";
// eslint-disable-next-line import/no-cycle
import { Cell } from "../tables/common";
import { RejectionReason, EURO_CURRENCY } from "../constants/constants";

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(0),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headerRow: {
    paddingTop: "0.4rem",
    paddingBottom: "0.8rem",
    borderBottom: "solid 1px #e7e7e7",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  productRow: {
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
  },
  headerText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  name: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    textTransform: "none",
    marginBottom: theme.spacing(1),
  },
  valueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  address: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  divider: {
    height: "2px",
  },
  secondaryTitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(1),
  },
  rejectReason: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  additionalInfo: {
    marginBottom: theme.spacing(4),
  },
  radioButtonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  radioButton: {
    color: theme.palette.secondary.main,
  },
  radioButtonLabel: {
    ...theme.typography.button,
    textTransform: "none",
  },
  additionalInfoField: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  input: {
    height: "5px",
  },
}));

function Header() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.headerRow}>
      <Cell width="50%">
        <Typography className={classes.headerText}>
          {t("productNumber")}
        </Typography>
      </Cell>
      <Cell width="30%">
        <Typography className={classes.headerText}>{t("amount")}</Typography>
      </Cell>
      <Cell width="20%">
        <Typography className={classes.headerText}>
          {t("compensation")}
        </Typography>
      </Cell>
    </div>
  );
}

function Amount({ product }) {
  const classes = useStyles();

  return (
    <Typography className={classes.name} color="textPrimary">
      {product.productQuantity}
    </Typography>
  );
}

Amount.propTypes = {
  product: PropTypes.shape({
    productQuantity: PropTypes.number,
  }),
};

Amount.defaultProps = {
  product: null,
};

function Product({ product }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography className={classes.name}>{product.productName}</Typography>
      <Typography className={classes.address}>{`${t("vnr").toUpperCase()} ${
        product.productVNR
      }`}</Typography>
    </div>
  );
}

Product.propTypes = {
  product: PropTypes.shape({
    productName: PropTypes.string,
    productVNR: PropTypes.string,
  }),
};

Product.defaultProps = {
  product: null,
};

function Row({ product }) {
  const classes = useStyles();

  const compensationValue = product.updatedStockValue.replace(",", ".");
  return (
    <div className={classes.productRow}>
      <Cell width="50%">
        <Product product={product} />
      </Cell>
      <Cell width="30%">
        <Amount product={product} />
      </Cell>
      <Cell width="20%">
        <Typography className={classes.name}>
          {`${new Decimal(compensationValue).abs().toFixed(2)}${EURO_CURRENCY}`}
        </Typography>
      </Cell>
    </div>
  );
}

Row.propTypes = {
  product: PropTypes.shape({
    updatedStockValue: PropTypes.string,
  }),
};

Row.defaultProps = {
  product: null,
};

function AdditionalInfo({ product }) {
  const classes = useStyles();

  const info = product.additionalInfo || "";
  return (
    <div className={classes.additionalInfo}>
      <Typography className={classes.additionalInfoField}>{info}</Typography>
    </div>
  );
}

AdditionalInfo.propTypes = {
  product: PropTypes.shape({
    additionalInfo: PropTypes.string,
  }),
};

AdditionalInfo.defaultProps = {
  product: null,
};

function RejectReason({ product }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const reason =
    product.statusReason != null
      ? product.statusReason
      : RejectionReason.CompensationPolicy;

  return (
    <div className={classes.rejectReason}>
      <Typography className={classes.secondaryTitle}>
        {t("rejectionReason")}
      </Typography>
      <div className={classes.radioButtonGroup}>
        {reason === RejectionReason.CompensationPolicy && (
          <FormControlLabel
            value="end"
            control={<Radio className={classes.radioButton} checked />}
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("compensationPolicy")}
              </Typography>
            }
            labelPlacement="end"
          />
        )}
        {reason === RejectionReason.MistakeInRequest && (
          <FormControlLabel
            value="end"
            control={<Radio className={classes.radioButton} checked />}
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("mistakeInRequest")}
              </Typography>
            }
            labelPlacement="end"
          />
        )}
        {reason === RejectionReason.Other && (
          <FormControlLabel
            value="end"
            control={<Radio className={classes.radioButton} checked />}
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("other")}
              </Typography>
            }
            labelPlacement="end"
          />
        )}
      </div>
    </div>
  );
}

RejectReason.propTypes = {
  product: PropTypes.shape({
    statusReason: PropTypes.string,
  }),
};

RejectReason.defaultProps = {
  product: null,
};

function RejectPopupProductView({
  product,
  show,
  top,
  left,
  bottom,
  right,
  popupRef,
  onCancel,
}) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <PopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      hideButtons
      popupRef={popupRef}
      onCancel={onCancel}
    >
      <Typography className={classes.title} variant="h5" color="textPrimary">
        {t("rejectCompensationRequest")}
      </Typography>
      <div className={classes.tableContainer}>
        <Header />
        <Row product={product} />
      </div>
      <Divider className={classes.divider} />
      <RejectReason product={product} />
      <AdditionalInfo product={product} />
    </PopupLayout>
  );
}

RejectPopupProductView.propTypes = {
  product: PropTypes.shape({
    updatedStockValue: PropTypes.string,
    statusReason: PropTypes.string,
    productName: PropTypes.string,
    productVNR: PropTypes.string,
    productQuantity: PropTypes.number,
    additionalInfo: PropTypes.string,
  }),
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  popupRef: PropTypes.func,
  onCancel: PropTypes.func,
};

RejectPopupProductView.defaultProps = {
  product: null,
  top: null,
  left: null,
  bottom: null,
  right: null,
  show: false,
  popupRef: () => {},
  onCancel: () => {},
};

export default RejectPopupProductView;
