// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { Typography, Checkbox, Divider, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import {
  getPharmaCompanyPeriodApprovalStatusColor,
  getTotalCompensationsGiven,
  isPeriodApprovedByPharmaCompany,
  getPharmacyApprovedCompensations,
} from "../../utils/compensation/compensation";
// eslint-disable-next-line import/no-cycle
import CompensationSteps, { StepType } from "./compensationSteps";

import CompensationStepDetails from "./compensationStepDetails";
import {
  CHECKBOX_APPROVAL,
  EURO_CURRENCY,
} from "../common/constants/constants";
import { ORIOLA_ADMIN_ROLE, ORIOLA_VIEW_ROLE } from "../auth/constants";

const useStyles = makeStyles(theme => ({
  root: {},
  periodDetailsContainer: {
    display: "flex",
    flexDirection: "row",
  },
  periodDetailsHeader: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(1),
    marginLeft: "3rem",
    marginRight: "3rem",
  },
  periodDetailsComponents: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    marginLeft: "3rem",
    marginRight: "3rem",
  },

  periodDetailTitle: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  periodDetailCheckbox: {
    // marginTop: theme.spacing(1),
  },
  periodicButton: {
    // boxShadow: '0 1px 1px 0 rgba(0, 0, 0, 0.2)',
    backgroundColor: "#f5f4f3",
    paddingLeft: 0,
    paddingTop: 0,
    paddingBottom: 0,
    // marginTop: theme.spacing(2),
  },
  compensationTotalValue: {
    ...theme.typography.subtitle2,
    color: "#a29791",
    // marginTop: theme.spacing(2),
  },
  contentDividerFull: {
    marginTop: theme.spacing(2),
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
  },
  contentDividerCenter: {
    marginTop: theme.spacing(2),
  },
  contentDividerLeft: {
    marginTop: theme.spacing(2),
    marginLeft: "0.5rem",
  },
  contentDividerRight: {
    marginTop: theme.spacing(2),
    marginRight: "0.5rem",
  },
  cellCentered: {
    display: "flex",
    justifyContent: "center",
  },
  verticalDivider: {
    alignSelf: "stretch",
    height: "auto",
  },
}));

export const Status = Object.freeze({
  NotStarted: "not_started",
  Start: "start",
  Started: "started",
  Finished: "finished",
});

function Cell({ children, width, center }) {
  const classes = useStyles();

  const className = center === true ? classes.cellCentered : null;
  return (
    <div className={className} style={{ width }}>
      {children}
    </div>
  );
}

Cell.propTypes = {
  children: PropTypes.node,
  width: PropTypes.string,
  center: PropTypes.bool,
};

Cell.defaultProps = {
  children: null,
  width: "",
  center: false,
};

function PeriodCheckbox({ checked, color, disabled, onClick }) {
  const classes = useStyles();

  return (
    <Checkbox
      className={classes.periodDetailCheckbox}
      onClick={onClick}
      checked={checked}
      style={{
        color,
      }}
      disabled={disabled === true}
    />
  );
}

PeriodCheckbox.propTypes = {
  checked: PropTypes.bool,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

PeriodCheckbox.defaultProps = {
  checked: false,
  color: null,
  disabled: false,
  onClick: () => {},
};

function HeaderText({ text }) {
  const classes = useStyles();

  return (
    <Typography className={classes.periodDetailTitle} noWrap>
      {text}
    </Typography>
  );
}

HeaderText.propTypes = {
  text: PropTypes.string.isRequired,
};

export const getTextByStatus = (status, t) => {
  switch (status) {
    case Status.NotStarted:
      return t("notStarted");
    case Status.Start:
      return t("start");
    case Status.Started:
      return t("started");
    case Status.Finished:
      return t("finished");
    default:
      return "-";
  }
};
function OriolaPeriodDetails({
  period,
  compensations,
  stepType,
  compensationStatus,
  serviceChargesStatus,
  refundsStatus,
  onStepClicked,
  onConfirmClicked,
  onReportCreationFailed,
  approver,
  expanded,
  inServiceProfitCenters,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { userData } = useSelector(state => state.user);
  const { roles } = userData;
  const isOriolaViewRole =
    roles.includes(ORIOLA_VIEW_ROLE) && !roles.includes(ORIOLA_ADMIN_ROLE);

  const getTitleByType = type => {
    switch (type) {
      case StepType.Compensations:
        return t("compensations");
      case StepType.ServiceCharges:
        return t("serviceCharges");
      case StepType.Refunds:
        return t("refunds");
      default:
        return "";
    }
  };

  const getConfirmedByType = type => {
    switch (type) {
      case StepType.Compensations:
        return compensationStatus === Status.Finished;
      case StepType.ServiceCharges:
        return serviceChargesStatus === Status.Finished;
      case StepType.Refunds:
        return refundsStatus === Status.Finished;
      default:
        return false;
    }
  };

  const getCheckboxColorByStatus = status => {
    switch (status) {
      case Status.Start:
      case Status.NotStarted:
        return "white";
      case Status.Started:
        return "#ffa000";
      case Status.Finished:
        return "green";
      default:
        return "black";
    }
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function PeriodicButton({ status, disabled, onClick }) {
    const text = getTextByStatus(status, t);
    const checkboxColor = getCheckboxColorByStatus(status);
    const textColor = status !== Status.Start ? "#2b3e5b" : "#757070";

    return (
      <Button
        className={classes.periodicButton}
        variant="contained"
        onClick={onClick}
        disabled={disabled}
        style={{ color: textColor }}
      >
        <Checkbox style={{ color: checkboxColor }} checked />
        {text}
      </Button>
    );
  }

  PeriodicButton.propTypes = {
    status: PropTypes.string.isRequired,
    disabled: PropTypes.bool,
    onClick: PropTypes.func,
  };

  PeriodicButton.defaultProps = {
    disabled: false,
    onClick: () => {},
  };

  const periodApprovedByPharmaCompany =
    isPeriodApprovedByPharmaCompany(compensations);
  const pharmacyApprovedCompensations =
    getPharmacyApprovedCompensations(compensations);
  const totalCompensationGiven = getTotalCompensationsGiven(
    pharmacyApprovedCompensations
  );

  return (
    <div className={classes.root}>
      {compensations.length !== 0 && (
        <div className={classes.periodDetailsContainer}>
          <Cell width="25%">
            <div className={classes.periodDetailsHeader}>
              <Cell width="40%" center>
                <Typography className={classes.periodDetailTitle} noWrap>
                  {t("requests")}
                </Typography>
              </Cell>
              <Cell width="60%" center>
                <Typography className={classes.periodDetailTitle} noWrap>
                  {t("approvals")}
                </Typography>
              </Cell>
            </div>
            <Divider className={classes.contentDividerLeft} />
            <div className={classes.periodDetailsComponents}>
              <Cell width="40%" center>
                <PeriodCheckbox checked color={CHECKBOX_APPROVAL} disabled />
              </Cell>
              <Cell width="60%" center>
                <PeriodCheckbox
                  checked={periodApprovedByPharmaCompany}
                  color={getPharmaCompanyPeriodApprovalStatusColor(
                    compensations
                  )}
                  disabled
                />
              </Cell>
            </div>
          </Cell>
          <Divider orientation="vertical" className={classes.verticalDivider} />
          <Cell width="45%">
            <div className={classes.periodDetailsHeader}>
              <Cell width="33%">
                <HeaderText text={t("compensations")} />
              </Cell>
              <Cell width="33%">
                <HeaderText text={t("serviceCharges")} />
              </Cell>
              <Cell width="33%">
                <HeaderText text={t("refunds")} />
              </Cell>
            </div>
            <Divider className={classes.contentDividerCenter} />
            <div className={classes.periodDetailsComponents}>
              <Cell width="33%">
                <PeriodicButton
                  status={compensationStatus}
                  disabled={
                    periodApprovedByPharmaCompany === false || isOriolaViewRole
                  }
                  onClick={() => {
                    onStepClicked(StepType.Compensations);
                  }}
                />
              </Cell>
              <Cell width="33%">
                <PeriodicButton
                  status={serviceChargesStatus}
                  disabled={
                    compensationStatus !== Status.Finished ||
                    periodApprovedByPharmaCompany === false ||
                    isOriolaViewRole
                  }
                  onClick={() => {
                    onStepClicked(StepType.ServiceCharges);
                  }}
                />
              </Cell>
              <Cell width="33%">
                <PeriodicButton
                  status={refundsStatus}
                  disabled={
                    compensationStatus !== Status.Finished ||
                    serviceChargesStatus !== Status.Finished ||
                    periodApprovedByPharmaCompany === false ||
                    isOriolaViewRole
                  }
                  onClick={() => {
                    onStepClicked(StepType.Refunds);
                  }}
                />
              </Cell>
            </div>
          </Cell>
          <Cell width="30%">
            <div className={classes.periodDetailsHeader}>
              <Cell>
                <HeaderText text={t("totalCompensationValue")} />
              </Cell>
            </div>
            <Divider className={classes.contentDividerRight} />
            <div className={classes.periodDetailsComponents}>
              <Cell>
                <Typography
                  className={classes.compensationTotalValue}
                  variant="subtitle2"
                >
                  {`${totalCompensationGiven} ${EURO_CURRENCY}`}
                </Typography>
              </Cell>
            </div>
          </Cell>
        </div>
      )}

      {expanded === true && (
        <div>
          <Divider className={classes.contentDividerFull} />
          {compensations.length !== 0 && (
            <CompensationSteps
              type={stepType}
              period={period}
              confirmed={getConfirmedByType(stepType)}
              approved={periodApprovedByPharmaCompany}
              onConfirmClicked={() => onConfirmClicked(stepType)}
              onReportCreationFailed={onReportCreationFailed}
              approver={approver}
            />
          )}
          <CompensationStepDetails
            title={getTitleByType(stepType)}
            type={stepType}
            compensations={compensations}
            inServiceProfitCenters={inServiceProfitCenters}
          />
        </div>
      )}
    </div>
  );
}

OriolaPeriodDetails.propTypes = {
  period: PropTypes.shape({}).isRequired,
  compensations: PropTypes.arrayOf(PropTypes.shape({})),
  stepType: PropTypes.string,
  compensationStatus: PropTypes.string,
  serviceChargesStatus: PropTypes.string,
  refundsStatus: PropTypes.string,
  onStepClicked: PropTypes.func,
  onConfirmClicked: PropTypes.func,
  onReportCreationFailed: PropTypes.func,
  approver: PropTypes.shape({}),
  expanded: PropTypes.bool,
  inServiceProfitCenters: PropTypes.arrayOf(PropTypes.shape({})),
};

OriolaPeriodDetails.defaultProps = {
  compensations: [],
  stepType: StepType?.Compensations,
  compensationStatus: Status.NotStarted,
  serviceChargesStatus: Status.NotStarted,
  refundsStatus: Status.NotStarted,
  onStepClicked: () => {},
  onConfirmClicked: () => {},
  onReportCreationFailed: () => {},
  approver: {},
  expanded: false,
  inServiceProfitCenters: [],
};

export default OriolaPeriodDetails;
