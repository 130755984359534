// eslint-disable-next-line no-use-before-define
import React, { createContext, useCallback, useState } from "react";
import PropTypes from "prop-types";

const INITIAL_VALUE = {
  getTableDetailsOfChildTables: () => [],
  setTableDetailsForLevel: () => {
    /* Noop */
  },
  resetTableDetailsForLevel: () => {
    /* Noop */
  },
};

const INITIAL_TABLE_ROW_DETAILS = {
  rowCount: 0,
  defaultRowHeight: 70,
  utilitiesHeight: 0,
};

export const TableDetailsContext = createContext(INITIAL_VALUE);

function TableDetailsProvider({ children }) {
  const [tableDetailsByLevelMap, setTableDetailsByLevelMap] = useState({});

  const setTableDetailsForLevel = useCallback(
    (level, details) => {
      setTableDetailsByLevelMap(state => ({
        ...state,
        [level]: details,
      }));
    },
    [setTableDetailsByLevelMap]
  );

  const resetTableDetailsForLevel = useCallback(
    level => {
      setTableDetailsForLevel(level, { ...INITIAL_TABLE_ROW_DETAILS });
    },
    [setTableDetailsForLevel]
  );

  const getTableDetailsOfChildTables = useCallback(
    parentLevel =>
      Object.keys(tableDetailsByLevelMap)
        .map(key => {
          const level = Number(key);
          return level > parentLevel ? tableDetailsByLevelMap[level] : null;
        })
        .filter(item => !!item),
    [tableDetailsByLevelMap]
  );

  // eslint-disable-next-line react/jsx-no-constructed-context-values
  const contextValue = {
    setTableDetailsForLevel,
    resetTableDetailsForLevel,
    getTableDetailsOfChildTables,
  };

  return (
    // eslint-disable-next-line react/no-children-prop
    <TableDetailsContext.Provider value={contextValue} children={children} />
  );
}

TableDetailsProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export default TableDetailsProvider;
