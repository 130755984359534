import { applyMiddleware, compose, createStore } from "redux";
import thunkMiddleware from "redux-thunk";
import { sentry, AnalyticsUtil } from "@oriola-origo/origo-common-client-lib";
import loggerMiddleware from "../logger";
import rootReducer from "../reducers";
import analyticsMiddleware from "../analytics";

const sentryReduxEnhancer = sentry.createReduxEnhancer({});

const configureStore = preloadedState => {
  const middlewares = [loggerMiddleware, thunkMiddleware];

  if (AnalyticsUtil.isAnalyticsEnabled() === true) {
    middlewares.push(analyticsMiddleware);
  }

  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer, sentryReduxEnhancer];

  const composeEnhancers =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const composedEnhancers = composeEnhancers(...enhancers);

  const store = createStore(rootReducer, preloadedState, composedEnhancers);

  return store;
};
export default configureStore;
