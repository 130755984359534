// eslint-disable-next-line no-use-before-define
import React, { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import {
  EditImage,
  SaveImage,
  TrashcanImage,
  AddExceptionImage,
} from "../../../images";

const useStyles = makeStyles(theme => ({
  centeredRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  editText: {
    ...theme.typography.button,
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
  cancelButton: {
    backgroundColor: "rgba(0, 0, 0, 0.2)",
  },
  cancelText: {
    ...theme.typography.button,
    textTransform: "none",
  },
}));

export function EditButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button className={classes.centeredRow} onClick={onClick}>
      <EditImage style={{ width: 15, height: 15 }} />
      <Typography className={classes.editText}>{t("edit")}</Typography>
    </Button>
  );
}

EditButton.propTypes = {
  onClick: PropTypes.func,
};

EditButton.defaultProps = {
  onClick: () => {},
};

export function UpdateButton({ onClick, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabled}
      variant="contained"
      className={classes.centeredRow}
      onClick={onClick}
      color="secondary"
    >
      <SaveImage style={{ width: 15, height: 15 }} />
      <Typography className={classes.editText}>{t("update")}</Typography>
    </Button>
  );
}

UpdateButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};

UpdateButton.defaultProps = {
  disabled: false,
  onClick: () => {},
};

export function CancelButton({ onClick, disabled }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button
      disabled={disabled}
      className={classes.cancelButton}
      variant="contained"
      onClick={onClick}
    >
      <Typography className={classes.cancelText}>{t("cancel")}</Typography>
    </Button>
  );
}

CancelButton.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

CancelButton.defaultProps = {
  onClick: () => {},
  disabled: false,
};

export function RemoveButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button className={classes.centeredRow} onClick={onClick} color="secondary">
      <TrashcanImage style={{ width: 15, height: 15 }} />
      <Typography className={classes.editText}>{t("remove")}</Typography>
    </Button>
  );
}

RemoveButton.propTypes = {
  onClick: PropTypes.func,
};

RemoveButton.defaultProps = {
  onClick: () => {},
};

export function AddExceptionButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button className={classes.centeredRow} onClick={onClick}>
      <AddExceptionImage />
      <Typography className={classes.editText}>{t("addException")}</Typography>
    </Button>
  );
}

AddExceptionButton.propTypes = {
  onClick: PropTypes.func,
};

AddExceptionButton.defaultProps = {
  onClick: () => {},
};
