// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import Paper from "@material-ui/core/Paper";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Checkbox,
  Divider,
  FormControlLabel,
} from "@material-ui/core";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  getColorByStatus,
  getTextKeyByStatus,
  isPeriodApprovedByPharmaCompany,
} from "../../utils/compensation/compensation";
import { NotificationPopup, Progress } from "../common";
import {
  createServiceCharges,
  updateAllPeriodCompensationProperties,
  fetchCompensationsForOriola,
  updateProcessSteps,
  fetchServiceCharges,
} from "../../redux";
import { ExpansionImage, DashImage } from "../../images";
import { StepType } from "./compensationSteps";
import OriolaPeriodDetails, { Status } from "./oriolaPeriodDetails";
import {
  getProcessApprover,
  getStatus,
  updateStatus,
} from "../../utils/oriola/oriola";
import {
  DIALOG_APPEAR,
  CompensationStatuses,
} from "../common/constants/constants";

const useStyles = makeStyles(theme => ({
  periodContainer: {
    textTransform: "none",
  },
  row: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    borderTop: "solid 2px transparent",
  },
  rowExpanded: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    borderTop: "solid 2px #ffa000",
  },
  periodOverviewContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  periodOverview: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expandIcon: {
    marginLeft: "0.5rem",
    marginRight: "1rem",
  },
  submissionText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  headerDivider: {
    marginLeft: "0.2em",
    marginRight: "0.2rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  error: {
    color: "red",
    ...theme.typography.body1,
  },
}));

function SubmissionText({ period, error, isFetching }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const { periodStartDate, periodEndDate } = period;
  const text = `${t("submissionPeriod")} ${moment(periodStartDate).format(
    "DD."
  )}-${moment(periodEndDate).format("DD.MM.YYYY")}`;
  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.submissionText}>{text}</Typography>
      <Progress show={isFetching} size={25} />
      {error != null && (
        <Typography className={classes.error}>{t("networkError")}</Typography>
      )}
    </div>
  );
}

SubmissionText.propTypes = {
  period: PropTypes.shape({
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
  }),
  error: PropTypes.node,
  isFetching: PropTypes.bool,
};

SubmissionText.defaultProps = {
  period: {},
  error: null,
  isFetching: false,
};

function OriolaPeriodRow({
  period,
  compensations,
  fetchError,
  fetching,
  preload,
  inServiceProfitCenters,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.userData);
  const [stepType, setStepType] = useState(StepType.Compensations);
  const [dialog, setDialog] = useState({
    show: false,
    title: "",
    text: "",
  });
  const [showFileLoadError, setShowFileLoadError] = useState(false);
  const { processStatuses } = useSelector(state => state.oriola);
  const { sending } = useSelector(state => state.compensation);
  const doGetStatus = (startDate, endDate, type) =>
    getStatus(startDate, endDate, type, processStatuses);

  useEffect(() => {
    if (compensations == null && preload === true) {
      const { periodStartDate, periodEndDate } = period;
      dispatch(fetchCompensationsForOriola(periodStartDate, periodEndDate));
    }
  }, [compensations, preload, period, dispatch]);

  useEffect(() => {
    // fetch only when at the Charges tab
    if (
      (stepType === StepType.ServiceCharges || stepType === StepType.Refunds) &&
      compensations != null &&
      compensations.length > 0
    ) {
      // if finished already
      const { periodStartDate, periodEndDate } = period;

      const finished =
        doGetStatus(periodStartDate, periodEndDate, stepType) ===
        Status.Finished;
      if (finished === true) {
        dispatch(fetchServiceCharges(periodStartDate, periodEndDate));
      } else {
        dispatch(createServiceCharges(periodStartDate, periodEndDate));
      }
    }
    // eslint-disable-next-line
  }, [stepType, period, dispatch, compensations]);

  useEffect(() => {
    if (compensations != null && compensations.length === 0) {
      setExpanded(false);
    }
  }, [compensations, setExpanded]);

  // get approver name
  const getStepsApprover = (startDate, type) =>
    getProcessApprover(startDate, type, processStatuses);

  const updateProcessStatus = (startDate, endDate, type, status) => {
    const updatedStatuses = updateStatus(
      startDate,
      endDate,
      type,
      status,
      processStatuses,
      userData
    );
    dispatch(updateProcessSteps(updatedStatuses));
  };

  const updatePeriodToCompleted = providedPeriod => {
    const { periodStartDate, periodEndDate } = providedPeriod;

    const properties = {
      oriolaStatus: CompensationStatuses.Completed,
    };

    // update and refresh list
    return dispatch(
      updateAllPeriodCompensationProperties(
        periodStartDate,
        periodEndDate,
        properties
      )
    );
  };

  const showDialogAndUpdate = (title, text, providedPeriod, update) => {
    setDialog({
      show: true,
      title,
      text,
    });
    setTimeout(() => {
      // hide
      setDialog({ show: false });

      // refresh list
      if (update === true) {
        const { periodStartDate, periodEndDate } = providedPeriod;
        dispatch(fetchCompensationsForOriola(periodStartDate, periodEndDate));
      }
    }, DIALOG_APPEAR);
  };

  // eslint-disable-next-line consistent-return
  const isAllStepsCompleted = () => {
    const { periodStartDate } = period;
    for (let i = 0; i < processStatuses.length; i += 1) {
      const periodStatus = processStatuses[i];
      if (periodStatus.periodStartDate === periodStartDate) {
        return (
          periodStatus.compensationStatus === Status.Finished &&
          periodStatus.refundsStatus === Status.Finished &&
          periodStatus.serviceChargesStatus === Status.Finished
        );
      }
    }
  };

  const checkAndSetCompleted = type => {
    // final step and all done?
    const stepsCompleted = isAllStepsCompleted();
    if (type === StepType.Refunds && stepsCompleted === true) {
      updatePeriodToCompleted(period).then(response => {
        if (response != null) {
          showDialogAndUpdate(
            t("compensationRefundsConfirmed"),
            t("submissionPeriodComplete"),
            period,
            true
          );
        } else {
          showDialogAndUpdate(
            t("dataSendingFailed"),
            t("networkError"),
            period,
            false
          );
        }
      });
    }
  };

  const showFileLoadingErrorPopup = () => {
    setShowFileLoadError(true);
    // hide after timeout
    setTimeout(async () => {
      setShowFileLoadError(false);
    }, DIALOG_APPEAR);
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  function Popup() {
    return (
      <NotificationPopup
        show={dialog.show}
        title={dialog.title}
        primaryText={dialog.text}
      />
    );
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  function StepsDialog() {
    return <Popup />;
  }
  // eslint-disable-next-line
  function ExpandIcon({ period }) {
    const doSetExpanded = value => {
      // load compensations if not loaded yet
      if (compensations == null && value === true) {
        // initial fetching
        const { periodStartDate, periodEndDate } = period;
        dispatch(fetchCompensationsForOriola(periodStartDate, periodEndDate));
      }
      setExpanded(value);
    };

    return (
      <div className={classes.expandIcon}>
        {expanded === true && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(false)}
            disabled={fetching}
          >
            <DashImage />
          </IconButton>
        )}
        {expanded === false && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(true)}
            disabled={fetching}
          >
            <ExpansionImage />
          </IconButton>
        )}
      </div>
    );
  }

  ExpandIcon.propTypes = {
    period: PropTypes.shape({
      periodStartDate: PropTypes.string,
      periodEndDate: PropTypes.string,
    }).isRequired,
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  function OverallStatus() {
    // no compensations
    if (compensations == null || compensations.length === 0) {
      const text = compensations == null ? "-" : t("noCompensations");
      return <Typography>{text}</Typography>;
    }

    return (
      <div className={classes.overallStatus}>
        {sending === false && (
          <FormControlLabel
            control={
              <Checkbox
                checked
                value
                style={{
                  color: getColorByStatus(compensations),
                }}
              />
            }
            label={t(getTextKeyByStatus(compensations))}
            className={classes.statusCheckbox}
          />
        )}
        {sending === true && <Progress size={25} show />}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function PeriodHeader() {
    return (
      <div className={classes.periodOverviewContainer}>
        <div className={classes.periodOverview}>
          <ExpandIcon period={period} />
          <SubmissionText
            period={period}
            error={fetchError}
            isFetching={fetching}
          />
        </div>
        <OverallStatus period={period} />
      </div>
    );
  }
  // eslint-disable-next-line
  function PeriodDetails({ period }) {
    if (!compensations || !compensations.length) {
      return (
        <div>
          <OriolaPeriodDetails
            period={period}
            compensations={[]}
            stepType={stepType}
            expanded={expanded}
            inServiceProfitCenters={inServiceProfitCenters}
          />
        </div>
      );
    }

    const onStepClicked = type => {
      setExpanded(true);
      setStepType(type);

      const { periodStartDate, periodEndDate } = period;
      const currentStatus = doGetStatus(periodStartDate, periodEndDate, type);
      const periodApproved = isPeriodApprovedByPharmaCompany(compensations);
      if (periodApproved === true && currentStatus !== Status.Finished) {
        updateProcessStatus(
          periodStartDate,
          periodEndDate,
          type,
          Status.Started
        );
      }
    };

    const onConfirmClicked = type => {
      const { periodStartDate, periodEndDate } = period;
      updateProcessStatus(
        periodStartDate,
        periodEndDate,
        type,
        Status.Finished
      );

      // check whether this is completed
      checkAndSetCompleted(type);
    };

    const { periodStartDate, periodEndDate } = period;
    const approver = getStepsApprover(periodStartDate, stepType);
    return (
      <div>
        <Divider className={classes.headerDivider} />
        <OriolaPeriodDetails
          period={period}
          compensations={compensations}
          stepType={stepType}
          compensationStatus={doGetStatus(
            periodStartDate,
            periodEndDate,
            StepType.Compensations
          )}
          serviceChargesStatus={doGetStatus(
            periodStartDate,
            periodEndDate,
            StepType.ServiceCharges
          )}
          refundsStatus={doGetStatus(
            periodStartDate,
            periodEndDate,
            StepType.Refunds
          )}
          onStepClicked={type => onStepClicked(type)}
          onConfirmClicked={type => onConfirmClicked(type)}
          onReportCreationFailed={() => showFileLoadingErrorPopup()}
          approver={approver}
          expanded={expanded}
          inServiceProfitCenters={inServiceProfitCenters}
        />
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function FileLoadingErrorPopup() {
    return (
      <NotificationPopup
        show={showFileLoadError}
        title={t("reportLoadingErrorInfo")}
      />
    );
  }

  const rowClass = expanded ? classes.rowExpanded : classes.row;
  return (
    <div className={classes.periodContainer}>
      <Paper className={rowClass}>
        <StepsDialog />
        <FileLoadingErrorPopup />
        <PeriodHeader period={period} />

        <PeriodDetails period={period} />
      </Paper>
    </div>
  );
}

OriolaPeriodRow.propTypes = {
  period: PropTypes.shape({
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
  }).isRequired,
  compensations: PropTypes.arrayOf(PropTypes.shape({})),
  fetching: PropTypes.bool,
  fetchError: PropTypes.node,
  preload: PropTypes.bool,
  inServiceProfitCenters: PropTypes.arrayOf(PropTypes.shape({})),
};

OriolaPeriodRow.defaultProps = {
  compensations: null,
  fetching: false,
  fetchError: null,
  preload: false,
  inServiceProfitCenters: null,
};

export default OriolaPeriodRow;
