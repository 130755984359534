import { combineReducers } from "redux";
import { userReducer } from "./user/user";
import { compensationReducer } from "./compensation/compensation";
import { oriolaReducer } from "./oriola/oriola";
import { rulesReducer } from "./rules/rules";
import { pharmacyReducer } from "./pharmacy/pharmacy";
import { profitCentersReducer } from "./profitCenters";

export default combineReducers({
  user: userReducer,
  compensation: compensationReducer,
  oriola: oriolaReducer,
  rules: rulesReducer,
  pharmacy: pharmacyReducer,
  profitCenters: profitCentersReducer,
});
