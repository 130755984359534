/* eslint-disable */
import { Route } from "react-router-dom";
import PropTypes from "prop-types";

import Can from "./can";

function ProtectedRoute({
  path,
  component,
  failComponent,
  user,
  requiredPermission,
}) {
  return (
    <Can
      user={user}
      perform={requiredPermission}
      ok={() => <Route path={path} component={component} />}
      nok={() => <Route path={path} component={failComponent} />}
    />
  );
}

ProtectedRoute.propTypes = {
  path: PropTypes.string.isRequired,
  component: PropTypes.elementType.isRequired,
  failComponent: PropTypes.elementType.isRequired,
  user: PropTypes.shape({}).isRequired,
  requiredPermission: PropTypes.string.isRequired,
};

ProtectedRoute.defaultProps = {};

export default ProtectedRoute;
