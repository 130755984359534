// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Link } from "react-router-dom";
import { GroupIcon, BackArrowImage } from "../../../images";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "flex-start",
    width: "100%",
  },
  title: {
    ...theme.typography.h4,
    marginLeft: "0.8rem",
  },
  navigationTitle: {
    ...theme.typography.subtitle2,
    marginLeft: "1rem",
  },
  button: {
    textDecoration: "none",
    color: theme.palette.secondary.main,
  },
  backIcon: {
    marginLeft: "1rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

function NaviButton({ title, route }) {
  const classes = useStyles();

  return (
    <Link className={classes.button} to={route}>
      <Typography className={classes.navigationTitle}>{title}</Typography>
    </Link>
  );
}

NaviButton.propTypes = {
  title: PropTypes.string.isRequired,
  route: PropTypes.string.isRequired,
};

function NaviHeader({ title, naviTitle, naviRoute }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <GroupIcon />
      <NaviButton title={naviTitle} route={naviRoute} />
      <div className={classes.backIcon}>
        <BackArrowImage style={{ width: 15, height: 15 }} />
      </div>
      <Typography className={classes.title}>{title}</Typography>
    </div>
  );
}

NaviHeader.propTypes = {
  title: PropTypes.string.isRequired,
  naviTitle: PropTypes.string.isRequired,
  naviRoute: PropTypes.string.isRequired,
};

NaviHeader.defaultProps = {};

export default NaviHeader;
