export const EXTENSION_CONTAINER_TOP_PADDING = 20;
export const EXTENSION_CONTAINER_BOTTOM_PADDING = 30;
export const HEADER_TOP_PADDING = 12;
export const HEADER_BOTTOM_PADDING = 8;
export const HEADER_BORDER_WIDTH = 1;
export const HEADER_HEIGHT = 40;
export const HEADER_FULL_HEIGHT =
  HEADER_HEIGHT +
  HEADER_TOP_PADDING +
  HEADER_BOTTOM_PADDING +
  HEADER_BORDER_WIDTH;

export const calculateNeededHeightForChildTable = (
  includeTopLevelTablePaddings,
  getTableDetailsOfChildTables,
  tableLevel
) => {
  const childTableDetails = getTableDetailsOfChildTables(tableLevel);

  let total = 0;
  childTableDetails.forEach((tableDetails, index) => {
    const { rowCount, defaultRowHeight, utilitiesHeight } = tableDetails;
    if (rowCount > 0) {
      total += rowCount * defaultRowHeight + utilitiesHeight;
    }

    const includePaddings = includeTopLevelTablePaddings || index > 0;
    if (includePaddings) {
      total +=
        EXTENSION_CONTAINER_BOTTOM_PADDING + EXTENSION_CONTAINER_TOP_PADDING;
    }
  });

  return total;
};

export const getChildTableHeight = (
  includeTopLevelTablePaddings,
  fn,
  tableLevel
) =>
  calculateNeededHeightForChildTable(
    includeTopLevelTablePaddings,
    fn,
    tableLevel
  );
