import moment from "moment";
// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import ensureTrailingSlash from "../../utils/url/url";
import {
  getRequiredPropertiesForUpdate,
  createPeriodKeyFromDates,
} from "../../utils/compensation/compensation";
import { getRefreshedToken } from "..";
import { CompensationStatuses } from "../../components/common/constants/constants";
import { decompressData } from "../../utils/dataCompression/dataCompression";

const REQUEST_ABORTED_MESSAGE = "Request aborted";

export const Types = Object.freeze({
  FETCH_STARTED: "COMPENSATION_FETCH_STARTED",
  FETCH_FINISHED: "COMPENSATION_FETCH_FINISHED",
  FETCH_ERROR: "COMPENSATION_FETCH_ERROR",
  UPDATE_COMPENSATIONS: "UPDATE_COMPENSATIONS",
  SEARCH_STARTED: "COMPENSATION_SEARCH_STARTED",
  SEARCH_FINISHED: "COMPENSATION_SEARCH_FINISHED",
  SEARCH_ERROR: "COMPENSATION_SEARCH_ERROR",
  UPDATE_FILTERED_COMPENSATIONS: "UPDATE_FILTERED_COMPENSATIONS",
  SEND_STARTED: "COMPENSATION_SEND_STARTED",
  SEND_FINISHED: "COMPENSATION_SEND_FINISHED",
  SEND_ERROR: "COMPENSATION_SEND_ERROR",
});

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_COMPENSATION_SERVICE);
const compensationsApi = "compensation/v1";
const compensationFilterApi = "compensation/v1/filter";
const compensationsPharmacyApi = "compensation/v1/pharmacy";
const compensationsPharmaCompanyApi = "compensation/v1/pharma-company";
const updateAllPeriodCompensationApi =
  "compensation/v1/update-all-period-compensations";
const updatePharmaCompanyPeriodCompensationApi =
  "compensation/v1/update-pharma-company-period-compensations";
const updatePharmaCompanyCompensations =
  "compensation/v1/update-pharma-company-compensations";

// -- ACTIONS

const responseToCompensations = response => {
  const { compressedData } = response;
  const compensations = JSON.parse(decompressData(compressedData));
  return compensations;
};

export const filterCompensations =
  (
    periodStartDate,
    periodEndDate,
    query,
    organizationIds = null,
    profitCenters = null
  ) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      let path = `${baseUrl}${compensationFilterApi}?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&query=${query}`;

      if (organizationIds != null) {
        path = `${path}&organizationIds=${organizationIds.join(",")}`;
      }

      if (profitCenters != null) {
        path = `${path}&profitCenters=${profitCenters.join(",")}`;
      }

      dispatch({ type: Types.SEARCH_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.get(tokens, path);
      const compensations = responseToCompensations(response);

      dispatch({ type: Types.SEARCH_FINISHED });

      // create map
      const filteredCompensationsMap = compensations.reduce((acc, next) => {
        const { periodStartDate: startDate, periodEndDate: endDate } = next;
        const key = createPeriodKeyFromDates(startDate, endDate);
        const existingCompensations = acc.get(key);
        if (existingCompensations == null) {
          acc.set(key, [next]);
        } else {
          acc.set(key, existingCompensations.concat(next));
        }
        return acc;
      }, new Map());

      dispatch({
        type: Types.UPDATE_FILTERED_COMPENSATIONS,
        payload: {
          filteredCompensationCount: compensations.length,
          filteredCompensationsMap,
        },
      });

      return compensations;
    } catch (error) {
      dispatch({ type: Types.SEARCH_ERROR, payload: error });
    }
  };

export const fetchCompensationsForPharmacy =
  (organizationIds, periodStartDate, periodEndDate) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const organizationIdsStr = organizationIds.join(",");
      const path = `${baseUrl}${compensationsPharmacyApi}?organizationIds=${organizationIdsStr}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;

      const periodKey = createPeriodKeyFromDates(
        periodStartDate,
        periodEndDate
      );
      dispatch({ type: Types.FETCH_STARTED, payload: periodKey });

      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.get(tokens, path);
      const compensations = responseToCompensations(response);

      dispatch({ type: Types.FETCH_FINISHED, payload: periodKey });

      // update map
      const compensationsMap = new Map(
        getState().compensation.compensationsMap
      );
      compensationsMap.set(periodKey, compensations);

      dispatch({
        type: Types.UPDATE_COMPENSATIONS,
        payload: compensationsMap,
      });

      return compensations;
    } catch (error) {
      if (error.errorMessage !== REQUEST_ABORTED_MESSAGE) {
        dispatch({ type: Types.FETCH_ERROR, payload: error });
      }
    }
  };

export const fetchCompensationsForPharmaCompany =
  (profitCenters, periodStartDate, periodEndDate) =>
  // eslint-disable-next-line consistent-return
  async (dispatch, getState) => {
    try {
      const profitCentersStr = profitCenters.join(",");
      const path = `${baseUrl}${compensationsPharmaCompanyApi}?profitCenters=${profitCentersStr}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;

      const periodKey = createPeriodKeyFromDates(
        periodStartDate,
        periodEndDate
      );
      dispatch({ type: Types.FETCH_STARTED, payload: periodKey });

      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.get(tokens, path);
      const compensations = responseToCompensations(response);

      dispatch({ type: Types.FETCH_FINISHED, payload: periodKey });

      // update map
      const compensationsMap = new Map(
        getState().compensation.compensationsMap
      );
      compensationsMap.set(periodKey, compensations);

      dispatch({
        type: Types.UPDATE_COMPENSATIONS,
        payload: compensationsMap,
      });

      return compensations;
    } catch (error) {
      if (error.errorMessage !== REQUEST_ABORTED_MESSAGE) {
        dispatch({ type: Types.FETCH_ERROR, payload: error });
      }
    }
  };

export const fetchCompensationsForOriola =
  // eslint-disable-next-line consistent-return
  (periodStartDate, periodEndDate) => async (dispatch, getState) => {
    try {
      let path = `${baseUrl}${compensationsApi}?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&pagination=true`;

      const periodKey = createPeriodKeyFromDates(
        periodStartDate,
        periodEndDate
      );
      dispatch({ type: Types.FETCH_STARTED, payload: periodKey });

      const tokens = await dispatch(getRefreshedToken());

      const compensationItems = [];
      let decompressedData;

      do {
        // eslint-disable-next-line no-await-in-loop
        const response = await RestService.get(tokens, path);
        decompressedData = responseToCompensations(response);

        compensationItems.push(...decompressedData.items);
        if (decompressedData.lastEvaluatedKey) {
          path = `${baseUrl}${compensationsApi}?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}&pagination=true&exclusiveStartKey=${encodeURIComponent(
            decompressedData.lastEvaluatedKey
          )}`;
        }
      } while (decompressedData.lastEvaluatedKey);

      dispatch({ type: Types.FETCH_FINISHED, payload: periodKey });

      // update compensations map
      const compensationsMap = new Map(
        getState().compensation.compensationsMap
      );
      compensationsMap.set(periodKey, compensationItems);

      dispatch({
        type: Types.UPDATE_COMPENSATIONS,
        payload: compensationsMap,
      });

      return compensationItems;
    } catch (error) {
      // TODO: error handling
      if (error.errorMessage !== REQUEST_ABORTED_MESSAGE) {
        dispatch({ type: Types.FETCH_ERROR, payload: error });
      }
    }
  };

export const updateAllPeriodCompensationProperties =
  // eslint-disable-next-line consistent-return
  (periodStartDate, periodEndDate, properties) => async dispatch => {
    try {
      dispatch({ type: Types.SEND_STARTED });

      // TODO: this is horrible way to modify these. Consider creating APIs for modifying certain properties
      const data = {
        periodStartDate,
        periodEndDate,
        ...properties,
      };

      const path = `${baseUrl}${updateAllPeriodCompensationApi}?periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;
      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.post(tokens, path, data);
      const compensations = responseToCompensations(response);

      dispatch({ type: Types.SEND_FINISHED });

      return Promise.resolve(compensations);
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Types.SEND_ERROR, payload: error });
    }
  };

export const approvePeriodCompensations =
  (profitCenters, periodStartDate, periodEndDate, userId, userName) =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    // TODO: this is horrible way to approve these. Consider creating one-purpose API for approving instead of this
    // property update API.
    const properties = {
      periodApprovalStatus: CompensationStatuses.Accepted,
      acceptedByPharmaCompanyUserId: userId,
      acceptedByPharmaCompanyUsername: userName,
      pharmaCompanyAcceptedAt: moment().toISOString(),
    };

    try {
      dispatch({ type: Types.SEND_STARTED });

      const profitCentersStr = profitCenters.join(",");
      const path = `${baseUrl}${updatePharmaCompanyPeriodCompensationApi}?profitCenters=${profitCentersStr}&periodStartDate=${periodStartDate}&periodEndDate=${periodEndDate}`;
      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.post(tokens, path, properties);
      const compensations = responseToCompensations(response);

      dispatch({ type: Types.SEND_FINISHED });

      return Promise.resolve(compensations);
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Types.SEND_ERROR, payload: error });
    }
  };

export const updateCompensationsProperties =
  // eslint-disable-next-line consistent-return
  (compensations, updatedProperties) => async (dispatch, getState) => {
    try {
      const path = `${baseUrl}${updatePharmaCompanyCompensations}`;

      // TODO: this is horrible way to modify these. Consider creating APIs for modifying certain properties
      const data = {
        compensations: compensations.map(compensation =>
          getRequiredPropertiesForUpdate(compensation)
        ),
        updatedProperties,
      };

      dispatch({ type: Types.SEND_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const response = await RestService.post(tokens, path, data);
      const updatedCompensations = responseToCompensations(response);

      // update compensations map
      const compensationsMap = new Map(
        getState().compensation.compensationsMap
      );
      const updatedCompensationsMap = updatedCompensations.reduce(
        (acc, next) => {
          const { periodStartDate, periodEndDate } = next;
          const key = createPeriodKeyFromDates(periodStartDate, periodEndDate);

          // get stored array
          const existingArray = acc.get(key);

          // update compensation in it if found
          if (existingArray != null) {
            const updatedArray = existingArray.slice(0);
            const index = updatedArray.findIndex(
              compensation =>
                compensation.compensationId === next.compensationId
            );
            if (index !== -1) {
              updatedArray[index] = { ...next };
            } else {
              updatedArray.push(next);
            }
            acc.set(key, updatedArray);
          } else {
            acc.set(key, [next]);
          }

          return acc;
        },
        compensationsMap
      );

      dispatch({
        type: Types.UPDATE_COMPENSATIONS,
        payload: updatedCompensationsMap,
      });

      // finish sending
      dispatch({ type: Types.SEND_FINISHED });

      return Promise.resolve(updatedCompensationsMap);
    } catch (error) {
      // TODO: error handling
      dispatch({ type: Types.SEND_ERROR, payload: error });
    }
  };

export const clearFilteredCompensations = () => ({
  type: Types.UPDATE_FILTERED_COMPENSATIONS,
  payload: {
    filteredCompensationsMap: null,
    filteredCompensationCount: 0,
  },
});

// -- REDUCER --

const DEFAULT_STATE = {
  fetchingMap: new Map(),
  fetchError: null,
  searching: false,
  searchError: null,
  sending: false,
  sendError: null,
  compensationsMap: new Map(),
  filteredCompensationsMap: null,
  filteredCompensationCount: 0,
};

// eslint-disable-next-line default-param-last
export const compensationReducer = (state = DEFAULT_STATE, action) => {
  switch (action.type) {
    case Types.FETCH_STARTED: {
      const fetchingMap = new Map(state.fetchingMap);
      fetchingMap.set(action.payload, true);
      return { ...state, fetchingMap, fetchError: null };
    }
    case Types.FETCH_ERROR:
      return { ...state, fetching: false, fetchError: action.payload };
    case Types.FETCH_FINISHED: {
      const fetchingMap = new Map(state.fetchingMap);
      fetchingMap.delete(action.payload);
      return { ...state, fetchingMap };
    }
    case Types.SEARCH_STARTED:
      return { ...state, searching: true, searchError: null };
    case Types.SEARCH_ERROR:
      return { ...state, searching: false, searchError: action.payload };
    case Types.SEARCH_FINISHED:
      return { ...state, searching: false };
    case Types.UPDATE_COMPENSATIONS: {
      return { ...state, compensationsMap: action.payload };
    }
    case Types.SEND_STARTED:
      return { ...state, sending: true, sendError: null };
    case Types.SEND_ERROR:
      return { ...state, sending: false, sendError: action.payload };
    case Types.SEND_FINISHED:
      return { ...state, sending: false };
    case Types.UPDATE_FILTERED_COMPENSATIONS: {
      const { filteredCompensationsMap, filteredCompensationCount } =
        action.payload;
      return { ...state, filteredCompensationsMap, filteredCompensationCount };
    }
    default:
      return state;
  }
};
