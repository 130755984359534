import PropTypes from "prop-types";

import { isAllowed } from "./authorizationRules";

function Can({ user, perform, data, children, ok, nok }) {
  const allowed = ok || (() => children);
  return isAllowed(user, perform, data) ? allowed() : nok();
}

Can.propTypes = {
  user: PropTypes.shape({}).isRequired,
  perform: PropTypes.string.isRequired,
  data: PropTypes.shape({}),
  ok: PropTypes.func,
  nok: PropTypes.func,
};

Can.defaultProps = {
  nok: () => null,
};

export default Can;
