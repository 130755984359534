// eslint-disable-next-line no-use-before-define
import React, { useContext, useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
// eslint-disable-next-line import/no-cycle
import { InfinityList, OverlineValue, TooltipTypography } from "../..";
import {
  CompensationStatuses,
  EURO_CURRENCY,
  RejectionReason,
} from "../../constants/constants";
import {
  aggregateCompensationProducts,
  createUpdatedProductsProperties,
  getCompensationsByCompensatedProductVNR,
  getCompensationsByRejectedProductVNR,
  absPrice,
  getPharmacyApprovedCompensations,
} from "../../../../utils/compensation/compensation";
import {
  Cell,
  RejectButton,
  RollbackRejectButton,
  NetworkLoader,
} from "../common";
import ProductPharmacyTable from "./productPharmacyTable";
import RejectPopup, { RejectPopupType } from "../../rejectPopup/rejectPopup";
import RejectPopupProductView from "../../rejectPopupProductView/rejectPopupProductView";
import { updateCompensationsProperties } from "../../../../redux";
import { TableDetailsContext } from "../tableDetailsContext";
import {
  getChildTableHeight,
  calculateNeededHeightForChildTable,
  EXTENSION_CONTAINER_BOTTOM_PADDING,
  EXTENSION_CONTAINER_TOP_PADDING,
  HEADER_TOP_PADDING,
  HEADER_BOTTOM_PADDING,
  HEADER_BORDER_WIDTH,
  HEADER_HEIGHT,
  HEADER_FULL_HEIGHT,
} from "../../../../utils/tableExtensionView";

const FIXED_ROW_HEIGHT = 71;
const ROW_SPACING = 0;
const EXTENDED_ROW_EMPTY = -1;
const POPUP_MARGIN = 8;
const EMPTY_INDEX = -1;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
  },
  headerRow: {
    height: HEADER_HEIGHT,
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: HEADER_TOP_PADDING,
    paddingBottom: HEADER_BOTTOM_PADDING,
    borderBottom: `solid ${HEADER_BORDER_WIDTH}px #e7e7e7`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    // to compensate extension
    borderLeft: "5px solid transparent",
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
  },
  productRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
    // to compensate extension
    borderLeft: "5px solid transparent",
    paddingLeft: "0.2rem",
    paddingRight: "0.2rem",
  },
  borderLeftHighlight: {
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },

  headerText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  productName: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    textTransform: "none",
    marginBottom: theme.spacing(1),
  },
  valueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  overlineValueText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
    textDecoration: "line-through",
  },
  valueTextDisabled: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  noProducts: {
    padding: "0.8rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  columnValueMargin: {
    marginLeft: theme.spacing(1),
  },

  extensionContainer: {
    borderLeftWidth: "5px",
    borderLeftColor: theme.palette.secondary.main,
    borderLeftStyle: "solid",
    borderBottomWidth: "1px",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
    borderBottomStyle: "solid",
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: EXTENSION_CONTAINER_TOP_PADDING,
    paddingBottom: EXTENSION_CONTAINER_BOTTOM_PADDING,
  },
  statusLink: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
}));

const CellWidthNormal = Object.freeze({
  number: "30%",
  pharmaCompany: "15%",
  amount: "7%",
  unitPrice: "10%",
  compensation: "10%",
  status: "14%",
  actions: "14%",
});

const CellWidthNormalWithoutActions = Object.freeze({
  number: "27%",
  pharmaCompany: "15%",
  amount: "9%",
  unitPrice: "12%",
  compensation: "12%",
  status: "14%",
  rejectionReason: "11%",
});

const CellWidthExtended = Object.freeze({
  number: "30%",
  pharmaCompany: "15%",
  amount: "5%",
  pharmacy: "8%",
  unitPrice: "10%",
  compensation: "8%",
  status: "12%",
  actions: "12%",
});

const CellWidthExtendedWithoutActions = Object.freeze({
  number: "28%",
  pharmaCompany: "14%",
  amount: "6%",
  pharmacy: "9%",
  unitPrice: "11%",
  compensation: "9%",
  status: "13%",
  rejectionReason: "10%",
});

const getColumnWidths = (showPharmacyProductCount, showActionControls) => {
  if (showPharmacyProductCount) {
    return showActionControls
      ? CellWidthExtended
      : CellWidthExtendedWithoutActions;
  }
  return showActionControls ? CellWidthNormal : CellWidthNormalWithoutActions;
};

function ProductTable({
  compensations,
  showExtension,
  showActionControls,
  showPharmacyProductCount,
  maxVisibleItems,
  minVisibleItems,
  // click overrides
  onProductRejectClick,
  onProductRollbackRejectionClick,
  highlightedRowIndex,
  fixedHeight,
  showPharmaCompanyName,
  showRejectionReason,
  tableLevel = 0,
  hideActionControlsSubTable,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [aggregatedProducts, setAggregatedProducts] = useState([]);
  const [scrollToIndex, setScrollToIndex] = useState(EMPTY_INDEX);
  const [extendedRowIndex, setExtendedRowIndex] = useState(EXTENDED_ROW_EMPTY);
  const [rejectionRowIndex, setRejectionRowIndex] =
    useState(EXTENDED_ROW_EMPTY);
  const [rejectionRowSubIndex, setRejectionRowSubIndex] =
    useState(EXTENDED_ROW_EMPTY);
  const [dynamicHeight, setDynamicHeight] = useState(null);
  const [popup, setPopup] = useState({
    show: false,
    top: 0,
    left: 0,
    pharmacy: null,
    product: null,
    pharmacyCount: null,
    mode: RejectPopupType.Product,
  });
  const [popupSize, setPopupSize] = useState({ width: 0, height: 0 });
  // TODO: handle send error
  const { sending } = useSelector(state => state.compensation);
  const dispatch = useDispatch();
  const {
    getTableDetailsOfChildTables,
    resetTableDetailsForLevel,
    setTableDetailsForLevel,
  } = useContext(TableDetailsContext);

  useEffect(() => {
    setAggregatedProducts(aggregateCompensationProducts(compensations));
  }, [compensations]);

  useEffect(() => {
    setTableDetailsForLevel(tableLevel, {
      rowCount: aggregatedProducts.length,
      defaultRowHeight: FIXED_ROW_HEIGHT,
      utilitiesHeight: HEADER_FULL_HEIGHT,
    });
    return () => {
      resetTableDetailsForLevel(tableLevel);
    };
  }, [
    aggregatedProducts,
    tableLevel,
    setTableDetailsForLevel,
    resetTableDetailsForLevel,
  ]);

  // Force recomputeRowHeights of infinite table when TableDetailsContext changes
  const virtualizedRef = useRef();
  useEffect(() => {
    if (virtualizedRef.current) {
      virtualizedRef.current.recomputeRowHeights(0);
    }
  }, [getTableDetailsOfChildTables]);

  useEffect(() => {
    setDynamicHeight(null);
  }, [extendedRowIndex]);

  const dataCellExtended = showPharmacyProductCount;
  const showActionsColumn = showActionControls;
  const cellWidth = getColumnWidths(dataCellExtended, showActionsColumn);

  // eslint-disable-next-line react/no-unstable-nested-components
  function Header() {
    return (
      <div className={classes.headerRow}>
        <Cell width={cellWidth.number}>
          <Typography className={classes.headerText}>
            {t("productNumber")}
          </Typography>
        </Cell>
        {showPharmaCompanyName && (
          <Cell width={cellWidth.pharmaCompany}>
            <Typography className={classes.headerText}>
              {t("pharmaCompany")}
            </Typography>
          </Cell>
        )}
        <Cell width={cellWidth.amount}>
          <Typography className={classes.headerText}>{t("amount")}</Typography>
        </Cell>
        {dataCellExtended === true && (
          <Cell width={cellWidth.pharmacy}>
            <Typography className={classes.headerText}>
              {t("pharmacies")}
            </Typography>
          </Cell>
        )}
        <Cell width={cellWidth.unitPrice}>
          <Typography className={classes.headerText}>
            {t("unitPrice")}
          </Typography>
        </Cell>
        <Cell width={cellWidth.compensation}>
          <Typography className={classes.headerText}>
            {t("compensation")}
          </Typography>
        </Cell>
        <Cell width={cellWidth.status}>
          <Typography className={classes.headerText}>{t("status")}</Typography>
        </Cell>
        {showActionsColumn && (
          <Cell width={cellWidth.actions}>
            <Typography className={classes.headerText}>
              {t("actions")}
            </Typography>
          </Cell>
        )}
        {showRejectionReason && (
          <Cell width={cellWidth.rejectionReason}>
            <Typography className={classes.headerText}>
              {t("rejectionReason")}
            </Typography>
          </Cell>
        )}
      </div>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-shadow
  const renderRowExtension = ({ product, compensations }) => (
    /* TODO: enable at some point
    const handlePharmacyRejectClick = (e, pharmacy, product, index) => {
      e.stopPropagation();

      setRejectionRowSubIndex(index);
      const rect = e.target.getBoundingClientRect();
      setPopup({
        show: true,
        top: rect.y,
        left: rect.x,
        pharmacy,
        product,
        type: RejectPopupType.Pharmacy,
      });
    };
    */

    <div className={classes.extensionContainer}>
      <ProductPharmacyTable
        productVNR={product.productVNR}
        status={product.status}
        compensations={compensations}
        // onRejectPharmacyClick={handlePharmacyRejectClick}
        highlightedRowIndex={rejectionRowSubIndex}
        fixedHeight={getChildTableHeight(
          false,
          getTableDetailsOfChildTables,
          tableLevel
        )}
        tableLevel={tableLevel + 1}
        maxVisibleItems={maxVisibleItems}
        minVisibleItems={minVisibleItems}
        setDynamicHeight={setDynamicHeight}
        hideActionControls={hideActionControlsSubTable}
      />
    </div>
  );
  const getStatusText = status => {
    if (status === CompensationStatuses.Rejected) {
      return t("rejected");
    }
    if (status === CompensationStatuses.Accepted) {
      return t("toBeCompensated");
    }
    return `${t("unknown")} ${status}`;
  };

  const getStatusTextColor = status => {
    if (status === CompensationStatuses.Rejected) {
      return "#ec130e";
    }
    if (status === CompensationStatuses.Accepted) {
      return "#388646";
    }
    return "grey";
  };

  const rowRenderer = ({ key, index, style }) => {
    const product = aggregatedProducts[index];
    const rowHighlightClass =
      rejectionRowIndex === index || highlightedRowIndex === index
        ? classes.borderLeftHighlight
        : null;

    const productIsRejected = product.status === CompensationStatuses.Rejected;
    const productIsAccepted = product.status === CompensationStatuses.Accepted;
    const statusText = getStatusText(product.status);
    const statusColor = getStatusTextColor(product.status);
    const rowIsExtended = extendedRowIndex === index;
    const showActions = showActionControls && product.productQuantity > 0;

    // eslint-disable-next-line @typescript-eslint/no-shadow
    const handleProductRejectClick = (e, compensations, product, idx) => {
      e.stopPropagation();
      setScrollToIndex(idx);
      setRejectionRowIndex(idx);

      // override func provided?
      if (onProductRejectClick != null) {
        onProductRejectClick(e, compensations, product, idx);
      } else {
        // TODO: check this
        const rect = e.target.getBoundingClientRect();
        setPopup({
          show: true,
          edit: true,
          top: rect.y,
          left: rect.x,
          product,
          pharmacyCount: compensations.length,
          type: RejectPopupType.Product,
        });
      }
    };
    const handleProductRollbackRejection = async (
      e,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      compensations,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      product
    ) => {
      e.stopPropagation();
      setScrollToIndex(index);
      setRejectionRowIndex(index);

      if (onProductRollbackRejectionClick != null) {
        onProductRollbackRejectionClick(e, compensations, product);
      } else {
        // revert to default
        const updatedProperties = createUpdatedProductsProperties(
          [product],
          CompensationStatuses.Accepted,
          RejectionReason.CompensationPolicy,
          ""
        );
        dispatch(
          updateCompensationsProperties(compensations, updatedProperties)
        );
      }
    };

    const handleRowClick = idx => {
      setScrollToIndex(idx);
      if (showExtension === true) {
        if (extendedRowIndex === idx) {
          setExtendedRowIndex(EXTENDED_ROW_EMPTY);
        } else {
          setExtendedRowIndex(idx);
        }
      }
    };

    const handleStatusClick = e => {
      e.stopPropagation();

      if (productIsRejected === true) {
        setScrollToIndex(index);
        const rect = e.target.getBoundingClientRect();
        setPopup({
          show: true,
          edit: false,
          top: rect.y,
          left: rect.x,
          product,
        });
      }
    };

    let toBeModifiedCompensations;
    // calculate value based on approved ones
    if (productIsRejected === true) {
      toBeModifiedCompensations = getCompensationsByRejectedProductVNR(
        product.productVNR,
        compensations
      );
    } else {
      toBeModifiedCompensations = getCompensationsByCompensatedProductVNR(
        product.productVNR,
        compensations
      );
    }

    // for visuals only
    const pharmacyApprovedCompensations = getPharmacyApprovedCompensations(
      toBeModifiedCompensations
    );

    return (
      // eslint-disable-next-line
      <div
        key={key}
        style={style}
        onClick={() => handleRowClick(index)}
        onKeyDown={() => {}}
      >
        <div className={`${classes.productRow} ${rowHighlightClass}`}>
          <Cell width={cellWidth.number}>
            <TooltipTypography
              className={classes.productName}
              tooltip={product.productName}
            >
              {product.productName}
            </TooltipTypography>
            <Typography className={classes.valueTextDisabled} noWrap>{`${t(
              "vnr"
            ).toUpperCase()} ${product.productVNR}`}</Typography>
          </Cell>
          {showPharmaCompanyName && (
            <Cell width={cellWidth.pharmaCompany}>
              <Typography
                className={classes.valueText}
              >{`${product.pharmaCompanyName}`}</Typography>
            </Cell>
          )}
          <Cell width={cellWidth.amount}>
            <OverlineValue
              overallValue={product.overallQuantity}
              actualValue={product.productQuantity}
              overline={product.overallQuantity !== product.productQuantity}
            />
          </Cell>
          {dataCellExtended === true && (
            <Cell width={cellWidth.pharmacy}>
              <OverlineValue
                overallValue={toBeModifiedCompensations.length}
                actualValue={pharmacyApprovedCompensations.length}
                overline={
                  toBeModifiedCompensations.length !==
                  pharmacyApprovedCompensations.length
                }
              />
            </Cell>
          )}
          <Cell width={cellWidth.unitPrice}>
            <OverlineValue
              overallValue={`${absPrice(
                product.previousWholeSalePrice
              )}${EURO_CURRENCY}`}
              actualValue={`${absPrice(
                product.currentWholeSalePrice
              )}${EURO_CURRENCY}`}
              overline
            />
          </Cell>
          <Cell width={cellWidth.compensation}>
            <Typography className={classes.valueText}>{`${absPrice(
              product.updatedStockValue
            )}${EURO_CURRENCY}`}</Typography>
          </Cell>
          <Cell width={cellWidth.status}>
            <Button
              onClick={e => handleStatusClick(e)}
              className={classes.statusLink}
              style={{ color: statusColor, backgroundColor: "transparent" }}
              disabled={productIsRejected === false}
            >
              {statusText}
            </Button>
          </Cell>
          {showActionsColumn && (
            <Cell width={cellWidth.actions}>
              {showActions && (
                <>
                  {sending === true && <NetworkLoader />}
                  {productIsRejected === true && sending === false && (
                    <RollbackRejectButton
                      text={t("rollbackRejection")}
                      onClick={e =>
                        handleProductRollbackRejection(
                          e,
                          toBeModifiedCompensations,
                          product
                        )
                      }
                    />
                  )}
                  {productIsAccepted === true && sending === false && (
                    <RejectButton
                      text={t("reject")}
                      onClick={e =>
                        handleProductRejectClick(
                          e,
                          toBeModifiedCompensations,
                          product,
                          index
                        )
                      }
                    />
                  )}
                </>
              )}
            </Cell>
          )}
          {showRejectionReason && product.rejectedReason && (
            <Cell width={cellWidth.rejectionReason}>
              <Typography className={classes.valueText}>
                {t(`${product.rejectedReason}`)}
              </Typography>
            </Cell>
          )}
        </div>
        {rowIsExtended === true &&
          renderRowExtension({ product, compensations })}
      </div>
    );
  };

  const getRowHeight = ({ index }) => {
    if (index !== extendedRowIndex) {
      return FIXED_ROW_HEIGHT;
    }

    const childTableHeight = calculateNeededHeightForChildTable(
      true,
      getTableDetailsOfChildTables,
      tableLevel
    );
    // Add one fixed row height to give the needed height for the parent row
    return childTableHeight + FIXED_ROW_HEIGHT;
  };

  const renderTable = () => {
    const hasProducts = aggregatedProducts.length > 0;

    let visibleCount;
    const allRowsVisible = tableLevel > 0;
    if (allRowsVisible) {
      visibleCount = aggregatedProducts.length;
    } else {
      visibleCount =
        aggregatedProducts.length < maxVisibleItems
          ? aggregatedProducts.length
          : maxVisibleItems;
      visibleCount =
        visibleCount < minVisibleItems ? minVisibleItems : visibleCount;
    }

    if (hasProducts === true) {
      let infinityListHeight;
      if (fixedHeight) {
        infinityListHeight = fixedHeight - HEADER_FULL_HEIGHT;
      }
      // Expand Product table height dynamically when child table is big
      // and only a few rows are visible in Product table view itself
      if (dynamicHeight) {
        infinityListHeight = dynamicHeight;
      }
      return (
        <InfinityList
          ref={virtualizedRef}
          items={aggregatedProducts}
          visibleItemCount={visibleCount}
          itemOverallCount={aggregatedProducts.length}
          rowHeight={FIXED_ROW_HEIGHT}
          listHeight={infinityListHeight}
          getRowHeight={getRowHeight}
          rowSpacing={ROW_SPACING}
          showLoader={false}
          onRenderRow={rowRenderer}
          onLoad={() => {}}
          scrollToIndex={scrollToIndex - visibleCount}
        />
      );
    }

    return (
      <div className={classes.noProducts}>
        <Typography className={classes.emptyText}>{t("noProducts")}</Typography>
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Popup() {
    const { edit, type, pharmacy, product, pharmacyCount, show, top, left } =
      popup;
    const topPos =
      top + popupSize.height > window.innerHeight
        ? window.innerHeight - popupSize.height - POPUP_MARGIN
        : top;
    const leftPos = left - popupSize.width - POPUP_MARGIN;

    const onCancel = () => {
      setPopup({ show: false });
      setRejectionRowIndex(EXTENDED_ROW_EMPTY);
      setRejectionRowSubIndex(EXTENDED_ROW_EMPTY);
    };

    const onReject = (
      rejectPopupType,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      pharmacy,
      // eslint-disable-next-line @typescript-eslint/no-shadow
      product,
      rejectReason,
      additionalInfo
    ) => {
      setPopup({ show: false });
      setRejectionRowIndex(EXTENDED_ROW_EMPTY);
      setRejectionRowSubIndex(EXTENDED_ROW_EMPTY);

      // product being rejected
      if (rejectPopupType === RejectPopupType.Product) {
        // reject product from all the pharmacies that has this
        const targetCompensations = getCompensationsByCompensatedProductVNR(
          product.productVNR,
          compensations
        );
        const updatedProductProperties = createUpdatedProductsProperties(
          [product],
          CompensationStatuses.Rejected,
          rejectReason,
          additionalInfo || ""
        );

        // send update
        dispatch(
          updateCompensationsProperties(
            targetCompensations,
            updatedProductProperties
          )
        );
      } else {
        // TODO: pharmacy being rejected
      }
    };

    // view rejection
    if (edit === false) {
      return (
        <RejectPopupProductView
          product={product}
          show={show}
          top={topPos}
          left={leftPos}
          onCancel={() => onCancel()}
          popupRef={ref => {
            if (ref) {
              const rect = ref.getBoundingClientRect();
              if (
                popupSize.width !== rect.width ||
                popupSize.height !== rect.height
              ) {
                setPopupSize({ width: rect.width, height: rect.height });
              }
            }
          }}
        />
      );
    }

    // edit
    return (
      <RejectPopup
        type={type}
        pharmacy={pharmacy}
        product={product}
        pharmacyCount={pharmacyCount}
        show={show}
        top={topPos}
        left={leftPos}
        onCancel={() => onCancel()}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        onReject={(type, pharmacy, product, rejectReason, additionalInfo) =>
          onReject(type, pharmacy, product, rejectReason, additionalInfo)
        }
        popupRef={ref => {
          if (ref) {
            const rect = ref.getBoundingClientRect();
            if (
              popupSize.width !== rect.width ||
              popupSize.height !== rect.height
            ) {
              setPopupSize({ width: rect.width, height: rect.height });
            }
          }
        }}
      />
    );
  }

  return (
    <div className={classes.root}>
      <Header />
      {renderTable()}
      <Popup />
    </div>
  );
}

ProductTable.propTypes = {
  compensations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  showExtension: PropTypes.bool,
  showActionControls: PropTypes.bool,
  showPharmacyProductCount: PropTypes.bool,
  maxVisibleItems: PropTypes.number,
  minVisibleItems: PropTypes.number,
  onProductRejectClick: PropTypes.func,
  onProductRollbackRejectionClick: PropTypes.func,
  highlightedRowIndex: PropTypes.number,
  hideActionControlsSubTable: PropTypes.bool,
  fixedHeight: PropTypes.number.isRequired,
  tableLevel: PropTypes.number.isRequired,
  showPharmaCompanyName: PropTypes.bool.isRequired,
  showRejectionReason: PropTypes.bool,
};

ProductTable.defaultProps = {
  showExtension: false,
  showActionControls: false,
  showPharmacyProductCount: false,
  onProductRejectClick: null,
  onProductRollbackRejectionClick: null,
  highlightedRowIndex: EXTENDED_ROW_EMPTY,
  maxVisibleItems: 10,
  minVisibleItems: 5,
  showRejectionReason: false,
  hideActionControlsSubTable: false,
};

export default ProductTable;
