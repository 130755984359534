// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";

const useStyles = makeStyles(theme => ({
  exceptionContainer: {},
  rowText: {
    ...theme.typography.button,
    textTransform: "none",
  },
  rowTextSecondary: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  valueMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  marginTop: {
    marginTop: theme.spacing(1),
  },
}));

const formatDate = date => {
  const momentDate = moment(date, ["DD-MM-YYYY", "DD.MM.YYYY"]);
  if (momentDate.isValid()) {
    return momentDate.format("DD.MM.YYYY");
  }
  return "";
};

function Exception({ startDate, endDate, description, index }) {
  const classes = useStyles();
  const className = index > 0 ? classes.marginTop : null;
  return (
    <div className={`${classes.exceptionContainer} ${className}`}>
      <Typography
        className={`${classes.rowText} ${classes.valueMarginBottom}`}
        noWrap
      >
        {description}
      </Typography>
      <Typography className={classes.rowTextSecondary} noWrap>{`${formatDate(
        startDate
      )} - ${formatDate(endDate)}`}</Typography>
    </div>
  );
}

Exception.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  description: PropTypes.string,
  index: PropTypes.number,
};

Exception.defaultProps = {
  startDate: "",
  endDate: "",
  description: "",
  index: 0,
};

function ExceptionsView({ rule }) {
  const classes = useStyles();

  if (Array.isArray(rule.exceptions) && rule.exceptions.length > 0) {
    return rule.exceptions.map((e, index) => (
      <Exception
        // eslint-disable-next-line react/no-array-index-key
        key={index}
        index={index}
        startDate={e.startDate}
        endDate={e.endDate}
        description={e.description}
      />
    ));
  }
  return <Typography className={classes.rowTextSecondary}>-</Typography>;
}

ExceptionsView.propTypes = {
  rule: PropTypes.shape({
    exceptions: PropTypes.arrayOf(
      PropTypes.shape({
        startDate: PropTypes.string,
        endDate: PropTypes.string,
        description: PropTypes.string,
      })
    ),
  }),
};

ExceptionsView.defaultProps = {
  rule: {
    exceptions: [],
  },
};

export default ExceptionsView;
