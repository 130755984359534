// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import moment from "moment";
import { Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Checkbox from "@material-ui/core/Checkbox";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import fileDownload from "js-file-download";
import { upperFirst } from "lodash";
// eslint-disable-next-line import/no-cycle
import { fetchServiceReportFile } from "../../redux";
import { ReportType } from "../common/constants/constants";
import { convertJsonToExcel } from "../../utils/oriola/oriola";
import { ExportImageWhite } from "../../images";
import { OrigoTextButton, Progress } from "../common";
import { ORIOLA_ADMIN_ROLE, ORIOLA_VIEW_ROLE } from "../auth/constants";

const useStyles = makeStyles(theme => ({
  root: {},
  titleContainer: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  sectionContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    borderBottom: "solid 1px #e7e7e7",
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
    backgroundColor: theme.palette.background.default,
  },
  section: {
    display: "flex",
    flexDirection: "column",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: "2rem",
    borderRight: `2px dashed ${theme.palette.primary.main}`,
  },
  hideSectionBorder: {
    borderRight: "1px dashed transparent",
  },
  importExcelHeading: {
    display: "block",
  },
  excelButton: {
    // margin: theme.spacing(1),
  },
  excelButtonContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  instruction: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  confirmCheckbox: {},
  checkboxConfirmed: {
    color: "#388646",
  },
  confirmButton: {
    display: "flex",
  },
  approverDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: theme.spacing(2),
    marginLeft: theme.spacing(4.5),
  },
  approverDetailsText: {
    ...theme.typography.body2,
    color: theme.palette.text.disabled,
  },
  progress: {
    marginLeft: "1rem",
  },
  fetchingChargesProgress: {
    margin: theme.spacing(1.5),
  },
}));

export const StepType = Object.freeze({
  Compensations: "compensations",
  ServiceCharges: "serviceCharges",
  Refunds: "refunds",
});

function Title({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Typography color="textPrimary" variant="subtitle2">
        {text}
      </Typography>
    </div>
  );
}

Title.propTypes = {
  text: PropTypes.string,
};

Title.defaultProps = {
  text: "",
};

function Section({ width, children, hideBorder }) {
  const classes = useStyles();

  const hideClassName = hideBorder === true ? classes.hideSectionBorder : null;
  return (
    <div className={`${classes.section} ${hideClassName}`} style={{ width }}>
      {children}
    </div>
  );
}

Section.propTypes = {
  width: PropTypes.string,
  children: PropTypes.node,
  hideBorder: PropTypes.bool,
};

Section.defaultProps = {
  width: "100%",
  children: null,
  hideBorder: false,
};

function ApproverDetails({ approver }) {
  const classes = useStyles();

  if (approver != null && approver.approverName && approver.acceptedAt) {
    const time = moment(approver.acceptedAt).format("DD.MM.YYYY, HH:mm");
    const text = `${approver.approverName}, ${time}`;
    return (
      <div className={classes.approverDetailsContainer}>
        <Typography className={classes.approverDetailsText}>{text}</Typography>
      </div>
    );
  }
  return null;
}

ApproverDetails.propTypes = {
  approver: PropTypes.shape({
    approverName: PropTypes.string,
    acceptedAt: PropTypes.string,
  }),
};

ApproverDetails.defaultProps = {
  approver: null,
};

function CompensationSteps({
  type,
  period,
  confirmed,
  approved,
  onConfirmClicked,
  approver,
  onReportCreationFailed,
}) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [checkboxConfirm, setCheckboxConfirm] = useState(false);
  const { fetchingFiles } = useSelector(state => state.oriola);
  const { sending } = useSelector(state => state.compensation);
  const { t } = useTranslation();
  const { fetchingCharges, fetchChargesError } = useSelector(
    state => state.oriola
  );
  const { userData } = useSelector(state => state.user);
  const { roles } = userData;
  const isOriolaView =
    roles.includes(ORIOLA_VIEW_ROLE) && !roles.includes(ORIOLA_ADMIN_ROLE);

  useEffect(() => {
    setCheckboxConfirm(false);
  }, [type]);

  const downloadReport = stepType => {
    const { periodStartDate, periodEndDate } = period;
    const downloadDate = moment().format("DD.MM.YYYY");

    let reportType;
    switch (stepType) {
      case StepType.Compensations:
        reportType = ReportType.Compensation;
        break;
      case StepType.ServiceCharges:
        reportType = ReportType.Charges;
        break;
      case StepType.Refunds:
        reportType = ReportType.Refunds;
        break;
      default:
        break;
    }

    dispatch(
      fetchServiceReportFile(periodStartDate, periodEndDate, reportType)
    ).then(async response => {
      // TODO: show error dialog if there is no response data
      if (response != null) {
        const fileBuffer = await convertJsonToExcel(response);

        let fileName = reportType;

        if (reportType === ReportType.Compensation) {
          fileName = `SCS_GS_${upperFirst(StepType.Refunds)}_${downloadDate}`;
        }
        fileDownload(fileBuffer, `${fileName}.xlsx`);
      } else {
        onReportCreationFailed();
      }
    });
  };

  const getTitleByType = stepType => {
    switch (stepType) {
      case StepType.Compensations:
        return t("compensations");
      case StepType.ServiceCharges:
        return t("serviceCharges");
      case StepType.Refunds:
        return t("refunds");
      default:
        return "";
    }
  };

  const confirmedColor = confirmed === true ? "#388646" : null;
  const confirmedText =
    confirmed === true
      ? t("compensationsConfirmed")
      : t("confirmCompensations");

  return (
    <div className={classes.root}>
      <Title text={getTitleByType(type)} />

      <div
        className={classes.sectionContainer}
        style={{ borderLeftColor: confirmedColor }}
      >
        <Section width="30%">
          <div>
            <Typography
              className={classes.importExcelHeading}
              color="textPrimary"
              variant="subtitle2"
            >
              {`1. ${t("downloadFile")}`}
            </Typography>
            <Typography className={classes.instruction} color="textPrimary">
              {t("winShuttleClick")}
            </Typography>

            <div className={classes.excelButtonContainer}>
              <OrigoTextButton
                className={classes.excelButton}
                size="medium"
                color="secondary"
                onClick={() => downloadReport(type)}
                disabled={approved === false || fetchingFiles === true}
              >
                <ExportImageWhite style={{ marginRight: "0.5rem" }} />
                {t("exportToExcel")}
              </OrigoTextButton>

              {fetchingFiles === true && (
                <div className={classes.progress}>
                  <Progress size={25} show />
                </div>
              )}
            </div>
          </div>
        </Section>

        <Section width="30%">
          <div>
            <Typography
              className={classes.importExcelHeading}
              color="textPrimary"
              variant="subtitle2"
            >
              {`2. ${t("importFileToSap")}`}
            </Typography>
            <Typography className={classes.instruction} color="textPrimary">
              {t("winShuttleImport")}
            </Typography>
          </div>
        </Section>

        <Section width="40%" hideBorder>
          <div>
            <Typography
              className={classes.importExcelHeading}
              color="textPrimary"
              variant="subtitle2"
            >
              {`3. ${t("confirmCompensationRequests")}`}
            </Typography>
            <Typography className={classes.instruction} color="textPrimary">
              {t("winShuttleBatch")}
            </Typography>
            <FormControlLabel
              control={
                <div>
                  {fetchingCharges === true && (
                    <Progress
                      className={classes.fetchingChargesProgress}
                      size={20}
                      show
                    />
                  )}

                  {fetchingCharges === false && (
                    <Checkbox
                      checked={checkboxConfirm || confirmed}
                      onChange={() => setCheckboxConfirm(!checkboxConfirm)}
                      value
                      style={{ color: confirmedColor }}
                      disabled={
                        confirmed ||
                        fetchingCharges === true ||
                        fetchChargesError != null ||
                        isOriolaView
                      }
                    />
                  )}
                </div>
              }
              label={confirmedText}
              className={classes.confirmCheckbox}
              style={{ color: confirmedColor }}
            />
            {confirmed === false && sending === false && (
              <OrigoTextButton
                className={classes.confirmButton}
                size="medium"
                color="secondary"
                onClick={() => onConfirmClicked(type)}
                disabled={checkboxConfirm === false}
              >
                {t("confirm")}
              </OrigoTextButton>
            )}
            {confirmed === false && sending === true && (
              <div className={classes.progress}>
                <Progress size={25} show />
              </div>
            )}
          </div>
          <ApproverDetails approver={approver} />
        </Section>
      </div>
    </div>
  );
}

CompensationSteps.propTypes = {
  type: PropTypes.string,
  period: PropTypes.shape({
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
  }),
  confirmed: PropTypes.bool.isRequired,
  onConfirmClicked: PropTypes.func.isRequired,
  approver: PropTypes.shape({
    approverName: PropTypes.string,
    acceptedAt: PropTypes.string,
  }),
  approved: PropTypes.bool,
  onReportCreationFailed: PropTypes.func,
};

CompensationSteps.defaultProps = {
  approved: false,
  onReportCreationFailed: () => {},
  approver: null,
  type: "",
  period: {
    periodStartDate: "",
    periodEndDate: "",
  },
};

export default CompensationSteps;
