// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import moment from "moment";
import { makeStyles } from "@material-ui/core/styles";
import { TextField, Button, Typography } from "@material-ui/core";
import { v4 as uuidv4 } from "uuid";
import { DateRangePicker } from "../../../common";
import { TrashcanImage } from "../../../../images";
import { AddExceptionButton } from "../buttons";

const useStyles = makeStyles(theme => ({
  centeredRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  editText: {
    ...theme.typography.button,
    textTransform: "none",
    marginLeft: theme.spacing(1),
  },
  rowText: {
    ...theme.typography.button,
    textTransform: "none",
  },
  rowTextSecondary: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  valueMarginBottom: {
    marginBottom: theme.spacing(1),
  },
  exceptionReason: {
    backgroundColor: "white",
    borderRadius: 2,
    "& fieldset": {
      borderRadius: 2,
      border: "solid 1px #a29791",
    },
    marginBottom: theme.spacing(1),
  },
  input: {
    height: "5px",
  },
  exceptionEdit: {
    marginBottom: theme.spacing(2),
  },
  exceptionEditAdd: {
    marginTop: theme.spacing(1),
  },
  exceptionTitle: {
    marginBottom: theme.spacing(1),
  },
}));

export const createEmptyException = () => ({
  id: uuidv4(),
  startDate: null,
  endDate: null,
  description: "",
});

function RemoveButton({ onClick }) {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Button className={classes.centeredRow} onClick={onClick} color="secondary">
      <TrashcanImage style={{ width: 15, height: 15 }} />
      <Typography className={classes.editText}>{t("remove")}</Typography>
    </Button>
  );
}

RemoveButton.propTypes = {
  onClick: PropTypes.func,
};

RemoveButton.defaultProps = {
  onClick: () => {},
};

function ExceptionsEdit({ rule, onExceptionChanged }) {
  const classes = useStyles();
  const { t } = useTranslation();

  // eslint-disable-next-line react/no-unstable-nested-components
  function ExceptionEdit({ exception, index }) {
    const [description, setDescription] = useState("");

    useEffect(() => {
      setDescription(exception.description);
    }, [exception, setDescription]);

    const updateDescription = desc => {
      const { exceptions } = rule;
      const idx = exceptions.map(e => e.id).indexOf(exception.id);
      exceptions[idx] = { ...exception, desc };
      onExceptionChanged(exceptions);
    };

    return (
      <div className={classes.exceptionEdit}>
        <Typography className={classes.exceptionTitle} variant="body2">{`${t(
          "exception"
        )} ${index + 1}`}</Typography>
        <TextField
          id="outlined-dense"
          placeholder={t("writeExceptionNameOrDescription")}
          className={classes.exceptionReason}
          margin="none"
          variant="outlined"
          fullWidth
          value={description}
          onBlur={() => updateDescription(description)}
          onKeyPress={e => {
            if (e.key === "Enter") {
              updateDescription(description);
            }
          }}
          onChange={e => setDescription(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
          }}
        />
        <div className={classes.centeredRow}>
          <DateRangePicker
            startDate={exception.startDate}
            endDate={exception.endDate}
            onStartDateChanged={date => {
              const startDate =
                date != null ? moment(date).format("DD-MM-YYYY") : null;
              const { exceptions } = rule;
              const idx = exceptions.map(e => e.id).indexOf(exception.id);
              exceptions[idx] = { ...exception, startDate };
              onExceptionChanged(exceptions);
            }}
            onEndDateChanged={date => {
              const endDate =
                date != null ? moment(date).format("DD-MM-YYYY") : null;
              const { exceptions } = rule;
              const idx = exceptions.map(e => e.id).indexOf(exception.id);
              exceptions[idx] = { ...exception, endDate };
              onExceptionChanged(exceptions);
            }}
          />
          <RemoveButton
            onClick={() => {
              const { exceptions } = rule;
              const idx = exceptions.map(e => e.id).indexOf(exception.id);
              exceptions.splice(idx, 1);
              onExceptionChanged(exceptions);
            }}
          />
        </div>
      </div>
    );
  }

  ExceptionEdit.propTypes = {
    exception: PropTypes.shape({}),
    index: PropTypes.number,
  };

  ExceptionEdit.defaultProps = {
    exception: {},
    index: 0,
  };

  if (Array.isArray(rule.exceptions) && rule.exceptions.length > 0) {
    return (
      <div>
        <div>
          {rule.exceptions.map((e, index) => (
            // eslint-disable-next-line react/no-array-index-key
            <ExceptionEdit key={index} index={index} exception={e} />
          ))}
        </div>
        <div className={classes.exceptionEditAdd}>
          <AddExceptionButton
            onClick={() => {
              const { exceptions } = rule;
              exceptions.push(createEmptyException());
              onExceptionChanged(exceptions);
            }}
          />
        </div>
      </div>
    );
  }
  return null;
}

ExceptionsEdit.propTypes = {
  rule: PropTypes.shape({
    exceptions: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  exception: PropTypes.shape({
    id: PropTypes.string,
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    description: PropTypes.string,
  }),
  onExceptionChanged: PropTypes.func,
};

ExceptionsEdit.defaultProps = {
  rule: {},
  exception: {},
  onExceptionChanged: () => {},
};

export default ExceptionsEdit;
