// eslint-disable-next-line no-use-before-define
import React, { createRef, forwardRef } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import MenuItem from "@material-ui/core/MenuItem";
import { useDispatch, useSelector } from "react-redux";
import fileDownload from "js-file-download";
import _ from "lodash";
// eslint-disable-next-line import/no-cycle
import { InfinityList } from "../..";
import { Cell, NetworkLoader } from "../common";
import VerticalMenu from "../../verticalMenu/verticalMenu";
import {
  ExportImageBlack,
  ManualProcessingImageBlack,
  ManualProcessingImageColor,
} from "../../../../images";
// eslint-disable-next-line import/no-cycle
import {
  updateServiceChargeStatus,
  fetchServiceReportFile,
  fetchServiceCharges,
} from "../../../../redux";
// eslint-disable-next-line import/no-cycle
import { convertJsonToExcel } from "../../../../utils/oriola/oriola";
// eslint-disable-next-line import/no-cycle
import { absPrice } from "../../../../utils/compensation/compensation";
import { EURO_CURRENCY, ChargeStatus } from "../../constants/constants";

const FIXED_ROW_HEIGHT = 70;
const ROW_SPACING = 0;

const useStyles = makeStyles(theme => ({
  root: {},
  headerRow: {
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: "0.4rem",
    paddingBottom: "0.8rem",
    borderBottom: "solid 1px #e7e7e7",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  productRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    marginLeft: "1rem",
    marginRight: "1rem",
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
  },
  headerText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  valueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  valueTextDisabled: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
    marginTop: theme.spacing(1),
  },
  manualProcessing: {
    color: "#ffa000",
    ...theme.typography.button,
    textTransform: "none",
  },
  noCharges: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  emptyText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
  menuItemContent: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

export const ChargesTableMode = Object.freeze({
  ServiceCharges: "serviceCharges",
  Refunds: "refunds",
});

function Header({ chargeTableMode }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.headerRow}>
      <Cell width="30%">
        <Typography className={classes.headerText}>
          {t("pharmaCompanyName")}
        </Typography>
      </Cell>
      <Cell width="20%">
        <Typography className={classes.headerText}>
          {t("customerNumber")}
        </Typography>
      </Cell>
      <Cell width="20%">
        {chargeTableMode === ChargesTableMode.ServiceCharges && (
          <Typography className={classes.headerText}>
            {t("linesPaid")}
          </Typography>
        )}
      </Cell>
      <Cell width="30%">
        {chargeTableMode === ChargesTableMode.ServiceCharges && (
          <Typography className={classes.headerText}>
            {t("serviceCharges")}
          </Typography>
        )}
        {chargeTableMode === ChargesTableMode.Refunds && (
          <Typography className={classes.headerText}>{t("refunds")}</Typography>
        )}
      </Cell>
      <Cell width="30%">
        {chargeTableMode === ChargesTableMode.Refunds && (
          <Typography className={classes.headerText}>{t("actions")}</Typography>
        )}
      </Cell>
    </div>
  );
}

Header.propTypes = {
  chargeTableMode: PropTypes.string.isRequired,
};

function ChargesTable({ mode, charges }) {
  const classes = useStyles();
  const { t } = useTranslation();
  const { sendingCharges } = useSelector(state => state.oriola);
  const ref = createRef();
  const dispatch = useDispatch();

  // TODO: Resolve forward reference error
  // eslint-disable-next-line
  const ServiceChargesMenuItems = forwardRef((props, ref) => {
    const onExport = charge => {
      dispatch(
        fetchServiceReportFile(
          charge.periodStartDate,
          charge.periodEndDate,
          "refunds"
        )
      ).then(async response => {
        const results = _.find(response, {
          "Profit center": charge.pharmaCompanyId,
        });
        const fileBuffer = await convertJsonToExcel(results);
        const fileName = "refunds.xlsx";
        fileDownload(fileBuffer, fileName);
      });
    };

    const onChangeStatusClick = (charge, chargeStatus) => {
      const { periodStartDate, pharmaCompanyId } = charge;

      // TODO: update already returns those
      // update
      dispatch(
        updateServiceChargeStatus(
          pharmaCompanyId,
          periodStartDate,
          chargeStatus
        )
      ).then(response => {
        if (response != null) {
          // refresh
          dispatch(
            fetchServiceCharges(
              response.periodStartDate,
              response.periodEndDate
            )
          );
        }
      });
    };

    const { charge } = props;
    const { status } = charge;
    return (
      <div>
        <MenuItem
          onClick={() => onExport(charge)}
          disabled={charge.status === ChargeStatus.manual}
          ref={ref}
        >
          <div className={classes.menuItemContent}>
            <ExportImageBlack style={{ marginRight: "0.5rem" }} />
            {t("export")}
          </div>
        </MenuItem>
        {status === ChargeStatus.automatic && (
          <MenuItem
            onClick={() => onChangeStatusClick(charge, ChargeStatus.manual)}
            disabled={charge.status === ChargeStatus.manual}
            ref={ref}
          >
            <div className={classes.menuItemContent}>
              <ManualProcessingImageBlack style={{ marginRight: "0.5rem" }} />
              {t("manualProcessing")}
            </div>
          </MenuItem>
        )}
        {status === ChargeStatus.manual && (
          <MenuItem
            onClick={() => onChangeStatusClick(charge, ChargeStatus.automatic)}
            disabled={charge.status === ChargeStatus.automatic}
            ref={ref}
          >
            <div className={classes.menuItemContent}>
              <ManualProcessingImageBlack style={{ marginRight: "0.5rem" }} />
              {t("automaticProcessing")}
            </div>
          </MenuItem>
        )}
      </div>
    );
  });

  ServiceChargesMenuItems.propTypes = {
    charge: PropTypes.shape({
      status: PropTypes.string,
    }).isRequired,
  };

  const rowRenderer = ({ key, index, style }) => {
    const charge = charges[index];
    return (
      <div key={key} style={style}>
        <div className={classes.productRow}>
          <Cell width="30%">
            <Typography className={classes.valueText}>
              {charge.pharmaCompanyName}
            </Typography>
            <Typography className={classes.valueTextDisabled} />
          </Cell>
          <Cell width="20%">
            <Typography className={classes.valueText}>
              {charge.customerNumber}
            </Typography>
          </Cell>
          <Cell width="20%">
            {mode === ChargesTableMode.ServiceCharges && (
              <Typography className={classes.valueText}>
                {charge.lines}
              </Typography>
            )}
          </Cell>
          <Cell width="30%">
            {mode === ChargesTableMode.ServiceCharges && (
              <Typography className={classes.valueText}>{`${absPrice(
                charge.serviceCharges
              )}${EURO_CURRENCY}`}</Typography>
            )}
            {mode === ChargesTableMode.Refunds &&
              charge.status === ChargeStatus.automatic && (
                <Typography className={classes.valueText}>{`${absPrice(
                  charge.refunds
                )}${EURO_CURRENCY}`}</Typography>
              )}
            {mode === ChargesTableMode.Refunds &&
              charge.status === ChargeStatus.manual && (
                <Typography className={classes.manualProcessing}>
                  <ManualProcessingImageColor /> {t("manual")}
                </Typography>
              )}
          </Cell>
          <Cell width="30%">
            {mode === ChargesTableMode.Refunds && sendingCharges === false && (
              <VerticalMenu>
                <ServiceChargesMenuItems charge={charge} ref={ref} />
              </VerticalMenu>
            )}
            {mode === ChargesTableMode.Refunds && sendingCharges === true && (
              <NetworkLoader />
            )}
          </Cell>
        </div>
      </div>
    );
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Content() {
    if (charges.length > 0) {
      return (
        <InfinityList
          items={charges}
          visibleItemCount={3}
          itemOverallCount={charges.length}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          showLoader={false}
          onRenderRow={rowRenderer}
          onLoad={() => {}}
        />
      );
    }

    return (
      <div className={classes.noCharges}>
        <Typography className={classes.emptyText}>{t("noCharges")}</Typography>
      </div>
    );
  }

  return (
    <div className={classes.root}>
      <Header chargeTableMode={mode} />
      <Content />
    </div>
  );
}

ChargesTable.propTypes = {
  mode: PropTypes.string.isRequired,
  charges: PropTypes.arrayOf(
    PropTypes.shape({
      pharmaCompanyName: PropTypes.string,
      customerNumber: PropTypes.string,
      lines: PropTypes.number,
      serviceCharges: PropTypes.number,
      refunds: PropTypes.number,
      status: PropTypes.string,
      periodStartDate: PropTypes.string,
      periodEndDate: PropTypes.string,
    })
  ).isRequired,
};

export default ChargesTable;
