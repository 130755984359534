import ExcelJS from "exceljs";
import moment from "moment";
// eslint-disable-next-line import/no-cycle
import { StepType } from "../../components/oriolaAdmin/compensationSteps";
// eslint-disable-next-line import/no-cycle
import { Status } from "../../components/oriolaAdmin/oriolaPeriodDetails";

export const convertJsonToExcel = json => {
  const workbook = new ExcelJS.Workbook();
  const worksheet = workbook.addWorksheet("Upload_data");

  // Add the column headers to the worksheet
  worksheet.addRow(Object.keys(json[0]));

  // Add each JSON object as a row in the worksheet
  json.forEach(dataItem => {
    worksheet.addRow(Object.values(dataItem));
  });

  // Generate the Excel file in a buffer
  return workbook.xlsx.writeBuffer();
};

export const convertWidthToPercentage = width => {
  const widthInPercentage = `${width.toString()}%`;
  return widthInPercentage;
};
// TODO: refactor THIS
export const updateStatus = (
  startDate,
  endDate,
  type,
  status,
  processStatuses,
  userData
) => {
  const newStatus = processStatuses;
  let found = false;

  // TODO: move these away from here!! Should not update it here.
  for (let i = 0; i < newStatus.length; i += 1) {
    if (newStatus[i].periodStartDate === startDate) {
      if (
        type === StepType.Compensations &&
        newStatus[i].compensationStatus !== Status.Finished
      ) {
        newStatus[i].compensationStatus = status;
        if (status === Status.Finished) {
          newStatus[i].compensationApprover = userData.name;
          newStatus[i].compensationAcceptedAt = moment().toISOString();
        }
      }
      if (
        type === StepType.ServiceCharges &&
        newStatus[i].serviceChargesStatus !== Status.Finished
      ) {
        newStatus[i].serviceChargesStatus = status;
        if (status === Status.Finished) {
          newStatus[i].serviceChargesApprover = userData.name;
          newStatus[i].serviceChargesAcceptedAt = moment().toISOString();
        }
      }
      if (
        type === StepType.Refunds &&
        newStatus[i].refundsStatus !== Status.Finished
      ) {
        newStatus[i].refundsStatus = status;
        if (status === Status.Finished) {
          newStatus[i].refundsApprover = userData.name;
          newStatus[i].refundsAcceptedAt = moment().toISOString();
        }
      }
      found = true;
      break;
    }
  }
  if (!found) {
    const statusObj = {};
    statusObj.periodStartDate = startDate;
    statusObj.periodEndDate = endDate;
    if (type === StepType.Compensations) {
      statusObj.compensationStatus = Status.NotStarted;
    }
    if (type === StepType.ServiceCharges) {
      statusObj.serviceChargesStatus = Status.NotStarted;
    }
    if (type === StepType.Refunds) {
      statusObj.refundsStatus = Status.NotStarted;
    }
    newStatus.push(statusObj);
  }
  return newStatus;
};

export const getStatus = (startDate, endDate, type, processStatuses) => {
  const newStatus = processStatuses;
  let status = null;
  // let found = false;
  for (let i = 0; i < newStatus.length; i += 1) {
    if (newStatus[i].periodStartDate === startDate) {
      if (type === StepType.Compensations) {
        status = newStatus[i].compensationStatus;
      }
      if (type === StepType.ServiceCharges) {
        status = newStatus[i].serviceChargesStatus;
      }
      if (type === StepType.Refunds) {
        status = newStatus[i].refundsStatus;
      }
      // found = true;
      break;
    }
  }
  /* WTF?
  // If no status is found then intialize it to 'not_started'
  if (found === false) {
    const statusObj = {};
    statusObj.periodStartDate = startDate;
    statusObj.periodEndDate = endDate;
    statusObj.compensationStatus = Status.NotStarted;
    statusObj.serviceChargesStatus = Status.NotStarted;
    statusObj.refundsStatus = Status.NotStarted;
    status = Status.NotStarted;
    newStatus.push(statusObj);
  }
  */
  return status || Status.NotStarted;
};

export const getProcessApprover = (startDate, type, processStatuses) => {
  const newStatus = processStatuses;
  const found = {};
  for (let i = 0; i < newStatus.length; i += 1) {
    if (newStatus[i].periodStartDate === startDate) {
      if (type === StepType.Compensations) {
        found.approverName = newStatus[i].compensationApprover;
        found.acceptedAt = newStatus[i].compensationAcceptedAt;
      }
      if (type === StepType.ServiceCharges) {
        found.approverName = newStatus[i].serviceChargesApprover;
        found.acceptedAt = newStatus[i].serviceChargesAcceptedAt;
      }
      if (type === StepType.Refunds) {
        found.approverName = newStatus[i].refundsApprover;
        found.acceptedAt = newStatus[i].refundsAcceptedAt;
      }
      break;
    }
  }
  return found;
};
