// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { Progress } from "..";

const useStyles = makeStyles(theme => ({
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    marginLeft: "1rem",
    ...theme.typography.body1,
    color: theme.palette.secondary.main,
  },
}));

function FetchLoader({ loading, title, size }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const text = title != null ? title : t("loading");

  if (loading === true) {
    return (
      <div className={classes.actionContainer}>
        <Progress show={loading} size={size} />
        <Typography className={classes.text}>{text}</Typography>
      </div>
    );
  }
  return null;
}

FetchLoader.propTypes = {
  loading: PropTypes.bool.isRequired,
  title: PropTypes.string,
  size: PropTypes.number,
};

FetchLoader.defaultProps = {
  size: 36,
  title: null,
};

export default FetchLoader;
