// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";

const useStyles = makeStyles(theme => ({
  actionContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
  },
  text: {
    ...theme.typography.body1,
  },
}));

function NoResults({ title, show }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const text = title != null ? title : t("noResults");

  if (show === true) {
    return (
      <div className={classes.actionContainer}>
        <Typography className={classes.text}>{text}</Typography>
      </div>
    );
  }
  return null;
}

NoResults.propTypes = {
  show: PropTypes.bool.isRequired,
  title: PropTypes.string,
};

NoResults.defaultProps = {
  title: null,
};

export default NoResults;
