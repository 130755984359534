/* eslint-disable */
import Icon from "@material-ui/core/Icon";

const styles = {
  icon: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  image: {},
};

export function ReclamationLogo() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="reclamation logo"
        src={require("./reclamationLogo/reclamationLogo.png")}
        srcSet={`
            ${require("./reclamationLogo/reclamationLogo@2x.png")} 2x,
            ${require("./reclamationLogo/reclamationLogo@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function More() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./more/more.png")}
        srcSet={`
            ${require("./more/more@2x.png")} 2x,
            ${require("./more/more@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Flask() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./flask/flask.png")}
        srcSet={`
            ${require("./flask/flask@2x.png")} 2x,
            ${require("./flask/flask@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Box() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./box/box.png")}
        srcSet={`
            ${require("./box/box@2x.png")} 2x,
            ${require("./box/box@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Transportation() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="more"
        src={require("./transportation/transportation.png")}
        srcSet={`
            ${require("./transportation/transportation@2x.png")} 2x,
            ${require("./transportation/transportation@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Info(props) {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="Info"
        src={require("./info/info.png")}
        srcSet={`
            ${require("./info/info@2x.png")} 2x,
            ${require("./info/info@3x.png")} 3x
          `}
        {...props}
      />
    </Icon>
  );
}

export function HealthForLife() {
  return (
    <img
      alt="Health for life"
      src={require("./healthForLife/health_for_life_f.png")}
      srcSet={`
          ${require("./healthForLife/health_for_life_f@2x.png")} 2x,
          ${require("./healthForLife/health_for_life_f@3x.png")} 3x
        `}
    />
  );
}

export function ArrowRight() {
  return (
    <img
      alt="Arrow right"
      src={require("./arrowRight/arrowRight.png")}
      srcSet={`
          ${require("./arrowRight/arrowRight@2x.png")} 2x,
          ${require("./arrowRight/arrowRight@3x.png")} 3x
        `}
    />
  );
}

export function Calendar() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="calendar"
        src={require("./calendar/calendar.png")}
        srcSet={`
            ${require("./calendar/calendar@2x.png")} 2x,
            ${require("./calendar/calendar@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function Search() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="search"
        src={require("./search/search.png")}
        srcSet={`
            ${require("./search/search@2x.png")} 2x,
            ${require("./search/search@3x.png")} 3x
          `}
      />
    </Icon>
  );
}

export function FolderDownload() {
  return (
    <Icon style={styles.icon}>
      <img
        style={styles.image}
        alt="folder download"
        src={require("./folderDownload/folderDownload.png")}
        srcSet={`
            ${require("./folderDownload/folderDownload@2x.png")} 2x,
            ${require("./folderDownload/folderDownload@3x.png")} 3x
          `}
      />
    </Icon>
  );
}
// eslint-disable-next-line
export const GroupIcon = ({ imageStyle }) => {
  return (
    <img
      style={imageStyle}
      src={require("./group/group.png")}
      alt="group icon"
      srcSet={`
          ${require("./group/group@2x.png")} 2x,
          ${require("./group/group@3x.png")} 3x
        `}
    />
  );
};

// @deprecated
// eslint-disable-next-line
export const ExpansionIcon = ({ imageStyle, clickEvent }) => {
  return (
    <Icon>
      <img
        style={imageStyle}
        src={require("./expansion/expansion.png")}
        alt="expansion icon"
        onClick={clickEvent}
        srcSet={`
          ${require("./expansion/expansion@2x.png")} 2x,
          ${require("./expansion/expansion@3x.png")} 3x
        `}
        onKeyDown={() => {}}
      />
    </Icon>
  );
};

// TODO: switch to use this
// eslint-disable-next-line
export const ExpansionImage = ({ imageStyle }) => {
  return (
    <img
      style={imageStyle}
      src={require("./expansion/expansion.png")}
      alt="expansion icon"
      srcSet={`
          ${require("./expansion/expansion@2x.png")} 2x,
          ${require("./expansion/expansion@3x.png")} 3x
        `}
    />
  );
};

// @deprecated
// eslint-disable-next-line
export const DashIcon = ({ imageStyle, clickEvent }) => {
  return (
    <Icon>
      <img
        style={imageStyle}
        src={require("./dash/dash.png")}
        alt="dash icon"
        onClick={clickEvent}
        srcSet={`
          ${require("./dash/dash@2x.png")} 2x,
          ${require("./dash/dash@3x.png")} 3x
        `}
        onKeyDown={() => {}}
      />
    </Icon>
  );
};

export function TrashcanImage() {
  return (
    <img
      style={styles.image}
      src={require("./trashcan/trashcan.png")}
      alt="dash icon"
      srcSet={`
          ${require("./trashcan/trashcan@2x.png")} 2x,
          ${require("./trashcan/trashcan@3x.png")} 3x
        `}
    />
  );
}

// TODO: switch to use this
// eslint-disable-next-line
export const DashImage = ({ styles }) => {
  return (
    <img
      style={styles}
      src={require("./dash/dash.png")}
      alt="dash icon"
      srcSet={`
          ${require("./dash/dash@2x.png")} 2x,
          ${require("./dash/dash@3x.png")} 3x
        `}
    />
  );
};
// eslint-disable-next-line
export const MoreVerticalImage = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./moreVert/moreVert.png")}
      alt="vertical icon"
      srcSet={`
          ${require("./moreVert/moreVert@2x.png")} 2x,
        `}
    />
  );
};
// eslint-disable-next-line
export const ExportImageWhite = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./import/whiteImport.png")}
      alt="import icon"
      srcSet={`
            ${require("./import/whiteImport@2x.png")} 2x,
            ${require("./import/whiteImport@3x.png")} 3x,
          `}
    />
  );
};
// eslint-disable-next-line
export const ExportImageBlack = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./import/blackImport.png")}
      alt="import icon"
      srcSet={`
            ${require("./import/blackImport@2x.png")} 2x,
            ${require("./import/blackImport@3x.png")} 3x,
          `}
    />
  );
};

export function SettingsIcon() {
  return (
    <Icon>
      <img
        src={require("./settings/settings.png")}
        alt="settings icon"
        srcSet={`
          ${require("./settings/settings@2x.png")} 2x,
          ${require("./settings/settings@3x.png")} 3x,
        `}
      />
    </Icon>
  );
}
// eslint-disable-next-line
export const BackArrowImage = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./backArrow/backArrow.png")}
      alt="backArrow icon"
      srcSet={`
          ${require("./backArrow/backArrow@2x.png")} 2x,
        `}
    />
  );
};
// eslint-disable-next-line
export const EditImage = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./edit/edit.png")}
      alt="Edit icon"
      srcSet={`
          ${require("./edit/edit@2x.png")} 2x,
        `}
    />
  );
};
// eslint-disable-next-line
export const SaveImage = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./save/save.png")}
      alt="Save icon"
      srcSet={`
          ${require("./save/save@2x.png")} 2x,
          ${require("./save/save@3x.png")} 3x,
        `}
    />
  );
};
// eslint-disable-next-line
export const AddExceptionImage = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./exception/exception.png")}
      alt="Exception icon"
      srcSet={`
          ${require("./exception/exception@2x.png")} 2x,
          ${require("./exception/exception@3x.png")} 3x,
        `}
    />
  );
};
// eslint-disable-next-line
export const DiscsIcon = imageStyle => {
  return (
    <Icon>
      <img
        style={imageStyle}
        src={require("./discs/discs.png")}
        alt="Discs icon"
        srcSet={`
          ${require("./discs/discs@2x.png")} 2x,
          ${require("./discs/discs@3x.png")} 3x,
        `}
      />
    </Icon>
  );
};
// eslint-disable-next-line
export const ManualProcessingImageColor = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./manualProcessing/colorManualProcessing.png")}
      alt="processing icon"
      srcSet={`
            ${require("./manualProcessing/colorManualProcessing@2x.png")} 2x,
            ${require("./manualProcessing/colorManualProcessing@3x.png")} 3x,
          `}
    />
  );
};
// eslint-disable-next-line
export const ManualProcessingImageBlack = ({ style }) => {
  return (
    <img
      style={style}
      src={require("./manualProcessing/manualProcessing.png")}
      alt="processing icon"
      srcSet={`
            ${require("./manualProcessing/manualProcessing@2x.png")} 2x,
            ${require("./manualProcessing/manualProcessing@3x.png")} 3x,
          `}
    />
  );
};
