import moment from "moment";
import Decimal from "decimal.js-light";
import {
  PharmacyStatuses,
  CompensationStatuses,
  FORMAT,
  CHECKBOX_APPROVAL_COLOR,
  CHECKBOX_REJECTION_COLOR,
  YEARS,
} from "../../components/common/constants/constants";

export const createPeriodKeyFromDates = (periodStartDate, periodEndDate) =>
  `${periodStartDate}_${periodEndDate}`;

export const absPrice = value => {
  // sanity
  if (value == null) {
    return "0.00";
  }

  // remove any spaces and switch to dot
  const noWhiteSpaceDotValue = String(value)
    .replace(/\s+/g, "")
    .replace(",", ".");
  return new Decimal(noWhiteSpaceDotValue).abs().toFixed(2);
};

const getSanitizedProductQuantity = product =>
  Number.isInteger(+product.productQuantity) ? product.productQuantity : 0;

const doAggregateCompensationProducts = products => {
  const productsCopy = products.slice(0);
  const aggregated = [];

  // check all
  while (productsCopy.length) {
    const aggregate = { ...productsCopy.shift() };

    // add conditionally
    let { pharmacyAccepted } = aggregate;
    let price = new Decimal(
      pharmacyAccepted === true ? absPrice(aggregate.updatedStockValue) : 0
    );
    const aggregateQty = getSanitizedProductQuantity(aggregate);
    let quantity = new Decimal(pharmacyAccepted === true ? aggregateQty : 0);
    let totalUpdatedStockValue = new Decimal(
      absPrice(aggregate.updatedStockValue)
    );

    // overall value for the UI
    let overallQuantity = new Decimal(aggregateQty);

    // gather same products
    // eslint-disable-next-line no-plusplus
    for (let i = productsCopy.length; i--; ) {
      const product = productsCopy[i];

      // match
      if (product.productVNR === aggregate.productVNR) {
        // add conditionally
        pharmacyAccepted = product.pharmacyAccepted;
        price = price.plus(
          pharmacyAccepted === true ? absPrice(product.updatedStockValue) : 0
        );
        totalUpdatedStockValue = totalUpdatedStockValue.plus(
          absPrice(product.updatedStockValue)
        );

        // there have been cases of invalid numbers so check before calculating
        const qty = getSanitizedProductQuantity(product);
        quantity = quantity.plus(pharmacyAccepted === true ? qty : 0);

        // overall value for the UI
        overallQuantity = overallQuantity.plus(qty);

        // remove
        productsCopy.splice(i, 1);
      }
    }
    // update
    aggregate.productQuantity = quantity.toFixed(0);
    aggregate.updatedStockValue = price.toFixed(2);
    aggregate.overallQuantity = overallQuantity.toFixed(0);
    aggregate.totalUpdatedStockValue = totalUpdatedStockValue.toFixed(2);

    // add to array
    aggregated.push(aggregate);
  }
  return aggregated;
};

export const aggregateCompensationProducts = (compensations = []) => {
  // create separated arrays
  const result = compensations.reduce(
    (acc, next) => {
      // add acceptance flag to products
      const pharmacyAccepted =
        next.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;

      // Add pharma company & ID to products
      const { pharmaCompanyName } = next;
      const { pharmaCompanyId } = next;

      // populate compensated
      const compensatedWithAccept = next.compensatedProducts.map(product => ({
        ...product,
        pharmacyAccepted,
        pharmaCompanyName,
        pharmaCompanyId,
      }));

      // populate rejected
      const rejectedWithAccept = next.rejectedProducts.map(product => ({
        ...product,
        pharmacyAccepted,
        pharmaCompanyName,
        pharmaCompanyId,
        rejectedReason: product.statusReason,
      }));

      acc.compensated = acc.compensated.concat(compensatedWithAccept);
      acc.rejected = acc.rejected.concat(rejectedWithAccept);
      return acc;
    },
    { compensated: [], rejected: [] }
  );

  const compensatedAggregated = doAggregateCompensationProducts(
    result.compensated
  );
  const rejectedAggregated = doAggregateCompensationProducts(result.rejected);

  /* For debugging
  console.log('-- aggregateCompensationProducts-- ');
  console.log('compensations', compensations.length);
  console.log('compensatedProducts', compensatedProducts.length);
  console.log('rejectedProducts', rejectedProducts.length);
  console.log('compensatedAggregated', compensatedAggregated.length);
  console.log('rejectedAggregated', rejectedAggregated.length);
  */

  // group to same array and sort by name
  const overall = compensatedAggregated.concat(rejectedAggregated);

  /* For debugging
  overall.forEach(p => {
    console.log(`${p.productQuantity} -> ${p.productName} (${p.productVNR}) `);
  });
  */

  return overall.sort((a, b) => a.productName.localeCompare(b.productName));
};

export const generatePeriodsForYear = year => {
  const periodsConsidered = [];
  for (let month = 0; month < 12; month += 1) {
    const firstPeriodDates = [];
    const firstPeriodStartDate = moment(year)
      .add(month, "month")
      .set("date", 1);
    const firstPeriodEndDate = moment(year).add(month, "month").set("date", 14);

    const today = moment();

    if (
      firstPeriodStartDate.isAfter(today) &&
      firstPeriodEndDate.isAfter(today)
    )
      break;
    firstPeriodDates.push(firstPeriodStartDate.format(FORMAT));
    firstPeriodDates.push(firstPeriodEndDate.format(FORMAT));
    const secondPeriodDates = [];
    const secondPeriodStartDate = moment(year)
      .add(month, "month")
      .set("date", 15);

    const secondPeriodEndDate = moment(year).add(month, "month").endOf("month");
    periodsConsidered.push(firstPeriodDates);

    if (
      secondPeriodStartDate.isAfter(today) &&
      secondPeriodEndDate.isAfter(today)
    )
      break;
    secondPeriodDates.push(
      moment(year).add(month, "month").set("date", 15).format(FORMAT)
    );
    secondPeriodDates.push(
      moment(year).add(month, "month").endOf("month").format(FORMAT)
    );
    periodsConsidered.push(secondPeriodDates);
  }
  return periodsConsidered;
};

export const createPeriodKey = period => {
  const { periodStartDate, periodEndDate } = period;
  return createPeriodKeyFromDates(periodStartDate, periodEndDate);
};

export const getYearFromDate = date => {
  const periodYear = moment(date).year().toString();
  return periodYear;
};

export const generateCompensationPeriods = year => {
  const periods = [];
  // create periods
  const allPeriods = generatePeriodsForYear(year);

  // group
  for (let i = 0; i < allPeriods.length; i += 1) {
    // add to results
    periods.push({
      periodStartDate: allPeriods[i][0],
      periodEndDate: allPeriods[i][1],
      key: createPeriodKeyFromDates(allPeriods[i][0], allPeriods[i][1]),
    });
  }
  return periods;
};

export const convertDateFormat = date => moment(date).format("DD.MM.YYYY");

export const getTotalServiceCharges = charges => {
  const valueDecimal = charges.reduce(
    (acc, next) => acc.plus(absPrice(next.serviceCharges)),
    new Decimal(0)
  );
  return valueDecimal.abs().toFixed(2);
};

export const getTotalRefunds = charges => {
  const valueDecimal = charges.reduce(
    (acc, next) => acc.plus(absPrice(next.refunds)),
    new Decimal(0)
  );
  return valueDecimal.abs().toFixed(2);
};

export const isOngoingPeriod = period => {
  const currentDate = moment();
  return moment(currentDate.format(FORMAT)).isBetween(
    period.periodStartDate,
    period.periodEndDate,
    null,
    "[]"
  );
};

export const isCompensationPeriodCompleted = compensations => {
  for (let i = 0; i < compensations.length; i += 1) {
    const compensation = compensations[i];
    const completed =
      compensation.oriolaStatus === CompensationStatuses.Completed;
    // Pending is the default value on new ones.
    // Check this way to mark old compensations approved that does not have this field.
    const periodApproved =
      compensation.periodApprovalStatus !== CompensationStatuses.Pending;
    if (completed === false || periodApproved === false) {
      return false;
    }
  }
  return true;
};

export const getTextKeyByStatus = compensations => {
  const accepted = isCompensationPeriodCompleted(compensations);
  return accepted
    ? CompensationStatuses.Accepted
    : CompensationStatuses.Pending;
};

export const getColorByStatus = compensations => {
  const accepted = isCompensationPeriodCompleted(compensations);
  return accepted ? CHECKBOX_APPROVAL_COLOR : CHECKBOX_REJECTION_COLOR;
};

export const isPeriodApprovedByPharmaCompany = compensations => {
  for (let i = 0; i < compensations.length; i += 1) {
    const compensation = compensations[i];
    // check this way so that old compensations without this field are set to be approved
    if (compensation.periodApprovalStatus === CompensationStatuses.Pending) {
      return false;
    }
  }
  return true;
};

export const isCompletedByOriola = compensations => {
  for (let i = 0; i < compensations.length; i += 1) {
    const compensation = compensations[i];
    if (compensation.oriolaStatus !== CompensationStatuses.Completed) {
      return false;
    }
  }
  return true;
};

export const getPharmaCompanyPeriodApprovalStatusColor = compensations => {
  const approved = isPeriodApprovedByPharmaCompany(compensations);
  return approved ? CHECKBOX_APPROVAL_COLOR : CHECKBOX_REJECTION_COLOR;
};

export const getOriolaCompletedStatusColor = compensations => {
  const completed = isCompletedByOriola(compensations);
  return completed ? CHECKBOX_APPROVAL_COLOR : CHECKBOX_REJECTION_COLOR;
};

export const getYears = () => {
  const lastFiveYears = [];
  for (let i = 0; i < YEARS; i += 1) {
    const year = moment().subtract(i, "year").year();
    lastFiveYears.push(year.toString());
  }
  return lastFiveYears;
};

export const filterProductsByVNR = (productVNR, products) =>
  products.filter(product => product.productVNR === productVNR);

export const getPharmacyApprovedCompensations = compensations =>
  compensations.filter(
    compensation =>
      compensation.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted
  );

export const areAllApprovedByPharmacies = compensations =>
  compensations.every(
    compensation =>
      compensation.pharmacyAcceptStatus === PharmacyStatuses.Accepted
  );

export const getCompensationsProducts = compensations => {
  const products = compensations.reduce(
    (acc, next) =>
      acc.concat(next.compensatedProducts).concat(next.rejectedProducts),
    []
  );
  return products.sort((a, b) => a.productName.localeCompare(b.productName));
};

export const getCompensatedProducts = compensations =>
  compensations.reduce((acc, next) => acc.concat(next.compensatedProducts), []);

export const getRejectedProducts = compensations =>
  compensations.reduce((acc, next) => acc.concat(next.rejectedProducts), []);

export const getTotalCompensationsRequested = compensations =>
  // calculate the total compensation sum by aggregating by product and then summing up
  aggregateCompensationProducts(compensations)
    .reduce(
      (sum, x) => sum.plus(absPrice(x.totalUpdatedStockValue)),
      new Decimal(0)
    )
    .toFixed(2);

export const getTotalCompensationsGiven = compensations =>
  /* The compensations array may be filtered by product name which means that the individual
   * compensations might not contain all the products from which the total sums have been
   * calculated. Thus, we cannot use the totalCompensationsGiven field. Instead, we need to
   * calculate the sum from the products in the compensation items.
   */

  // calculate the total compensation sum by aggregating by product and then summing up accepted items
  aggregateCompensationProducts(compensations)
    .filter(x => x.status === CompensationStatuses.Accepted)
    .reduce((sum, x) => sum.plus(absPrice(x.updatedStockValue)), new Decimal(0))
    .toFixed(2);

export const calculateProductsStockValue = products => {
  const result = products.reduce(
    (acc, next) => acc.plus(absPrice(next.updatedStockValue)),
    new Decimal(0)
  );
  return result.toFixed(2);
};

export const calculateProductsOverallQuantity = products => {
  const result = products.reduce(
    (acc, next) => acc.plus(next.productQuantity),
    new Decimal(0)
  );
  return result.toFixed(0);
};

export const aggregatePharmacies = compensations => {
  const compensationsCopy = compensations.slice(0);

  const aggregated = [];
  while (compensationsCopy.length) {
    const aggregate = { ...compensationsCopy.shift() };

    // determines whether to include into calculations (earlier ones does not have this so NULL -> true)
    let pharmacyAccepted =
      aggregate.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;

    // get the price values
    let requestedPrice = absPrice(aggregate.totalCompensationRequested);
    let givenPrice = absPrice(aggregate.totalCompensationGiven);

    // add conditionally
    let totalRequested = new Decimal(
      pharmacyAccepted === true ? requestedPrice : 0
    );
    let totalGiven = new Decimal(pharmacyAccepted === true ? givenPrice : 0);
    let compensatedProducts =
      pharmacyAccepted === true ? aggregate.compensatedProducts : [];
    let rejectedProducts =
      pharmacyAccepted === true ? aggregate.rejectedProducts : [];

    // gather same pharma companies
    // eslint-disable-next-line no-plusplus
    for (let i = compensationsCopy.length; i--; ) {
      const compensation = compensationsCopy[i];

      if (compensation.pharmacyId === aggregate.pharmacyId) {
        // check accepted
        pharmacyAccepted =
          compensation.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;

        // get price values
        requestedPrice = absPrice(compensation.totalCompensationRequested);
        givenPrice = absPrice(compensation.totalCompensationGiven);

        // add to pile conditionally
        totalRequested = totalRequested.plus(
          pharmacyAccepted === true ? requestedPrice : 0
        );
        totalGiven = totalGiven.plus(
          pharmacyAccepted === true ? givenPrice : 0
        );

        // add products to pile conditionally
        compensatedProducts = compensatedProducts.concat(
          pharmacyAccepted === true ? compensation.compensatedProducts : []
        );
        rejectedProducts = rejectedProducts.concat(
          pharmacyAccepted === true ? compensation.rejectedProducts : []
        );

        // remove
        compensationsCopy.splice(i, 1);
      }
    }
    aggregate.totalCompensationRequested = totalRequested;
    aggregate.totalCompensationGiven = totalGiven;
    aggregate.compensatedProducts = compensatedProducts;
    aggregate.rejectedProducts = rejectedProducts;
    aggregated.push(aggregate);
  }
  return aggregated;
};

export const aggregatePharmaCompanies = compensations => {
  const compensationsCopy = compensations.slice(0);

  const aggregated = [];
  while (compensationsCopy.length) {
    const aggregate = { ...compensationsCopy.shift() };

    // determines whether to include into calculations (earlier ones does not have this so NULL -> true)
    let pharmacyAccepted =
      aggregate.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;

    // get the price values
    let requestedPrice = absPrice(aggregate.totalCompensationRequested);
    let givenPrice = absPrice(aggregate.totalCompensationGiven);

    // add conditionally
    let totalRequested = new Decimal(
      pharmacyAccepted === true ? requestedPrice : 0
    );
    let totalGiven = new Decimal(pharmacyAccepted === true ? givenPrice : 0);
    let compensatedProducts =
      pharmacyAccepted === true ? aggregate.compensatedProducts : [];
    let rejectedProducts =
      pharmacyAccepted === true ? aggregate.rejectedProducts : [];

    // gather same pharma companies
    // eslint-disable-next-line no-plusplus
    for (let i = compensationsCopy.length; i--; ) {
      const compensation = compensationsCopy[i];

      if (compensation.pharmaCompanyId === aggregate.pharmaCompanyId) {
        // check accepted
        pharmacyAccepted =
          compensation.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;

        // get price values
        requestedPrice = absPrice(compensation.totalCompensationRequested);
        givenPrice = absPrice(compensation.totalCompensationGiven);

        // add to pile conditionally
        totalRequested = totalRequested.plus(
          pharmacyAccepted === true ? requestedPrice : 0
        );
        totalGiven = totalGiven.plus(
          pharmacyAccepted === true ? givenPrice : 0
        );

        // add products to pile conditionally
        compensatedProducts = compensatedProducts.concat(
          pharmacyAccepted === true ? compensation.compensatedProducts : []
        );
        rejectedProducts = rejectedProducts.concat(
          pharmacyAccepted === true ? compensation.rejectedProducts : []
        );

        // remove
        compensationsCopy.splice(i, 1);
      }
    }
    aggregate.totalCompensationRequested = totalRequested;
    aggregate.totalCompensationGiven = totalGiven;
    aggregate.compensatedProducts = compensatedProducts;
    aggregate.rejectedProducts = rejectedProducts;
    aggregated.push(aggregate);
  }
  return aggregated;
};

export const getProductCompensationValue = product => {
  const current = product.currentProductStockValue;
  const previous = product.previousProductStockValue;
  if (current != null && previous != null) {
    return new Decimal(current.replace(",", "."))
      .minus(previous.replace(",", "."))
      .abs()
      .toFixed(2);
  }
  return 0;
};

export const getAllCompensationsByProductVNR = (productVNR, compensations) =>
  compensations.reduce((acc, next) => {
    const products = next.compensatedProducts.concat(next.rejectedProducts);
    if (products.find(product => product.productVNR === productVNR) != null) {
      return acc.concat(next);
    }
    return acc;
  }, []);

export const getCompensationsByCompensatedProductVNR = (
  productVNR,
  compensations
) =>
  compensations.reduce((acc, next) => {
    const products = next.compensatedProducts;
    if (products.find(product => product.productVNR === productVNR) != null) {
      return acc.concat(next);
    }
    return acc;
  }, []);

export const getCompensationsByRejectedProductVNR = (
  productVNR,
  compensations
) =>
  compensations.reduce((acc, next) => {
    const products = next.rejectedProducts;
    if (products.find(product => product.productVNR === productVNR) != null) {
      return acc.concat(next);
    }
    return acc;
  }, []);

export const createUpdatedProductsProperties = (
  products,
  status,
  statusReason,
  additionalInfo
) => {
  const updatedProducts = products.map(product => ({
    productVNR: product.productVNR,
    status,
    statusReason,
    additionalInfo,
  }));
  // API wants it as products property
  return { products: updatedProducts };
};

export const getRequiredPropertiesForUpdate = compensation => ({
  pharmaCompanyId: compensation.pharmaCompanyId,
  pharmacyId: compensation.pharmacyId,
  createdAt: compensation.createdAt,
});
