import moment from "moment";
import supportedLanguages, {
  DEFAULT_LANG,
} from "../../localization/supportedLanguages";
import { RuleStatus } from "../../components/common/constants/constants";

const getSuppportedLanguageByKey = key =>
  supportedLanguages.find(l => l.key === key || l.name.toLowerCase === key);

const getLanguagesInOrderOfPrecedence = lang => {
  // Select first two letters of lang for handling situations where lang is f.e. fi-FI
  const lcLang = lang.toLowerCase().substring(0, 2);

  // Get language and if not found in supported languages then default to en
  const primaryLang =
    getSuppportedLanguageByKey(lcLang) ||
    getSuppportedLanguageByKey(DEFAULT_LANG);

  const otherLangs = supportedLanguages.filter(
    l => !(l.key === lcLang || l.name.toLowerCase === lcLang)
  );
  return [primaryLang, ...otherLangs];
};

export const getLocalizedRuleProductName = (rule, lang) => {
  // from Search engine?
  const languages = getLanguagesInOrderOfPrecedence(lang);
  for (let i = 0; i < languages.length; i += 1) {
    const value = rule.productLocalizedName
      .filter(localizedName => localizedName.language === languages[i].name)
      .map(localizedName => localizedName.value);
    if (value && value.length > 0 && value[0].length > 0) {
      return value[0];
    }
  }
  return null;
};

export const isRuleCompensated = rule => rule.status === RuleStatus.ON;

export const getTimeLimitStr = (rule, t) => {
  const start =
    rule.startDate != null
      ? moment(rule.startDate, ["DD-MM-YYYY", "DD.MM.YYYY"])
      : null;
  const end =
    rule.endDate != null
      ? moment(rule.endDate, ["DD-MM-YYYY", "DD.MM.YYYY"])
      : null;

  if (rule.status === RuleStatus.UNDEFINED) {
    return "-";
  }
  if (start == null && end == null) {
    return t("continuous");
  }
  if (start != null && end == null) {
    return `${moment(start).format("DD.MM.")} -`;
  }
  if (start == null && end != null) {
    return `- ${moment(end).format("DD.MM.YYYY")}`;
  }
  return `${moment(start).format("DD.MM.")} - ${moment(end).format(
    "DD.MM.YYYY"
  )}`;
};
