// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  Divider,
  FormControlLabel,
  Radio,
  TextField,
} from "@material-ui/core";
import { useTranslation } from "react-i18next";
import Decimal from "decimal.js-light";
import PopupLayout from "../popupLayout/popupLayout";
// eslint-disable-next-line import/no-cycle
import { Cell } from "../tables/common";
import { InfinityList } from "..";
import { RejectionReason } from "../constants/constants";

const FIXED_ROW_HEIGHT = 80;
const ROW_SPACING = 0;
const MAX_VISIBLE_ITEMS = 3;

const useStyles = makeStyles(theme => ({
  title: {
    marginTop: theme.spacing(0),
  },
  tableContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  headerRow: {
    paddingTop: "0.4rem",
    paddingBottom: "0.8rem",
    borderBottom: "solid 1px #e7e7e7",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  productRow: {
    paddingTop: "0.8rem",
    paddingBottom: "0.8rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
  },
  headerText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  name: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    textTransform: "none",
    marginBottom: theme.spacing(1),
  },
  valueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  address: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  divider: {
    height: "2px",
  },
  secondaryTitle: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    marginBottom: theme.spacing(1),
  },
  rejectReason: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  additionalInfo: {
    marginBottom: theme.spacing(4),
  },
  radioButtonGroup: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: theme.spacing(2),
  },
  radioButton: {
    color: theme.palette.secondary.main,
  },
  radioButtonLabel: {
    ...theme.typography.button,
    textTransform: "none",
  },
  additionalInfoField: {
    backgroundColor: "white",
    borderRadius: 2,
    "& fieldset": {
      borderRadius: 2,
      border: "solid 1px #a29791",
    },
    marginTop: theme.spacing(2),
  },
  input: {
    height: "5px",
  },
}));

function Product({ item }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div>
      <Typography className={classes.name} noWrap>
        {item.productName}
      </Typography>
      <Typography className={classes.address}>{`${t("vnr").toUpperCase()} ${
        item.productVNR
      }`}</Typography>
    </div>
  );
}

Product.propTypes = {
  item: PropTypes.shape({
    productName: PropTypes.string,
    productVNR: PropTypes.string,
  }),
};

Product.defaultProps = {
  item: null,
};

export const RejectPopupType = {
  Product: "product",
  Pharmacy: "pharmacy",
};

function Header({ rejectPopupType }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const pharmacyMode = rejectPopupType === RejectPopupType.Pharmacy;
  return (
    <div className={classes.headerRow}>
      {pharmacyMode === true && (
        <Cell width="30%">
          <Typography className={classes.headerText}>
            {t("pharmacy")}
          </Typography>
        </Cell>
      )}
      <Cell width="50%">
        <Typography className={classes.headerText}>
          {t("productNumber")}
        </Typography>
      </Cell>
      {pharmacyMode === false && (
        <Cell width="30%">
          <Typography className={classes.headerText}>
            {t("pharmacies")}
          </Typography>
        </Cell>
      )}
      <Cell width="20%">
        <Typography className={classes.headerText}>
          {t("compensation")}
        </Typography>
      </Cell>
    </div>
  );
}

Header.propTypes = {
  rejectPopupType: PropTypes.string,
};

Header.defaultProps = {
  rejectPopupType: null,
};

function RejectPopup({
  type,
  product,
  pharmacyCount,
  pharmacy,
  show,
  top,
  left,
  bottom,
  right,
  onReject,
  onCancel,
  popupRef,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);
  const [rejectReason, setRejectReason] = useState(
    RejectionReason.CompensationPolicy
  );
  const [additionalInfo, setAdditionalInfo] = useState("");

  useEffect(() => {
    // create list
    if (
      type === RejectPopupType.Product &&
      product != null &&
      pharmacyCount != null
    ) {
      setItems([product]);
    } else if (pharmacy != null) {
      let { compensatedProducts } = pharmacy;
      // single product
      if (product != null) {
        compensatedProducts = [product];
      }
      // create list
      const itemList = compensatedProducts.map(compensatedProduct => ({
        pharmacyName: pharmacy.pharmacyName,
        address: "",
        ...compensatedProduct,
      }));
      setItems(itemList);
    }
  }, [type, product, pharmacy, pharmacyCount, setItems]);

  const handleReject = () => {
    onReject(type, pharmacy, product, rejectReason, additionalInfo);
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  function Pharmacy({ item }) {
    if (type === RejectPopupType.Pharmacy) {
      const name = item.pharmacyName;
      const address = item.pharmacyAddress || "";
      return (
        <div>
          <Typography className={classes.name} color="textPrimary">
            {name}
          </Typography>
          <Typography className={classes.address} color="textPrimary">
            {address}
          </Typography>
        </div>
      );
    }
    return (
      <Typography className={classes.name} color="textPrimary">
        {pharmacyCount || 0}
      </Typography>
    );
  }

  Pharmacy.propTypes = {
    item: PropTypes.shape({
      pharmacyName: PropTypes.string,
      pharmacyAddress: PropTypes.string,
    }),
  };

  Pharmacy.defaultProps = {
    item: null,
  };

  const rowRenderer = ({ key, index, style }) => {
    const item = items[index];
    const compensationValue = new Decimal(
      // eslint-disable-next-line react/prop-types
      item.updatedStockValue.replace(",", ".")
    )
      .abs()
      .toFixed(2);
    const pharmacyMode = type === RejectPopupType.Pharmacy;
    return (
      <div key={key} style={style}>
        <div className={classes.productRow}>
          {pharmacyMode === true && (
            <Cell width="30%">{index === 0 && <Pharmacy item={item} />}</Cell>
          )}
          <Cell width="50%">
            <Product item={item} />
          </Cell>
          {pharmacyMode === false && (
            <Cell width="30%">
              <Pharmacy item={item} />
            </Cell>
          )}
          <Cell width="20%">
            <Typography
              className={classes.name}
            >{`${compensationValue}€`}</Typography>
          </Cell>
        </div>
      </div>
    );
  };
  // eslint-disable-next-line react/no-unstable-nested-components
  function Table() {
    const visibleItems =
      items.length < MAX_VISIBLE_ITEMS ? items.length : MAX_VISIBLE_ITEMS;
    return (
      <div>
        <InfinityList
          items={items}
          visibleItemCount={visibleItems}
          itemOverallCount={items.length}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          showLoader={false}
          onRenderRow={rowRenderer}
          onLoad={() => {}}
        />
      </div>
    );
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  function RejectReason() {
    return (
      <div className={classes.rejectReason}>
        <Typography className={classes.secondaryTitle}>
          {t("rejectionReason")}
        </Typography>
        <div className={classes.radioButtonGroup}>
          <FormControlLabel
            value="end"
            control={
              <Radio
                className={classes.radioButton}
                checked={rejectReason === RejectionReason.CompensationPolicy}
                onChange={() =>
                  setRejectReason(RejectionReason.CompensationPolicy)
                }
              />
            }
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("compensationPolicy")}
              </Typography>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={
              <Radio
                className={classes.radioButton}
                checked={rejectReason === RejectionReason.MistakeInRequest}
                onChange={() =>
                  setRejectReason(RejectionReason.MistakeInRequest)
                }
              />
            }
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("mistakeInRequest")}
              </Typography>
            }
            labelPlacement="end"
          />
          <FormControlLabel
            value="end"
            control={
              <Radio
                className={classes.radioButton}
                checked={rejectReason === RejectionReason.Other}
                onChange={() => setRejectReason(RejectionReason.Other)}
              />
            }
            label={
              <Typography className={classes.radioButtonLabel}>
                {t("other")}
              </Typography>
            }
            labelPlacement="end"
          />
        </div>
      </div>
    );
  }
  // eslint-disable-next-line react/no-unstable-nested-components
  function AdditionalInfo() {
    return (
      <div className={classes.additionalInfo}>
        <Typography className={classes.secondaryTitle}>
          {t("additionalInformationOptional")}
        </Typography>
        <TextField
          id="outlined-dense"
          placeholder={t("writeAdditionalInfo")}
          className={classes.additionalInfoField}
          margin="none"
          variant="outlined"
          fullWidth
          value={additionalInfo}
          onChange={e => setAdditionalInfo(e.target.value)}
          InputProps={{
            classes: { input: classes.input },
          }}
          autoFocus
        />
      </div>
    );
  }

  return (
    <PopupLayout
      show={show}
      left={left}
      top={top}
      right={right}
      bottom={bottom}
      onOk={handleReject}
      onCancel={onCancel}
      popupRef={popupRef}
      okKey="reject"
    >
      <Typography className={classes.title} variant="h5" color="textPrimary">
        {t("rejectCompensationRequest")}
      </Typography>
      <div className={classes.tableContainer}>
        <Header rejectPopupType={type} />
        <Table />
      </div>
      <Divider className={classes.divider} />
      <RejectReason />
      <AdditionalInfo />
    </PopupLayout>
  );
}

RejectPopup.propTypes = {
  type: PropTypes.string,
  product: PropTypes.shape({}),
  pharmacyCount: PropTypes.number,
  pharmacy: PropTypes.shape({
    pharmacyName: PropTypes.string,
    pharmacyAddress: PropTypes.string,
    compensatedProducts: PropTypes.arrayOf(PropTypes.shape({})),
  }),
  item: PropTypes.shape({}),
  top: PropTypes.number,
  left: PropTypes.number,
  bottom: PropTypes.number,
  right: PropTypes.number,
  show: PropTypes.bool,
  onReject: PropTypes.func,
  onCancel: PropTypes.func,
  popupRef: PropTypes.func,
};

RejectPopup.defaultProps = {
  type: null,
  product: null,
  pharmacy: null,
  pharmacyCount: null,
  onReject: () => {},
  onCancel: () => {},
  show: false,
  top: null,
  left: null,
  bottom: null,
  right: null,
  popupRef: () => {},
  item: null,
};

export default RejectPopup;
