// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-extraneous-dependencies
import clsx from "clsx";
import { makeStyles } from "@material-ui/core/styles";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import OutlinedInput from "@material-ui/core/OutlinedInput";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

const useStyles = makeStyles(theme => ({
  optionText: {
    color: theme.palette.text.disabled,
    textTransform: "none",
  },
  select: {},
}));

function DropDown(props) {
  const classes = useStyles();
  const {
    disabled,
    options,
    className,
    value,
    onSelect,
    formatLabel,
    noSelectionLabel,
    allowSelectNone,
    selectNoneLabel,
  } = props;

  const renderOption = (optionLabel, option, index) => (
    <MenuItem key={`select-option-${index}`} value={option}>
      <Typography
        className={classes.optionText}
        color="textPrimary"
        variant="button"
      >
        {optionLabel}
      </Typography>
    </MenuItem>
  );

  const isDisabled = disabled || options.length === 0;

  return (
    <FormControl variant="outlined" className={clsx(classes.root, className)}>
      <Select
        disabled={isDisabled}
        variant="outlined"
        value={value || ""}
        displayEmpty
        onChange={event => onSelect(event.target.value)}
        className={classes.select}
        input={<OutlinedInput margin="dense" />}
        // eslint-disable-next-line @typescript-eslint/no-shadow
        renderValue={value => {
          const text =
            value && value !== "" ? formatLabel(value) : noSelectionLabel;
          return (
            <Typography
              className={classes.optionText}
              color="textPrimary"
              variant="button"
            >
              {text}
            </Typography>
          );
        }}
      >
        {allowSelectNone && renderOption(selectNoneLabel, null, 0)}
        {options.map((option, idx) =>
          renderOption(formatLabel(option), option, idx + 1)
        )}
      </Select>
    </FormControl>
  );
}

DropDown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.object])
  ),
  formatLabel: PropTypes.func,
  onSelect: PropTypes.func,
  value: PropTypes.node,
  noSelectionLabel: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  allowSelectNone: PropTypes.bool,
  selectNoneLabel: PropTypes.string,
};

DropDown.defaultProps = {
  options: [],
  formatLabel: x => x,
  onSelect: () => {},
  noSelectionLabel: "Select",
  disabled: false,
  allowSelectNone: false,
  selectNoneLabel: "Select none",
  value: null,
  className: null,
};

export default DropDown;
