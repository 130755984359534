const createFiltersForSearchValue = searchValue => {
  searchValue = searchValue.toLocaleLowerCase();

  const tryIncludes = value => {
    try {
      return value.toLocaleLowerCase().includes(searchValue);
    } catch (e) {
      return false;
    }
  };

  const filterByPharmacy = compensation =>
    tryIncludes(compensation.pharmacyName) ||
    tryIncludes(compensation.pharmacyId)
      ? compensation
      : null;

  const filterByPharmaCompany = compensation =>
    tryIncludes(compensation.pharmaCompanyName) ? compensation : null;

  const filterProducts = productArray =>
    productArray.filter(
      product =>
        tryIncludes(product.productName) ||
        tryIncludes(product.productCode) ||
        tryIncludes(product.productVNR)
    );

  const filterByProduct = compensation => {
    const rejectedProducts = filterProducts(
      compensation.rejectedProducts || []
    );
    const compensatedProducts = filterProducts(
      compensation.compensatedProducts || []
    );
    if (rejectedProducts.length > 0 || compensatedProducts.length > 0) {
      return {
        ...compensation,
        compensatedProducts,
        rejectedProducts,
      };
    }

    return null;
  };

  return [filterByPharmacy, filterByPharmaCompany, filterByProduct];
};

const locallyFilterCompensations = (compensations, searchValue) => {
  if (!searchValue) {
    return compensations;
  }

  const filters = createFiltersForSearchValue(searchValue);

  return compensations
    .map(compensation => {
      // eslint-disable-next-line no-restricted-syntax
      for (const filter of filters) {
        const val = filter(compensation, searchValue);
        if (val) {
          return val;
        }
      }
      return null;
    })
    .filter(Boolean);
};

export default locallyFilterCompensations;
