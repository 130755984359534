// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles(theme => ({
  container: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  text: {
    marginLeft: theme.spacing(1),
    marginRight: theme.spacing(1),
  },
}));

function IconTextLabel({ text, icon, color, variant, textStyle }) {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      {icon}
      <Typography
        align="left"
        variant={variant}
        className={classes.text}
        color={color}
        style={textStyle}
      >
        {text}
      </Typography>
    </div>
  );
}

IconTextLabel.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.shape({}),
  color: PropTypes.string,
  variant: PropTypes.string,
  textStyle: PropTypes.shape({}),
};

IconTextLabel.defaultProps = {
  text: "",
  icon: null,
  color: "textPrimary",
  variant: "inherit",
  textStyle: {},
};

export default IconTextLabel;
