import ensureTrailingSlash from "../../utils/url/url";
// eslint-disable-next-line import/no-cycle
import { RestService } from "../../components/common";
import { getRefreshedToken } from "..";

const baseUrl = ensureTrailingSlash(process.env.REACT_APP_COMPENSATION_SERVICE);
const pharmacyApproverApi = "pharmacyApprover/v1";

export const Types = Object.freeze({
  SEND_CONSENT_STARTED: "PHARMACY_SEND_CONSENT_STARTED",
  SEND_CONSENT_FINISHED: "PHARMACY_SEND_CONSENT_FINISHED",
  SEND_CONSENT_ERROR: "PHARMACY_SEND_CONSENT_ERROR",
  FETCH_CONSENT_STARTED: "PHARMACY_FETCH_CONSENT_STARTED",
  FETCH_CONSENT_FINISHED: "PHARMACY_FETCH_CONSENT_FINISHED",
  FETCH_CONSENT_ERROR: "PHARMACY_FETCH_CONSENT_ERROR",
  UPDATE_CONSENT_DATA: "PHARMACY_UPDATE_CONSENT_DATA",
});

// -- ACTIONS --

export const updatePharmacyApproveData =
  consentData =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      const path = `${baseUrl}${pharmacyApproverApi}`;

      dispatch({ type: Types.SEND_CONSENT_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const approvals = await RestService.post(tokens, path, consentData);

      dispatch({ type: Types.SEND_CONSENT_FINISHED });

      return approvals;
    } catch (error) {
      dispatch({ type: Types.SEND_CONSENT_ERROR, payload: error });
    }
  };

export const fetchPharmacyApproveData =
  organizationIds =>
  // eslint-disable-next-line consistent-return
  async dispatch => {
    try {
      const organizationIdsStr = organizationIds.join(",");
      const path = `${baseUrl}${pharmacyApproverApi}?organizationIds=${organizationIdsStr}`;

      dispatch({ type: Types.FETCH_CONSENT_STARTED });

      const tokens = await dispatch(getRefreshedToken());
      const approvals = (await RestService.get(tokens, path)) || null;

      dispatch({
        type: Types.UPDATE_CONSENT_DATA,
        payload: approvals,
      });

      dispatch({ type: Types.FETCH_CONSENT_FINISHED });

      return approvals;
    } catch (error) {
      dispatch({ type: Types.FETCH_CONSENT_FINISHED, payload: error });
    }
  };

// -- REDUCER --

const INIT_STATE = {
  fetchingConsent: false,
  consentFetchError: null,
  sendingConsent: false,
  consentSendError: null,
  approvals: null,
};

// eslint-disable-next-line default-param-last
export const pharmacyReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case Types.SEND_CONSENT_STARTED:
      return { ...state, sendingConsent: true, consentSendError: null };
    case Types.SEND_CONSENT_FINISHED:
      return { ...state, sendingConsent: false };
    case Types.SEND_CONSENT_ERROR:
      return {
        ...state,
        sendingConsent: false,
        consentSendError: action.payload,
      };
    case Types.FETCH_CONSENT_STARTED:
      return { ...state, fetchingConsent: true, consentFetchError: null };
    case Types.FETCH_CONSENT_FINISHED:
      return { ...state, fetchingConsent: false };
    case Types.FETCH_CONSENT_ERROR:
      return {
        ...state,
        fetchingConsent: false,
        consentFetchError: action.payload,
      };
    case Types.UPDATE_CONSENT_DATA: {
      const approvals = action.payload;
      return { ...state, approvals };
    }
    default:
      return state;
  }
};
