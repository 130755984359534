// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import IconTextLabel from "../iconTextLabel/iconTextLabel";

const useStyles = makeStyles(() => ({
  button: {
    textTransform: "none",
    margin: 0,
  },
}));

function IconTextButton({
  text,
  icon,
  onClick,
  size,
  color,
  buttonRef,
  textStyle,
  disabled,
}) {
  const classes = useStyles();
  return (
    <Button
      size={size}
      color={color}
      className={classes.button}
      onClick={onClick}
      ref={buttonRef}
      disabled={disabled}
    >
      <IconTextLabel text={text} icon={icon} textStyle={textStyle} />
    </Button>
  );
}

IconTextButton.propTypes = {
  text: PropTypes.string,
  icon: PropTypes.shape({}),
  onClick: PropTypes.func,
  size: PropTypes.string,
  color: PropTypes.string,
  buttonRef: PropTypes.func,
  textStyle: PropTypes.shape({}),
  disabled: PropTypes.bool,
};

IconTextButton.defaultProps = {
  text: "",
  icon: null,
  onClick: () => {},
  size: "small",
  color: "default",
  textStyle: {},
  disabled: false,
  buttonRef: () => {},
};

export default IconTextButton;
