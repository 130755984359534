// eslint-disable-next-line no-use-before-define
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, IconButton } from "@material-ui/core";
import { ExpansionImage, DashImage } from "../../../images";
import HeaderRow from "./headerRow";
import { FetchError } from "../../common";
import { Cell } from "../../common/tables/common";
// eslint-disable-next-line import/no-cycle
import RulesTable from "./rulesTable";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  expandIcon: {
    marginLeft: "0.5rem",
    marginRight: "1rem",
  },
  cell: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
  },
  titleText: {
    ...theme.typography.subtitle2,
    color: "#757070",
    marginRight: "0.5rem",
  },
  rowHeader: {
    marginTop: theme.spacing(2),
  },
}));

export const RuleListMode = Object.freeze({
  DEFAULT: "DEFAULT",
  INACTIVE: "INACTIVE",
  ACTIVE: "ACTIVE",
});

function CompensationRuleList({
  title,
  rules,
  ruleCount,
  expand,
  fetching,
  fetchError,
  onLoadMore,
  ruleListMode,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [expanded, setExpanded] = useState(expand);

  // eslint-disable-next-line react/no-unstable-nested-components
  function ExpandIcon() {
    return (
      <div className={classes.expandIcon}>
        {expanded === true && (
          <IconButton size="small" onClick={() => setExpanded(false)}>
            <DashImage />
          </IconButton>
        )}
        {expanded === false && (
          <IconButton size="small" onClick={() => setExpanded(true)}>
            <ExpansionImage />
          </IconButton>
        )}
      </div>
    );
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function TitleText() {
    return <Typography className={classes.titleText}>{title}</Typography>;
  }

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <Cell width="3%">
          <ExpandIcon />
        </Cell>
        <Cell width="93%">
          <TitleText />
        </Cell>
      </div>
      {expanded === true && (
        <div>
          <div className={classes.rowHeader}>
            <HeaderRow />
          </div>
          {fetchError == null && (
            <RulesTable
              ruleListMode={ruleListMode}
              rules={rules}
              ruleCount={ruleCount}
              isLoading={fetching}
              onLoadMore={onLoadMore}
            />
          )}
          <FetchError error={fetchError} title={t("ruleFetchError")} />
        </div>
      )}
    </div>
  );
}

CompensationRuleList.propTypes = {
  title: PropTypes.string,
  rules: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  ruleCount: PropTypes.number.isRequired,
  fetching: PropTypes.bool,
  onLoadMore: PropTypes.func,
  ruleListMode: PropTypes.string,
  fetchError: PropTypes.node,
  expand: PropTypes.bool,
};

CompensationRuleList.defaultProps = {
  fetching: false,
  onLoadMore: () => {},
  ruleListMode: RuleListMode.DEFAULT,
  fetchError: null,
  expand: false,
  title: "",
};

export default CompensationRuleList;
