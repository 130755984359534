/* eslint-disable */
import { useState, useEffect } from "react";
import Paper from "@material-ui/core/Paper";
import { useDispatch, useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  IconButton,
  Checkbox,
  Tabs,
  Tab,
  Divider,
} from "@material-ui/core";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import PropTypes from "prop-types";
import moment from "moment";
import { useTranslation } from "react-i18next";
import {
  isOngoingPeriod,
  getColorByStatus,
  getTextKeyByStatus,
  getTotalCompensationsGiven,
  getTotalCompensationsRequested,
  getPharmaCompanyPeriodApprovalStatusColor,
  isPeriodApprovedByPharmaCompany,
  isCompletedByOriola,
  getOriolaCompletedStatusColor,
} from "../../utils/compensation/compensation";
import { ExpansionImage, DashImage } from "../../images";
import ProductTable from "../common/tables/productTable/productTable";
import PharmaTable, {
  PharmaTableMode,
  ExtensionMode,
} from "../common/tables/pharmaTable/pharmaTable";
import { EURO_CURRENCY } from "../common/constants/constants";
import { fetchCompensationsForPharmacy } from "../../redux";
import { Progress } from "../common";
import TableDetailsProvider from "../common/tables/tableDetailsContext";

const useStyles = makeStyles(theme => ({
  periodContainer: {
    textTransform: "none",
  },
  row: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    borderTop: "solid 2px transparent",
  },
  rowExpanded: {
    paddingTop: theme.spacing(1.5),
    paddingBottom: theme.spacing(1.5),
    marginTop: theme.spacing(2),
    borderTop: "solid 2px #ffa000",
  },
  periodOverviewContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  periodOverview: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  submissionText: {
    ...theme.typography.subtitle2,
    color: theme.palette.text.disabled,
    marginRight: theme.spacing(2),
  },
  statusCheckbox: {},
  periodDetailsContainer: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: 'lightblue',
    marginLeft: "3rem",
    marginRight: "3rem",
  },
  periodDetail: {
    display: "flex",
    flexDirection: "column",
  },
  periodDetailTitle: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  periodDetailMarginTop: {
    marginTop: theme.spacing(2),
  },
  periodDetailValue: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
  },
  periodDetailCheckbox: {
    marginTop: theme.spacing(2),
  },
  tabContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  completedCompensationText: {
    marginLeft: theme.spacing(1),
  },
  totalCompensationValueContainer: {
    marginLeft: theme.spacing(5),
    marginTop: theme.spacing(2),
    display: "flex",
    flexDirection: "row",
  },
  overallStatus: {
    display: "flex",
    flexDirection: "row",
  },
  expandIcon: {
    marginLeft: "0.5rem",
    marginRight: "1rem",
  },
  contentContainer: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  divider: {
    marginLeft: "0.5rem",
    marginRight: "0.5rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  headerDivider: {
    marginLeft: "0.2em",
    marginRight: "0.2rem",
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    backgroundColor: "#ffa000",
  },
  content: {
    marginTop: theme.spacing(4),
  },
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const TabType = Object.freeze({
  Pharmacies: "pharmacies",
  Products: "products",
});

function PeriodDetail({ children, width }) {
  const classes = useStyles();

  return (
    <div className={classes.cell} style={{ width }}>
      {children}
    </div>
  );
}

function PharmacyPeriodRow({
  period,
  compensations,
  fetchError,
  fetching,
  preload,
  inServiceProfitCenters,
  showInServiceProfitCenters,
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);
  const [tabValue, setTabValue] = useState(TabType.Pharmacies);
  const { t } = useTranslation();
  const userData = useSelector(state => state.user.userData);
  const dispatch = useDispatch();

  useEffect(() => {
    if (compensations == null && preload === true) {
      // initial fetching
      const { organizationIds } = userData;
      const { periodStartDate, periodEndDate } = period;
      dispatch(
        fetchCompensationsForPharmacy(
          organizationIds,
          periodStartDate,
          periodEndDate
        )
      );
    }
  }, [compensations, preload, period, dispatch, userData]);

  useEffect(() => {
    if (compensations != null && compensations.length === 0) {
      setExpanded(false);
    }
  }, [compensations, setExpanded]);

  function ExpandIcon({ period }) {
    const doSetExpanded = value => {
      // load compensations if not loaded yet
      if (compensations == null && value === true) {
        // initial fetching
        const { organizationIds } = userData;
        const { periodStartDate, periodEndDate } = period;
        dispatch(
          fetchCompensationsForPharmacy(
            organizationIds,
            periodStartDate,
            periodEndDate
          )
        );
      }
      setExpanded(value);
    };

    return (
      <div className={classes.expandIcon}>
        {expanded === true && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(false)}
            disabled={fetching}
          >
            <DashImage />
          </IconButton>
        )}
        {expanded === false && (
          <IconButton
            size="small"
            onClick={() => doSetExpanded(true)}
            disabled={fetching}
          >
            <ExpansionImage />
          </IconButton>
        )}
      </div>
    );
  }

  function SubmissionText({ period }) {
    const { periodStartDate, periodEndDate } = period;
    const text = `${t("submissionPeriod")} ${moment(periodStartDate).format(
      "DD."
    )}-${moment(periodEndDate).format("DD.MM.YYYY")}`;

    return (
      <div className={classes.titleContainer}>
        <Typography className={classes.submissionText}>{text}</Typography>
        <Progress show={fetching} size={25} />
        {fetchError != null && (
          <Typography className={classes.error}>{t("networkError")}</Typography>
        )}
      </div>
    );
  }

  function TotalCompensationValue({ compensations }) {
    return (
      <div className={classes.totalCompensationValueContainer}>
        {t("totalCompensationValue")}
        <Typography
          className={classes.completedCompensationText}
          variant="subtitle2"
        >
          {getTotalCompensationsGiven(compensations) + EURO_CURRENCY}
        </Typography>
      </div>
    );
  }

  function OverallStatus({ period }) {
    // no compensations
    if (compensations == null || compensations.length === 0) {
      const text = compensations == null ? "-" : t("noCompensations");
      return <Typography>{text}</Typography>;
    }

    const ongoingPeriod = isOngoingPeriod(period);
    return (
      <div className={classes.overallStatus}>
        <FormControlLabel
          control={
            <Checkbox
              checked
              value
              style={{
                color: getColorByStatus(compensations),
              }}
            />
          }
          label={t(getTextKeyByStatus(compensations))}
          className={classes.statusCheckbox}
        />
        {ongoingPeriod === false && (
          <TotalCompensationValue compensations={compensations} />
        )}
      </div>
    );
  }

  function PeriodHeader({ period }) {
    return (
      <div className={classes.periodOverviewContainer}>
        <div className={classes.periodOverview}>
          <ExpandIcon period={period} />
          <SubmissionText period={period} />
        </div>
        <OverallStatus period={period} />
      </div>
    );
  }

  function PeriodDetails() {
    return (
      <div className={classes.periodDetailsContainer}>
        <PeriodDetail width="30%">
          <div>
            <Typography
              className={`${classes.periodDetailTitle} ${classes.periodDetailMarginTop}`}
            >
              {t("totalCompensationRequested")}
            </Typography>
            <Typography
              className={classes.periodDetailValue}
              variant="subtitle2"
            >
              {`${getTotalCompensationsRequested(
                compensations
              )} ${EURO_CURRENCY}`}
            </Typography>
          </div>
        </PeriodDetail>
        <PeriodDetail width="15%">
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked
                className={classes.periodDetailCheckbox}
                style={{ color: "#388646" }}
              />
            }
            label={
              <Typography className={classes.periodDetailTitle} noWrap>
                {t("requestSent")}
              </Typography>
            }
            labelPlacement="top"
            className={classes.periodDetailMarginTop}
          />
        </PeriodDetail>
        <PeriodDetail width="15%">
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={isPeriodApprovedByPharmaCompany(compensations)}
                className={classes.periodDetailCheckbox}
                style={{
                  color:
                    getPharmaCompanyPeriodApprovalStatusColor(compensations),
                }}
              />
            }
            label={
              <Typography className={classes.periodDetailTitle} noWrap>
                {t("requestApproved")}
              </Typography>
            }
            labelPlacement="top"
            className={classes.periodDetailMarginTop}
          />
        </PeriodDetail>
        <PeriodDetail width="15%">
          <FormControlLabel
            control={
              <Checkbox
                disabled
                checked={isCompletedByOriola(compensations)}
                style={{
                  color: getOriolaCompletedStatusColor(compensations),
                }}
                className={classes.periodDetailCheckbox}
              />
            }
            label={
              <Typography className={classes.periodDetailTitle} noWrap>
                {t("requestPaid")}
              </Typography>
            }
            labelPlacement="top"
            className={classes.periodDetailMarginTop}
          />
        </PeriodDetail>
        <PeriodDetail width="25%">
          <div>
            <Typography
              className={`${classes.periodDetailTitle} ${classes.periodDetailMarginTop}`}
            >
              {t("totalCompensationValue")}
            </Typography>
            <Typography
              className={classes.periodDetailValue}
              variant="subtitle2"
            >
              {`${getTotalCompensationsGiven(compensations)} ${EURO_CURRENCY}`}
            </Typography>
          </div>
        </PeriodDetail>
      </div>
    );
  }

  function ContentTable() {
    if (tabValue === TabType.Pharmacies) {
      return (
        <div className={classes.content}>
          <TableDetailsProvider>
            <PharmaTable
              compensations={compensations}
              mode={PharmaTableMode.ShowPharmaCompany}
              extensionMode={ExtensionMode.Pharmacies}
              inServiceProfitCenters={inServiceProfitCenters}
              showInServiceProfitCenters={showInServiceProfitCenters}
              showActionControls={false}
            />
          </TableDetailsProvider>
        </div>
      );
    }
    return (
      <div className={classes.content}>
        <TableDetailsProvider>
          <ProductTable
            compensations={compensations}
            showPharmaCompanyName
            showRejectionReason
            showExtension
            hideActionControlsSubTable
          />
        </TableDetailsProvider>
      </div>
    );
  }

  function PeriodContent({ period }) {
    const handleTabChange = (event, newValue) => {
      setTabValue(newValue);
    };

    return (
      <div className={classes.contentContainer}>
        <div className={classes.tabContainer}>
          <Tabs
            value={tabValue}
            onChange={handleTabChange}
            indicatorColor="primary"
            textColor="secondary"
          >
            <Tab
              className={classes.tab}
              label={t("pharmaCompanies").toUpperCase()}
              value={TabType.Pharmacies}
            />
            <Tab
              className={classes.tab}
              label={t("products").toUpperCase()}
              value={TabType.Products}
            />
          </Tabs>
        </div>
        <ContentTable period={period} />
      </div>
    );
  }

  const renderPeriod = () => {
    // no compensations
    if (!compensations || !compensations.length) {
      return <div>{expanded && <PeriodContent period={period} />}</div>;
    }

    return (
      <div>
        {expanded && <Divider className={classes.headerDivider} />}
        <PeriodDetails period={period} />
        {expanded && <Divider className={classes.divider} />}
        {expanded && <PeriodContent period={period} />}
      </div>
    );
  };

  const rowClass = expanded ? classes.rowExpanded : classes.row;
  return (
    <div className={classes.periodContainer}>
      <Paper className={rowClass}>
        <PeriodHeader period={period} />
        {renderPeriod()}
      </Paper>
    </div>
  );
}

PharmacyPeriodRow.propTypes = {
  period: PropTypes.shape({
    periodStartDate: PropTypes.string,
    periodEndDate: PropTypes.string,
  }).isRequired,
  compensations: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  preload: PropTypes.bool,
  fetching: PropTypes.bool,
  fetchError: PropTypes.node,
  showInServiceProfitCenters: PropTypes.bool,
  inServiceProfitCenters: PropTypes.arrayOf(PropTypes.shape({})),
};

PharmacyPeriodRow.defaultProps = {
  preload: false,
  fetching: false,
  fetchError: null,
  showInServiceProfitCenters: false,
  inServiceProfitCenters: null,
};

PeriodDetail.propTypes = {
  children: PropTypes.node.isRequired,
  width: PropTypes.string.isRequired,
};

export default PharmacyPeriodRow;
