// eslint-disable-next-line no-use-before-define
import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import NotFound from "./components/404/404";
import Layout from "./components/layout";
import Paths from "./utils/navigation/navigation";
import { PharmaCompany, CompensationRules } from "./components/pharmaCompany";
import Pharmacy from "./components/pharmacy";
import Oriola from "./components/oriolaAdmin";
import {
  ViewManager,
  OrigoAuthenticator,
  ProtectedRoute,
  Permission,
} from "./components/auth";
import { userSignIn, fetchUserDataFromOrigo } from "./redux";

function App() {
  const dispatch = useDispatch();
  const userData = useSelector(state => state.user.userData);
  const { i18n } = useTranslation();

  const initData = signInData => {
    dispatch(userSignIn(signInData));

    const { userId } = signInData.userData;
    dispatch(fetchUserDataFromOrigo(userId)).then(origoUserData => {
      if (origoUserData?.language) {
        i18n.changeLanguage(origoUserData.language);
      }
    });
  };

  const renderApp = () => (
    <Router>
      <Layout>
        <Switch>
          <ProtectedRoute
            user={userData}
            path={Paths.PharmaCompany}
            component={PharmaCompany}
            failComponent={NotFound}
            requiredPermission={Permission.PHARMA_COMPANY_VIEW}
          />
          <ProtectedRoute
            user={userData}
            path={Paths.Pharmacy}
            component={Pharmacy}
            failComponent={NotFound}
            requiredPermission={Permission.PHARMACY_VIEW}
          />
          <ProtectedRoute
            user={userData}
            path={Paths.Oriola}
            component={Oriola}
            failComponent={NotFound}
            requiredPermission={Permission.ORIOLA_VIEW}
          />
          <ProtectedRoute
            user={userData}
            path={Paths.CompensationRules}
            component={CompensationRules}
            failComponent={NotFound}
            requiredPermission={Permission.PHARMA_COMPANY_VIEW}
          />
          <Route exact path="/" component={ViewManager} />
          <Route component={NotFound} />
        </Switch>
      </Layout>
    </Router>
  );

  const renderAuthApp = () => (
    <OrigoAuthenticator onSuccess={signInData => initData(signInData)}>
      {renderApp()}
    </OrigoAuthenticator>
  );

  return renderAuthApp();
}

export default App;
