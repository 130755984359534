// eslint-disable-next-line import/no-cycle
import { isTokenExpired, fetchTokenData } from "../../utils/auth/auth";
import ensureTrailingSlash from "../../utils/url/url";
import { RestService } from "../../components/common";

export const User = Object.freeze({
  USER_SIGN_IN: "USER_SIGN_IN",
  USER_SIGN_OUT: "USER_SIGN_OUT",
  SET_SESSION_ID: "USER_SET_SESSION_ID",
  SET_TOKEN_DATA: "USER_SET_TOKEN_DATA",
  UPDATE_USER_DATA: "UPDATE_USER_DATA",
});

// -- ACTIONS --

export const TOKEN_TYPES = {
  accessToken: "accessToken",
  idToken: "idToken",
};

export const setSessionId = sessionId => ({
  type: User.SET_SESSION_ID,
  payload: sessionId,
});

export const setTokenData = tokenData => ({
  type: User.SET_TOKEN_DATA,
  payload: tokenData,
});

export const userSignOut = () => ({
  type: User.USER_SIGN_OUT,
});

export const userSignIn = signInData => ({
  type: User.USER_SIGN_IN,
  payload: signInData,
});

export const getRefreshedToken = tokenType => async (dispatch, getState) => {
  // get current token data
  const { tokenData } = getState().user;

  // check if expired
  if (isTokenExpired(tokenData)) {
    // refresh token data
    const { sessionId } = getState().user;
    const result = await fetchTokenData(sessionId);
    dispatch(setTokenData(result));

    // return token
    if (!tokenType) {
      return { accessToken: result.accessToken, idToken: result.idToken };
    }
    return Promise.resolve(
      tokenType === TOKEN_TYPES.idToken
        ? { idToken: result.idToken }
        : { accessToken: result.accessToken }
    );
  }

  // return token directly since it is still valid
  if (!tokenType) {
    return { accessToken: tokenData.accessToken, idToken: tokenData.idToken };
  }
  return Promise.resolve(
    tokenType === TOKEN_TYPES.idToken
      ? { idToken: tokenData.idToken }
      : { accessToken: tokenData.accessToken }
  );
};

export const updateUserData = userData => ({
  type: User.UPDATE_USER_DATA,
  payload: userData,
});

// eslint-disable-next-line consistent-return
export const fetchUserDataFromOrigo = userId => async dispatch => {
  try {
    const baseUrl = ensureTrailingSlash(process.env.REACT_APP_ORIGO_URL);
    const userDataApi = ensureTrailingSlash(
      process.env.REACT_APP_ORIGO_USER_DATA_API
    );
    const path = `${baseUrl}api/v1/${userDataApi}${userId}`;

    const accessToken = await dispatch(
      getRefreshedToken(TOKEN_TYPES.accessToken)
    );
    const origoUserData = await RestService.get(accessToken, path);

    dispatch(
      updateUserData({
        phoneNumber: origoUserData.phoneNumber,
        language: origoUserData.language,
      })
    );
    return origoUserData;
  } catch (error) {
    // Some error handling here maybe
  }
};

// -- REDUCER --

const INIT_STATE = {
  userData: {},
  signedIn: false,
  sessionId: null,
  tokenData: null,
  consentData: null,
};

// eslint-disable-next-line default-param-last
export const userReducer = (state = INIT_STATE, action) => {
  switch (action.type) {
    case User.SET_SESSION_ID:
      return { ...state, sessionId: action.payload };
    case User.SET_TOKEN_DATA:
      return { ...state, tokenData: action.payload };
    case User.USER_SIGN_OUT:
      return {
        ...state,
        signedIn: false,
        sessionId: null,
        tokenData: null,
        userData: null,
      };
    case User.USER_SIGN_IN: {
      const { sessionId, userData, tokenData } = action.payload;
      return { ...state, sessionId, userData, tokenData, signedIn: true };
    }
    case User.UPDATE_USER_DATA:
      return { ...state, userData: { ...state.userData, ...action.payload } };
    default:
      return state;
  }
};
