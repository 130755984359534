// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect, useRef, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { InfinityList, TooltipTypography } from "../..";
import { Cell /* , RejectButton */ } from "../common";
import {
  EURO_CURRENCY,
  CompensationStatuses,
  PharmacyStatuses,
} from "../../constants/constants";
import {
  getCompensationsByCompensatedProductVNR,
  getCompensationsByRejectedProductVNR,
  filterProductsByVNR,
  calculateProductsStockValue,
  calculateProductsOverallQuantity,
} from "../../../../utils/compensation/compensation";
import { TableDetailsContext } from "../tableDetailsContext";
import {
  HEADER_TOP_PADDING,
  HEADER_BOTTOM_PADDING,
  HEADER_BORDER_WIDTH,
  HEADER_HEIGHT,
  HEADER_FULL_HEIGHT,
} from "../../../../utils/tableExtensionView";

const FIXED_ROW_HEIGHT = 77;
const ROW_SPACING = 0;

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    paddingLeft: "5%",
  },
  headerRow: {
    height: HEADER_HEIGHT,
    paddingTop: HEADER_TOP_PADDING,
    paddingBottom: HEADER_BOTTOM_PADDING,
    borderBottom: `solid ${HEADER_BORDER_WIDTH}px #e7e7e7`,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderLeft: "5px solid transparent",
  },
  productRow: {
    "&:hover": {
      backgroundColor: "rgba(0, 0, 0, 0.08)",
    },
    paddingTop: "1rem",
    paddingBottom: "1rem",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    borderBottom: "solid 1px #e7e7e7",
    borderLeft: "5px solid transparent",
  },
  borderLeftHighlight: {
    borderLeftWidth: "5px",
    borderLeftStyle: "solid",
    borderLeftColor: theme.palette.primary.main,
  },
  rowDisabled: {
    backgroundColor: "rgba(0, 0, 0, 0.08)",
  },
  columnHeaderText: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
  columnValueText: {
    color: theme.palette.text.primary,
    ...theme.typography.button,
    textTransform: "none",
  },
  columnValueTextRejected: {
    color: "#ec130e",
    ...theme.typography.button,
    textTransform: "none",
  },
  rowDirectionCenter: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  nameContainer: {
    marginLeft: "0.2rem",
  },
  name: {
    color: theme.palette.text.primary,
    ...theme.typography.body1,
    textTransform: "none",
    marginBottom: theme.spacing(1),
  },
  address: {
    color: theme.palette.text.disabled,
    ...theme.typography.button,
    textTransform: "none",
  },
}));

function Header({ shouldHideActionControls }) {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.headerRow}>
      <Cell width="35%">
        <div className={classes.borderLeftNormal}>
          <Typography className={classes.columnHeaderText}>
            {t("pharmacy")}
          </Typography>
        </div>
      </Cell>
      <Cell width="26%">
        <Typography className={classes.columnHeaderText}>
          {t("amount")}
        </Typography>
      </Cell>
      <Cell width="10%">
        <Typography className={classes.columnHeaderText}>
          {t("compensation")}
        </Typography>
      </Cell>
      <Cell width="12%">
        <Typography className={classes.columnHeaderText}>
          {t("status")}
        </Typography>
      </Cell>
      {!shouldHideActionControls && (
        <Cell width="10%">
          <Typography className={classes.columnHeaderText}>
            {t("actions")}
          </Typography>
        </Cell>
      )}
    </div>
  );
}

Header.propTypes = {
  shouldHideActionControls: PropTypes.bool,
};

Header.defaultProps = {
  shouldHideActionControls: false,
};

function Name({ item }) {
  const classes = useStyles();
  const name = item.compensation.pharmacyName;
  const address = item.compensation.pharmacyAddress || "";
  return (
    <div className={classes.nameContainer}>
      <TooltipTypography
        className={classes.name}
        color="textPrimary"
        tooltip={name}
      >
        {name}
      </TooltipTypography>
      <Typography className={classes.address} color="textPrimary">
        {address}
      </Typography>
    </div>
  );
}

Name.propTypes = {
  item: PropTypes.shape({
    compensation: PropTypes.shape({
      pharmacyName: PropTypes.string,
      pharmacyAddress: PropTypes.string,
    }),
  }),
};

Name.defaultProps = {
  item: {},
};

function ProductCount({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.rowDirectionCenter}>
      <Typography className={classes.columnValueText}>
        {item.productCount}
      </Typography>
    </div>
  );
}

ProductCount.propTypes = {
  item: PropTypes.shape({
    productCount: PropTypes.number,
  }),
};

ProductCount.defaultProps = {
  item: {},
};

function CompensationValue({ item }) {
  const classes = useStyles();

  return (
    <div className={classes.rowDirectionCenter}>
      <Typography className={classes.columnValueText}>
        {`${item.compensationValue}${EURO_CURRENCY}`}
      </Typography>
    </div>
  );
}

CompensationValue.propTypes = {
  item: PropTypes.shape({
    compensationValue: PropTypes.number,
  }),
};

CompensationValue.defaultProps = {
  item: {},
};

function Status({ compensationStatus }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const rejected = compensationStatus === CompensationStatuses.Rejected;
  if (rejected === false) {
    return (
      <Typography
        className={classes.columnValueText}
        style={{ color: "#388646" }}
      >
        {t("toBeCompensated")}
      </Typography>
    );
  }
  return (
    <Typography className={classes.columnValueTextRejected}>
      {t("rejected")}
    </Typography>
  );
}

Status.propTypes = {
  compensationStatus: PropTypes.string.isRequired,
};

function ProductPharmacyTable({
  productVNR,
  status,
  compensations,
  // onRejectPharmacyClick,
  highlightedRowIndex,
  fixedHeight,
  setDynamicHeight,
  maxVisibleItems,
  minVisibleItems,
  tableLevel = 0,
  hideActionControls = false,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [items, setItems] = useState([]);

  const hasPharmacies = items.length > 0;
  let visibleCount;

  const allRowsVisible = tableLevel > 0;
  if (allRowsVisible) {
    visibleCount = items.length;
  } else {
    visibleCount =
      items.length < maxVisibleItems ? items.length : maxVisibleItems;
    visibleCount =
      visibleCount < minVisibleItems ? minVisibleItems : visibleCount;
  }

  let infinityListHeight;
  if (hasPharmacies) {
    if (fixedHeight) {
      infinityListHeight = fixedHeight - HEADER_FULL_HEIGHT;
    }
  }
  // Setting the height of parent table according to this table height
  // when rendered as a child table
  useEffect(() => {
    setDynamicHeight(
      infinityListHeight > FIXED_ROW_HEIGHT * maxVisibleItems
        ? FIXED_ROW_HEIGHT * maxVisibleItems
        : null
    );
  }, [setDynamicHeight, infinityListHeight, maxVisibleItems]);

  const {
    getTableDetailsOfChildTables,
    resetTableDetailsForLevel,
    setTableDetailsForLevel,
  } = useContext(TableDetailsContext);

  useEffect(() => {
    setTableDetailsForLevel(tableLevel, {
      rowCount: items.length,
      defaultRowHeight: FIXED_ROW_HEIGHT,
      utilitiesHeight: HEADER_FULL_HEIGHT,
    });
    return () => {
      resetTableDetailsForLevel(tableLevel);
    };
  }, [items, tableLevel, setTableDetailsForLevel, resetTableDetailsForLevel]);

  useEffect(() => {
    let filteredCompensations;
    const rejected = status === CompensationStatuses.Rejected;
    if (rejected === true) {
      filteredCompensations = getCompensationsByRejectedProductVNR(
        productVNR,
        compensations
      );
    } else {
      filteredCompensations = getCompensationsByCompensatedProductVNR(
        productVNR,
        compensations
      );
    }

    let itemArray = filteredCompensations.reduce((acc, next) => {
      let products;
      if (rejected === true) {
        products = filterProductsByVNR(productVNR, next.rejectedProducts);
      } else {
        products = filterProductsByVNR(productVNR, next.compensatedProducts);
      }

      return acc.concat({
        compensation: next,
        productCount: calculateProductsOverallQuantity(products),
        compensationValue: calculateProductsStockValue(products),
      });
    }, []);

    // Sort pharmacy A-Z
    itemArray = itemArray.sort((a, b) =>
      (a.compensation.pharmacyName || "").localeCompare(
        b.compensation.pharmacyName || "",
        "fi"
      )
    );
    setItems(itemArray);
  }, [compensations, productVNR, setItems, status]);

  // Force recomputeRowHeights of infinite table when TableDetailsContext changes
  const virtualizedRef = useRef();
  useEffect(() => {
    if (virtualizedRef.current) {
      virtualizedRef.current.recomputeRowHeights(0);
    }
  }, [getTableDetailsOfChildTables]);

  /* ¨
  // TODO: add pharmacy reject to product table extension
  <RejectButton
              text={t('rejectPharmacy')}
              onClick={e => onRejectPharmacyClick(e, item.compensation, item.product, index)}
            />
  */

  const rowRenderer = ({ key, index, style }) => {
    const item = items[index];
    const highlightClass =
      highlightedRowIndex === index ? classes.borderLeftHighlight : null;
    const pharmacyAccepted =
      item.compensation.pharmacyAcceptStatus !== PharmacyStatuses.NotAccepted;
    const rowDisabledClass =
      pharmacyAccepted === false ? classes.rowDisabled : null;

    return (
      <div key={key} style={style}>
        <div
          className={`${classes.productRow} ${highlightClass} ${rowDisabledClass}`}
        >
          <Cell width="35%">
            <Name item={item} />
          </Cell>
          <Cell width="26%">
            <ProductCount item={item} />
          </Cell>
          <Cell width="10%">
            <CompensationValue item={item} />
          </Cell>
          <Cell width="12%">
            <Status compensationStatus={status} />
          </Cell>
          <Cell width="10%">
            {pharmacyAccepted === false && (
              <Typography className={classes.columnHeaderText}>
                {t("pharmacyNotApproved")}
              </Typography>
            )}
          </Cell>
        </div>
      </div>
    );
  };

  const renderTable = () => {
    if (hasPharmacies) {
      return (
        <InfinityList
          ref={virtualizedRef}
          items={items}
          visibleItemCount={visibleCount}
          itemOverallCount={items.length}
          rowHeight={FIXED_ROW_HEIGHT}
          rowSpacing={ROW_SPACING}
          showLoader={false}
          onRenderRow={rowRenderer}
          onLoad={() => {}}
          listHeight={infinityListHeight}
        />
      );
    }
    return (
      <div className={classes.noProducts}>
        <Typography className={classes.emptyText}>{t("noProducts")}</Typography>
      </div>
    );
  };

  return (
    <div className={classes.root}>
      <Header shouldHideActionControls={hideActionControls} />
      {renderTable()}
    </div>
  );
}

ProductPharmacyTable.propTypes = {
  compensations: PropTypes.arrayOf(
    PropTypes.shape({
      pharmacyName: PropTypes.string,
      pharmacyAddress: PropTypes.string,
      pharmacyAcceptStatus: PropTypes.string,
      compensatedProducts: PropTypes.arrayOf(PropTypes.shape({})),
      rejectedProducts: PropTypes.arrayOf(PropTypes.shape({})),
    })
  ).isRequired,
  productVNR: PropTypes.string.isRequired,
  status: PropTypes.string.isRequired,
  compensation: PropTypes.shape({}),
  highlightedRowIndex: PropTypes.number,
  hideActionControls: PropTypes.bool,
  fixedHeight: PropTypes.number.isRequired,
  tableLevel: PropTypes.number,
  minVisibleItems: PropTypes.number.isRequired,
  maxVisibleItems: PropTypes.number.isRequired,
  setDynamicHeight: PropTypes.func,
};

ProductPharmacyTable.defaultProps = {
  compensation: {},
  highlightedRowIndex: -1,
  hideActionControls: false,
  tableLevel: 0,
  setDynamicHeight: () => {},
};

export default ProductPharmacyTable;
