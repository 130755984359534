// eslint-disable-next-line no-use-before-define
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Typography, Button } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { useSessionStorage } from "@oriola-origo/origo-ui-hooks";
import useWindowDimensions from "../hooks/useWindowDimensions";
import { userSignOut } from "../../redux/user/user";
import {
  fetchTokenData,
  parseUserData,
  openLoginPage,
  openPortal,
} from "../../utils/auth/auth";
import { Progress } from "../common";

const EMPTY_SESSION_ID = null;

function AuthLoader() {
  const { width, height } = useWindowDimensions();
  const size = 100;
  const top = height / 2 - size / 2;
  const left = width / 2 - size / 2;
  return (
    <div style={{ position: "absolute", top, left }}>
      <Progress show size={size} />
    </div>
  );
}

function Logout() {
  const [, setStoredSessionId] = useSessionStorage("sessionId");
  const dispatch = useDispatch();
  useEffect(() => {
    // clear
    setStoredSessionId(EMPTY_SESSION_ID);

    // clear
    dispatch(userSignOut());

    // redirect to origo portal
    openPortal(window.location);
  }, [dispatch, setStoredSessionId]);

  return <AuthLoader />;
}

function LoginError({ error }) {
  const { t } = useTranslation();

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography color="error" style={{ marginTop: "3rem" }} variant="h5">
        {t("signInFailed")}
      </Typography>
      <Typography style={{ marginTop: "1rem" }} variant="body1">
        {`${t("error")}: ${error.message}`}
      </Typography>
      <Button
        style={{ marginTop: "1rem" }}
        variant="contained"
        color="primary"
        onClick={() => openPortal(window.location)}
      >
        {t("openOrigo")}
      </Button>
    </div>
  );
}

function Authenticator({ children, history, onSuccess }) {
  const [storedSessionId, setStoredSessionId] = useSessionStorage(
    "sessionId",
    EMPTY_SESSION_ID
  );
  const user = useSelector(state => state.user);
  const [loginError] = useState(null);

  // sign in function
  const doRefreshToken = sessionId => {
    fetchTokenData(sessionId)
      .then(tokenData => {
        // get user data
        const userData = parseUserData(tokenData.idToken);

        // success
        onSuccess({
          sessionId,
          tokenData,
          userData,
        });
      })
      .catch(() => {
        // clear session
        setStoredSessionId(EMPTY_SESSION_ID);

        // redirect to login page
        openLoginPage(window.location);
      });
  };

  // authentication (token refresh)
  const authenticate = () => {
    // get session id (if there)
    const params = new URLSearchParams(window.location.search);
    const parsedParams = Object.fromEntries(params.entries());
    const receivedSessionId = parsedParams.session_id;

    // if loading directly the page there's no session id. When the
    // Origo calls back there's session id.
    if (receivedSessionId == null) {
      // check whether there's valid session
      if (storedSessionId === EMPTY_SESSION_ID) {
        // redirect to login page
        openLoginPage(window.location);
      } else {
        // there's valid session id, refresh token and sign in
        doRefreshToken(storedSessionId);
      }
    } else {
      // store session to local store and redux
      setStoredSessionId(receivedSessionId);

      // refresh token and sign in
      doRefreshToken(receivedSessionId);

      // open path "/" without session id
      history.push("/");
    }
  };

  // auth when loaded
  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(authenticate, []);

  if (user.signedIn) {
    return <div>{children}</div>;
  }
  if (loginError) {
    return <LoginError error={loginError} />;
  }
  return <AuthLoader />;
}

function AppAuthenticator(props) {
  return (
    <Router>
      <Switch>
        <Route path="/logout" component={Logout} />
        <Route
          path="/"
          render={routeProps => <Authenticator {...routeProps} {...props} />}
        />
      </Switch>
    </Router>
  );
}

AppAuthenticator.propTypes = {
  children: PropTypes.node,
};

AppAuthenticator.defaultProps = {
  children: null,
};

Authenticator.propTypes = {
  children: PropTypes.node,
  location: PropTypes.shape({}),
  onSuccess: PropTypes.func,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
};

Authenticator.defaultProps = {
  children: null,
  location: null,
  onSuccess: () => {},
  history: null,
};

LoginError.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }),
};

LoginError.defaultProps = {
  error: null,
};

export default AppAuthenticator;
