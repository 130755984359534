/* eslint-disable */
const log = false;

const logger = store => next => action => {
  log === true && console.group(action.type);
  log === true && console.info("dispatching", action);
  const result = next(action);
  log === true && console.log("next state", store.getState());
  log === true && console.groupEnd();
  return result;
};

export default logger;
