// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { useSelector, useDispatch } from "react-redux";
import { Paper, Tabs, Tab } from "@material-ui/core";
import SearchField from "../../common/searchField/searchField";
import { NaviHeader, Progress } from "../../common";
import CompensationRuleList, { RuleListMode } from "./compensationRuleList";
import { DEFAULT_VISIBLE_ITEMS } from "./rulesTable";
import {
  fetchDefaultRule,
  fetchActiveRules,
  fetchUndefinedRules,
  searchRules,
} from "../../../redux";

const MAX_SEARCH_RECORDS = 999;

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    padding: "1rem",
  },
  tabContainer: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  tabs: {
    display: "flex",
    flexDirection: "row",
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(0),
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  searchContainer: {
    marginBottom: theme.spacing(4),
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  ruleContainer: {
    marginBottom: theme.spacing(4),
  },
  progress: {
    marginLeft: "1rem",
  },
}));

const TabType = Object.freeze({
  Products: "products",
  ProductCategories: "productCategories",
});

function CompensationRules() {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(TabType.Products);
  const {
    fetchingActiveRules,
    fetchingUndefinedRules,
    fetchingDefaultRule,
    activeRuleFetchError,
    undefinedRuleFetchError,
    defaultRuleFetchError,
    activeRules,
    activeRuleCount,
    undefinedRules,
    undefinedRuleCount,
    defaultRules,
    searchingRules,
  } = useSelector(state => state.rules);
  const { userData } = useSelector(state => state.user);
  const dispatch = useDispatch();
  const [searchActive, setSearchActive] = useState(false);

  useEffect(() => {
    if (searchActive === false) {
      const initialCount = DEFAULT_VISIBLE_ITEMS * 2 - 1;
      dispatch(fetchDefaultRule(userData.profitCenters));
      dispatch(fetchUndefinedRules(userData.profitCenters, 0, initialCount));
      dispatch(fetchActiveRules(userData.profitCenters, 0, initialCount));
    }
  }, [dispatch, userData, searchActive]);

  return (
    <Paper className={classes.root}>
      <NaviHeader
        title={t("compensationRules")}
        naviTitle={t("goBackToCompensationManager")}
        naviRoute="pharmaCompany"
      />
      <div className={classes.tabContainer}>
        <Tabs
          value={tabValue}
          onChange={(event, value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="secondary"
        >
          <Tab
            className={classes.tab}
            label={t("products").toUpperCase()}
            value={TabType.Products}
          />
        </Tabs>
      </div>
      <div className={classes.searchContainer}>
        <SearchField
          className={classes.searchField}
          onSearch={text => {
            if (text == null) {
              setSearchActive(false);
            } else {
              setSearchActive(true);
              dispatch(
                searchRules(userData.profitCenters, 0, MAX_SEARCH_RECORDS, text)
              );
            }
          }}
          onClear={() => setSearchActive(false)}
          placeholder={t("searchByProductNameOrNumber")}
        />
        <Progress
          className={classes.progress}
          size={25}
          show={searchingRules}
        />
      </div>
      <div className={classes.ruleContainer}>
        <CompensationRuleList
          title={t("defaultRulesForNewProducts")}
          rules={defaultRules.length > 0 ? [defaultRules[0]] : []}
          ruleCount={1}
          fetching={fetchingDefaultRule}
          fetchError={defaultRuleFetchError}
          ruleListMode={RuleListMode.DEFAULT}
          onLoadMore={() => {}}
          expand
        />
      </div>
      <div className={classes.ruleContainer}>
        <CompensationRuleList
          title={t("productsWithoutCompensationRule")}
          rules={undefinedRules}
          ruleCount={undefinedRuleCount}
          fetching={fetchingUndefinedRules}
          fetchError={undefinedRuleFetchError}
          onLoadMore={(startIndex, stopIndex) => {
            // do not modify the list while search is on. User might modify the rules and shift from undefined -> active
            if (searchActive === false) {
              dispatch(
                fetchUndefinedRules(
                  userData.profitCenters,
                  startIndex,
                  stopIndex
                )
              );
            }
          }}
          ruleListMode={RuleListMode.INACTIVE}
          expand
        />
      </div>
      <div className={classes.ruleContainer}>
        <CompensationRuleList
          title={t("compensationRules")}
          rules={activeRules}
          ruleCount={activeRuleCount}
          fetching={fetchingActiveRules}
          fetchError={activeRuleFetchError}
          onLoadMore={(startIndex, stopIndex) => {
            // do not modify the list while search is on. User might modify the rules and shift from undefined -> active
            if (searchActive === false) {
              dispatch(
                fetchActiveRules(userData.profitCenters, startIndex, stopIndex)
              );
            }
          }}
          ruleListMode={RuleListMode.ACTIVE}
          expand
        />
      </div>
    </Paper>
  );
}

export default CompensationRules;
