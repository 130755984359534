export const EURO_CURRENCY = "€";
export const YEARS = 5;
export const FORMAT = "YYYY-MM-DD";
// TODO: replace this
export const STATUSES = [
  "pending",
  "accepted",
  "rejected",
  "pending at oriola",
  "completed",
];
export const CHECKBOX_APPROVAL = "green";
export const CHECKBOX_REJECTION = "#FFB833";
// TODO:

export const DIALOG_APPEAR = 5000;

// new
export const CompensationStatuses = {
  Pending: "pending",
  Accepted: "accepted",
  Rejected: "rejected",
  PendingAtOriola: "pending at oriola",
  Completed: "completed",
};

// pharmacy accept statuses
export const PharmacyStatuses = Object.freeze({
  Accepted: "accepted",
  NotAccepted: "notAccepted",
});

export const CHECKBOX_APPROVAL_COLOR = "green";
export const CHECKBOX_REJECTION_COLOR = "#FFB833";
export const ChargeStatus = {
  manual: "manual",
  automatic: "automatic",
};

export const RuleStatus = Object.freeze({
  UNDEFINED: "UNDEFINED",
  ON: "ON",
  OFF: "OFF",
});

export const RejectionReason = Object.freeze({
  CompensationPolicy: "compensationPolicy",
  MistakeInRequest: "mistakeInRequest",
  Other: "other",
});

export const ReportType = Object.freeze({
  Compensation: "compensation",
  Charges: "charges",
  Refunds: "refunds",
});
