// eslint-disable-next-line no-use-before-define
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import IconButton from "@material-ui/core/IconButton";
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";
import ClearIcon from "@material-ui/icons/Clear";
import { Search } from "../../../images";

const useStyles = makeStyles(() => ({
  root: {
    width: "100%",
    maxWidth: 750,
  },
  searchField: {
    backgroundColor: "#f5f4f3",
    borderRadius: 10,
    "& fieldset": {
      borderRadius: 10,
      border: "solid 2px rgba(203, 203, 203, 0.2)",
    },
  },
  input: {
    height: "5px",
  },
}));

function SearchField({
  onSearch,
  onActiveSearch,
  onClear,
  placeholder,
  className,
}) {
  const classes = useStyles();
  const [value, setValue] = useState("");

  const doSearch = val => {
    if (val == null || val.length === 0) {
      onClear();
      setValue("");
    } else {
      onSearch(val);
    }
  };

  const doClear = () => {
    onClear();
    setValue("");
  };

  const doSetValue = val => {
    setValue(val);

    if (onActiveSearch != null) {
      const searchValue = val != null ? val : null;
      onActiveSearch(searchValue);
    }
  };

  return (
    <div className={`${classes.root} ${className}`}>
      <TextField
        id="outlined-dense"
        placeholder={placeholder}
        className={classes.searchField}
        margin="none"
        variant="outlined"
        fullWidth
        value={value}
        onChange={e => doSetValue(e.target.value)}
        onKeyPress={e => {
          const search = value.length === 0 || value.length > 2;
          if (e.key === "Enter" && search === true) {
            doSearch(value);
          }
        }}
        InputProps={{
          classes: { input: classes.input },
          endAdornment: (
            <InputAdornment>
              {value !== "" && (
                <IconButton size="small" onClick={() => doClear()}>
                  <ClearIcon />
                </IconButton>
              )}
              <IconButton size="small" onClick={() => doSearch(value)}>
                <Search />
              </IconButton>
            </InputAdornment>
          ),
        }}
      />
    </div>
  );
}

SearchField.propTypes = {
  onSearch: PropTypes.func,
  onClear: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.node,
  onActiveSearch: PropTypes.func,
};

SearchField.defaultProps = {
  onSearch: () => {},
  onClear: () => {},
  placeholder: "",
  onActiveSearch: null,
  className: null,
};

export default SearchField;
