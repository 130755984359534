// eslint-disable-next-line no-use-before-define
import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: theme.spacing(8),
  },
}));

function NotFound({ title }) {
  const classes = useStyles();
  const { t } = useTranslation();

  const titleText = title || `404 ${t("pageNotFound").toUpperCase()}`;

  return (
    <div className={classes.root}>
      <Typography variant="h5">{titleText}</Typography>
    </div>
  );
}

NotFound.propTypes = {
  title: PropTypes.string,
};

NotFound.defaultProps = {
  title: undefined,
};

export default NotFound;
