// eslint-disable-next-line no-use-before-define
import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@material-ui/core/Modal";
import { Typography } from "@material-ui/core";
import { DiscsIcon } from "../../../images";

const useStyles = makeStyles(theme => ({
  modal: {
    pointerEvents: "none",
  },
  root: {
    marginLeft: "auto",
    marginRight: "auto",
    position: "absolute",
    backgroundColor: theme.palette.secondary.main,
    borderWidth: "2px",
    borderRadius: 5,
    borderColor: theme.palette.secondary.main,
    borderStyle: "solid",
    boxShadow: theme.shadows[2],
    display: "flex",
    flexDirection: "row",
    pointerEvents: "all",
  },
  container: {
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    flexGrow: 1,
  },
  icon: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  textContainer: {
    marginLeft: theme.spacing(2),
  },
  primaryText: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(0.2),
  },
}));

function NotificationPopup({
  left,
  right,
  width,
  height,
  top,
  marginLeft,
  marginRight,
  onClose,
  show,
  primaryText,
  secondaryText,
  title,
}) {
  const classes = useStyles();

  return (
    <Modal open={show} className={classes.modal} hideBackdrop onClose={onClose}>
      <div
        className={classes.root}
        style={{ top, left, right, width, height, marginLeft, marginRight }}
      >
        <div className={classes.container}>
          <div className={classes.icon}>
            <DiscsIcon />
          </div>
          <div className={classes.textContainer}>
            <Typography variant="subtitle1" color="textSecondary">
              {title}
            </Typography>
            <Typography
              className={classes.primaryText}
              variant="body2"
              color="textSecondary"
              style={{ fontWeight: "normal" }}
            >
              {primaryText}
            </Typography>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ textTransform: "normal" }}
            >
              {secondaryText}
            </Typography>
          </div>
        </div>
      </div>
    </Modal>
  );
}

NotificationPopup.propTypes = {
  onClose: PropTypes.func,
  top: PropTypes.number,
  left: PropTypes.number,
  right: PropTypes.number,
  width: PropTypes.string,
  height: PropTypes.string,
  marginLeft: PropTypes.string,
  marginRight: PropTypes.string,
  show: PropTypes.bool,
  primaryText: PropTypes.string,
  title: PropTypes.string,
  secondaryText: PropTypes.string,
};

NotificationPopup.defaultProps = {
  onClose: () => {},
  left: 0,
  right: 0,
  top: 16,
  width: "25%",
  height: "15%",
  marginLeft: "auto",
  marginRight: "auto",
  show: false,
  primaryText: "",
  title: "",
  secondaryText: "",
};

export default NotificationPopup;
