// eslint-disable-next-line no-use-before-define
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Typography, Divider, Tabs, Tab } from "@material-ui/core";
import { useTranslation } from "react-i18next";
// eslint-disable-next-line import/no-cycle
import { StepType } from "./compensationSteps";
import ProductTable from "../common/tables/productTable/productTable";
import PharmaTable, {
  ExtensionMode,
  PharmaTableMode,
} from "../common/tables/pharmaTable/pharmaTable";
import ChargesTable, {
  ChargesTableMode,
} from "../common/tables/chargesTable/chargesTable";
import { FetchLoader, FetchError } from "../common";
import {
  getTotalCompensationsGiven,
  getPharmacyApprovedCompensations,
  aggregatePharmaCompanies,
  aggregateCompensationProducts,
  getTotalServiceCharges,
  getTotalRefunds,
  aggregatePharmacies,
} from "../../utils/compensation/compensation";
import { EURO_CURRENCY } from "../common/constants/constants";
import TableDetailsProvider from "../common/tables/tableDetailsContext";
import SearchField from "../common/searchField/searchField";
import locallyFilterCompensations from "./locallyFilterCompensations";

const useStyles = makeStyles(theme => ({
  root: {},
  titleContainer: {
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  title: {
    color: theme.palette.text.disabled,
  },
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    marginLeft: "1rem",
    marginRight: "1rem",
    marginBottom: theme.spacing(1.5),
  },
  headerLeft: {
    width: "55%",
    display: "flex",
    flexDirection: "row",
  },
  headerRight: {
    width: "45%",
    display: "flex",
    flexDirection: "row",
  },
  headerText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.main,
  },
  headerValue: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.main,
    fontWeight: "bold",
    marginLeft: "0.5rem",
  },
  headerDivider: {
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  tabsAndSearchContainer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(4),
    marginLeft: "1rem",
    marginRight: "1rem",
  },
  tabsContainer: {},
  searchContainer: {
    width: "100%",
    maxWidth: 500,
  },
  searchField: {
    width: "100%",
  },
  tab: {
    ...theme.typography.h6,
    color: theme.palette.text.hint,
    fontWeight: theme.typography.fontWeightBold,
    fontSize: "1.07rem",
  },
  content: {
    marginTop: theme.spacing(4),
  },
}));

const TabType = Object.freeze({
  Pharmacies: "pharmacies",
  PharmaCompanies: "pharmaCompanies",
  Products: "products",
});

function Title({ text }) {
  const classes = useStyles();

  return (
    <div className={classes.titleContainer}>
      <Typography className={classes.title} variant="subtitle2">
        {text}
      </Typography>
    </div>
  );
}

Title.propTypes = {
  text: PropTypes.string.isRequired,
};

function CompensationStepDetails({
  title,
  type,
  compensations,
  inServiceProfitCenters,
}) {
  const classes = useStyles();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = useState(TabType.Pharmacies);
  const { charges, fetchingCharges, fetchChargesError } = useSelector(
    state => state.oriola
  );
  const [targetCompensations, setTargetCompensations] = useState([]);
  const [aggregatedCount, setAggregatedCount] = useState(0);
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    const filteredCompensations = locallyFilterCompensations(
      compensations,
      searchValue
    );
    let aggCount;
    if (tabValue === TabType.PharmaCompanies) {
      aggCount = aggregatePharmaCompanies(filteredCompensations).length;
    } else if (tabValue === TabType.Products) {
      aggCount = aggregateCompensationProducts(filteredCompensations).length;
    } else {
      aggCount = aggregatePharmacies(filteredCompensations).length;
    }

    setTargetCompensations(filteredCompensations);
    setAggregatedCount(aggCount);
  }, [compensations, tabValue, setTargetCompensations, searchValue]);

  const getSubHeaderText = () => {
    if (type === StepType.ServiceCharges || type === StepType.Refunds) {
      return t("pharmaCompanies");
    }
    switch (tabValue) {
      case TabType.Pharmacies:
        return t("pharmacies");
      case TabType.PharmaCompanies:
        return t("pharmaCompanies");
      case TabType.Products:
        return t("products");
      default:
        return "-";
    }
  };

  const getSubHeaderValue = () => {
    if (type === StepType.ServiceCharges || type === StepType.Refunds) {
      return charges.length;
    }
    return aggregatedCount;
  };

  const getTotalSum = () => {
    if (type === StepType.ServiceCharges) {
      return getTotalServiceCharges(charges);
    }
    if (type === StepType.Refunds) {
      return getTotalRefunds(charges);
    }
    const pharmacyApprovedCompensations =
      getPharmacyApprovedCompensations(targetCompensations);
    const totalCompensation = getTotalCompensationsGiven(
      pharmacyApprovedCompensations
    );

    // currently these are same but let's leave it be for future use.
    switch (tabValue) {
      case TabType.Pharmacies:
        return totalCompensation;
      case TabType.PharmaCompanies:
        return totalCompensation;
      case TabType.Products: {
        return totalCompensation;
      }
      default:
        return "-";
    }
  };

  const renderTabsAndSearch = () => (
    <div className={classes.tabsAndSearchContainer}>
      <div className={classes.tabsContainer}>
        <Tabs
          value={tabValue}
          onChange={(event, value) => setTabValue(value)}
          indicatorColor="primary"
          textColor="secondary"
        >
          <Tab
            className={classes.tab}
            label={t("pharmacies").toUpperCase()}
            value={TabType.Pharmacies}
          />
          <Tab
            className={classes.tab}
            label={t("pharmaCompanies").toUpperCase()}
            value={TabType.PharmaCompanies}
          />
          <Tab
            className={classes.tab}
            label={t("products").toUpperCase()}
            value={TabType.Products}
          />
        </Tabs>
      </div>
      <div className={classes.searchContainer}>
        <SearchField
          className={classes.searchField}
          placeholder={t("searchPlaceholder")}
          onSearch={text => setSearchValue(text || "")}
          onClear={() => setSearchValue("")}
        />
      </div>
    </div>
  );

  // eslint-disable-next-line react/no-unstable-nested-components
  function HeaderText() {
    return (
      <div>
        <div className={classes.headerContainer}>
          <div className={classes.headerLeft}>
            <Typography className={classes.headerText}>
              {getSubHeaderText()}
            </Typography>
            <Typography className={classes.headerValue}>
              {getSubHeaderValue()}
            </Typography>
          </div>
          <div className={classes.headerRight}>
            <Typography className={classes.headerText}>{t("total")}</Typography>
            <Typography
              className={classes.headerValue}
            >{`${getTotalSum()}${EURO_CURRENCY}`}</Typography>
          </div>
        </div>
        <Divider className={classes.headerDivider} />
      </div>
    );
  }

  const renderContent = () => {
    if (type === StepType.ServiceCharges || type === StepType.Refunds) {
      const mode =
        type === StepType.ServiceCharges
          ? ChargesTableMode.ServiceCharges
          : ChargesTableMode.Refunds;
      return (
        <div className={classes.content}>
          <FetchLoader loading={fetchingCharges} />
          <FetchError
            title={t("serviceChargeFetchError")}
            error={fetchChargesError}
          />
          {fetchingCharges === false && (
            <ChargesTable mode={mode} charges={charges} />
          )}
        </div>
      );
    }
    switch (tabValue) {
      case TabType.Pharmacies:
        return (
          <div className={classes.content}>
            <TableDetailsProvider>
              <PharmaTable
                compensations={targetCompensations}
                mode={PharmaTableMode.ShowPharmacy}
                extensionMode={ExtensionMode.PharmaCompanies}
                showActionControls={false}
                inServiceProfitCenters={inServiceProfitCenters}
                showInServiceProfitCenters={false}
              />
            </TableDetailsProvider>
          </div>
        );

      case TabType.PharmaCompanies:
        return (
          <div className={classes.content}>
            <TableDetailsProvider>
              <PharmaTable
                compensations={targetCompensations}
                mode={PharmaTableMode.ShowPharmaCompany}
                extensionMode={ExtensionMode.Products}
                showActionControls={false}
                inServiceProfitCenters={inServiceProfitCenters}
                showInServiceProfitCenters
              />
            </TableDetailsProvider>
          </div>
        );

      case TabType.Products:
        return (
          <div className={classes.content}>
            <TableDetailsProvider>
              <ProductTable compensations={targetCompensations} />
            </TableDetailsProvider>
          </div>
        );

      default:
        return null;
    }
  };

  return (
    <div className={classes.root}>
      <Title text={title} />
      {type === StepType.Compensations && renderTabsAndSearch()}
      <HeaderText />
      {renderContent()}
    </div>
  );
}

CompensationStepDetails.propTypes = {
  compensations: PropTypes.arrayOf(PropTypes.shape({})),
  title: PropTypes.string,
  type: PropTypes.node,
  inServiceProfitCenters: PropTypes.arrayOf(PropTypes.shape({})),
};

CompensationStepDetails.defaultProps = {
  compensations: null,
  title: "",
  type: null,
  inServiceProfitCenters: null,
};

export default CompensationStepDetails;
