// eslint-disable-next-line no-use-before-define
import React from "react";
import { useTranslation } from "react-i18next";
import { makeStyles } from "@material-ui/core/styles";
import { Typography } from "@material-ui/core";
import { Cell } from "../../common/tables/common";

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: "0.4rem",
    paddingBottom: "0.8rem",
    borderBottomStyle: "solid",
    borderBottomWidth: "2px",
    borderBottomColor: "rgba(0, 0, 0, 0.12)",
  },
  row: {
    paddingLeft: "0.5rem",
    paddingRight: "0.5rem",
    // backgroundColor: 'yellow',
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderLeft: "5px solid transparent",
  },
  headerText: {
    ...theme.typography.button,
    textTransform: "none",
    color: theme.palette.text.disabled,
  },
}));

export const TabWidths = Object.freeze({
  Product: "25%",
  Compensation: "13%",
  TimeLimit: "32%",
  Exceptions: "30%",
});

function HeaderRow() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <div className={classes.row}>
        <Cell width={TabWidths.Product}>
          <Typography className={classes.headerText}>{t("product")}</Typography>
        </Cell>
        <Cell width={TabWidths.Compensation}>
          <Typography className={classes.headerText}>
            {t("compensation")}
          </Typography>
        </Cell>
        <Cell width={TabWidths.TimeLimit}>
          <Typography className={classes.headerText}>
            {t("timeLimit")}
          </Typography>
        </Cell>
        <Cell width={TabWidths.Exceptions}>
          <Typography className={classes.headerText}>
            {t("exceptions")}
          </Typography>
        </Cell>
      </div>
    </div>
  );
}

export default HeaderRow;
