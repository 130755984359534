import { createMuiTheme } from "@material-ui/core";

const oriolaTheme = createMuiTheme({
  palette: {
    primary: {
      light: "#ffcf8e",
      main: "#ffa000",
      contrastText: "#fff",
    },
    secondary: {
      light: "#eaeef5",
      main: "#2b3e5b",
      contrastText: "#fff",
    },
    // error: will use the default color
    background: { paper: "#fff", default: "#f8f8f8" }, // was f5f4f3
    action: {
      hover: "#ffa00080",
      selected: "#ffa00080",
    },
    text: {
      primary: "#2b3e5b",
      secondary: "#fff",
      disabled: "#757070",
    },
    divider: "#e2e2e2",
    error: {
      main: "#ec130e",
    },
  },
  typography: {
    fontFamily: ["verdana"].join(","),
    fontSize: 16,
    lineHeight: 25,
    letterSpacing: 0.4,
    // these are defined from here
    // https://app.zeplin.io/project/5c3d8c03b2bfa352560dd752/screen/5d70c0d3c07a8762570a9dd6
    // 25px bold
    h4: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.5625rem",
      lineHeight: 1.2,
    },
    // 20px bold
    h5: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
    // 20px regular
    h6: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "1.25rem",
      lineHeight: 1.2,
    },
    // 18 px bold
    subtitle1: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1.125rem",
      lineHeight: 1.22,
    },
    // 16 px bold
    subtitle2: {
      fontFamily: "verdana",
      fontWeight: "bold",
      fontSize: "1rem",
      lineHeight: 1.19,
    },
    // 16 px normal
    body1: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "1rem",
      lineHeight: 1.19,
    },
    // 12 px normal
    body2: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "0.75rem",
      lineHeight: 1.25,
    },
    // 14px normal
    button: {
      fontFamily: "verdana",
      fontWeight: "normal",
      fontSize: "0.875rem",
      lineHeight: 1.21,
    },
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 2,
        textTransform: "none",
      },
    },
    MuiCheckbox: {
      root: {
        color: "#757070",
      },
    },
    MuiRadio: {
      root: {
        color: "#757070",
      },
    },
  },
});

export default oriolaTheme;
